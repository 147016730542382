import { configureStore } from '@reduxjs/toolkit'
import appToastReducer from '../features/utilities/appToastSlice'
import authReducer from '../features/auth/authSlice'
import dataReducer from '../features/data/dataSlice'
import leftSidebarReducer from '../features/sidebar/LeftSidebarSlice'
import AddTaskReducer from '../features/modal/AddTaskSlice'
import PinModalReducer from '../features/modal/PinModalSlice'
import RightSidebarReducer from '../features/sidebar/RightSidebarSlice'
import CreateOkrReducer from '../features/modal/CreateOkrSlice'
import OkrScoreReducer from '../features/modal/OkrScoreSlice'

export const store = configureStore({
  reducer: {
    appToast: appToastReducer,
    auth: authReducer,
    data: dataReducer,
    leftSidebar: leftSidebarReducer,
    addTask: AddTaskReducer,
    pinModal: PinModalReducer,
    rightSidebar: RightSidebarReducer,
    createOkr: CreateOkrReducer,
    okrScore: OkrScoreReducer
  },
});