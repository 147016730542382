import React, { useState, useRef } from 'react'
import './UsersChatList.css'
import { Link } from 'react-router-dom'
import {
  Form,
  InputGroup,
  Button,
  Badge,
  Overlay,
  Tooltip
} from 'react-bootstrap'

function UsersChatsList({}) {
  const [searchTerm, setSearchTerm] = useState('')
  const contacts = [
    {
      id: 1,
      name: 'MGM  (Nerul)',
      phone: '+1 123 456 7890',
      email: 'support.mgm.nerul@example.com',
      address: '1234 Elm St, Springfield, USA',
      emoji: '👍',
      timeAgo: '5 min ago',
      badge: '1234',
      img: 'Whatsapp'
    },
    {
      id: 2,
      name: 'Jane Smith',
      phone: '+44 987 654 3210',
      email: 'janesmith@example.co.uk',
      address: '5678 Oak Ave, London, UK',
      emoji: '❤️',
      timeAgo: '10 min ago',
      badge: '5678',
      img: 'Facebook'
    },
    {
      id: 3,
      name: 'Amit Sharma',
      phone: '+91 98765 43210',
      email: 'amitsharma@example.in',
      address: '12 MG Road, Mumbai, India',
      emoji: '😂',
      timeAgo: '15 min ago',
      badge: '9101',
      img: 'webweb'
    },
    {
      id: 4,
      name: 'Maria Garcia',
      phone: '+34 654 321 987',
      email: 'mariagarcia@example.es',
      address: '4567 La Rambla, Barcelona, Spain',
      emoji: '😢',
      timeAgo: '20 min ago',
      badge: '1121',
      img: 'Instagram'
    },
    {
      id: 5,
      name: 'Yuki Tanaka',
      phone: '+81 123 456 789',
      email: 'yukitanaka@example.jp',
      address: '89 Shibuya St, Tokyo, Japan',
      emoji: '🙏',
      timeAgo: '25 min ago',
      badge: '3141',
      img: 'webweb'
    }
  ]
  const filteredContacts = contacts.filter(
    contact =>
      contact.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      contact.badge.includes(searchTerm) ||
      contact.phone.replace(/\s+/g, '').includes(searchTerm.replace(/\s+/g, ''))
  )

  const handleSearch = event => {
    setSearchTerm(event.target.value)
  }

  return (
    <>
      <div className="wide-block pt-1 pb-1 position-sticky searchSection">
        <form className="search-form">
          <div className="form-group searchbox not-empty">
            <input
              type="text"
              className="form-control"
              placeholder="Search Conversations"
              value={searchTerm}
              onChange={handleSearch}
            />
            <i className="input-icon">
              <ion-icon
                name="search-outline"
                role="img"
                className="md hydrated"
                aria-label="search outline"
              ></ion-icon>
            </i>
          </div>
        </form>
      </div>
      <div className="UsersChatsListView">
        <ul className="listview image-listview">
          {filteredContacts.map(contact => (
            <li key={contact.id}>
              <Link to={`?chatId=${contact.badge}`} className="item active">
                <img
                  src={`assets/img/apps/${contact.img.toLowerCase()}.svg`}
                  alt="app-icon"
                  className="image"
                />
                <div className="in">
                  <div>
                    {contact.name}{' '}
                    <span className="timeAgo">{contact.timeAgo}</span>
                    &nbsp;&nbsp;
                    <Badge bg="secondary">{contact.badge}</Badge>
                    <header>{contact.email}</header>
                  </div>
                </div>
              </Link>
            </li>
          ))}
        </ul>
      </div>
    </>
  )
}

export default UsersChatsList
