import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { showPreloader, setObjectiveData } from '../../features/data/dataSlice';
import Masonry, { ResponsiveMasonry } from 'react-responsive-masonry';
import NestedCard from './smallComponents/NestedCard';
import TaskCard from './smallComponents/TaskCard';
import RightSidebar from '../layout/Sidebar/RightSidebar';
import { showRightSidebar } from '../../features/sidebar/RightSidebarSlice';
import axios from '../../AxiosConfig'

const OkrDashboard = ({
    showPreloader,
    allUserList,
    allProjectList,
    showRightSidebar,
    setObjectiveData,
    objectiveData,
    renderCount,
    role
}) => {
    const [checkedValues, setCheckedValues] = useState([]);
    const [okrData, setOkrData] = useState([
        {"ID": 3,"Level": 3, "Color": "gainsboro", "Title": "Marketing Campaign", "TaskList": ["Task 1", "Task 2", "Task 3"]},
        {"ID": 4,"Level": 3, "Color": "gainsboro", "Title": "Strategy Session", "TaskList": ["Task 1", "Task 2", "Task 3", "Task 4"]},
        {"ID": 5,"Level": 4, "Color": "#ffce88", "Title": "Development Phase", "TaskList": ["Task 1", "Task 2"]},
        {"ID": 6,"Level": 4, "Color": "#f6a79f", "Title": "QA Testing", "TaskList": ["Task 1", "Task 2", "Task 3", "Task 4"]},
        {"ID": 7,"Level": 4, "Color": "#9fe6b0", "Title": "Development Phase", "TaskList": ["Task 1", "Task 2"]},
        {"ID": 8,"Level": 4, "Color": "#f6a79f", "Title": "Design Sprint", "TaskList": ["Task 1", "Task 2", "Task 3", "Task 4", "Task 5"]},
        {"ID": 9,"Level": 3, "Color": "gainsboro", "Title": "Client Meeting", "TaskList": ["Task 1", "Task 2", "Task 3", "Task 4", "Task 5"]},
        {"ID": 10,"Level": 4, "Color": "#ffce88", "Title": "Research", "TaskList": ["Task 1", "Task 2", "Task 3", "Task 4"]}
    ])
    const [pid, setPid] = useState(null)
    const [qid, setQid] = useState(null)
    const [otherProject, setOtherProject] = useState([])
    const [otherOkrLength, setOtherOkrLength] = useState(0)
    const [searchParams] = useSearchParams();
    const uid = searchParams.get('uid');
    let navigate = useNavigate()
    useEffect(() => {
        showPreloader({preloaderVisible: false})
    }, [])
    const handleProjectFilter = (projectID) => {
        setPid(projectID)
        // navigate('?pid=' + ((projectID) ? projectID : 'all') + '&uid=' + uid + '&qid=' + qid)
    }
    const handleCheckboxChange = (event) => {
        const value = event.target.value;
        if (event.target.checked) {
          setCheckedValues([...checkedValues, value]);
        } else {
          setCheckedValues(checkedValues.filter((val) => val !== value));
        }
    };
    useEffect(() => {
        if (checkedValues.length > 0) {
            const checkedValuesString = checkedValues.join('-');
            // navigate('?pid=' + pid + '&uid=' + checkedValuesString + '&qid=' + qid);
        }
        if (pid === null || pid === undefined || pid === null) {
            // navigate('?pid=all' + '&uid=' + uid + '&qid=' + qid);
            setPid('all')
        }
        if (qid === null || qid === undefined || qid === null) {
            setQid(qid)
            // navigate('?pid=' + pid + '&uid=' + uid + '&qid=' + qid);
        }
    }, [checkedValues, pid, qid])
    useEffect(() => {
        axios.get('/objectives').then(res => {
            if (res.data.message === 'success') {
                setObjectiveData({objectiveData: res.data.data})
            } else {
                console.log('Something went wrong')
            }
        }).catch(err => {
            console.error(err);
        });
    }, [renderCount])
    const handleRightSidebar = () => {
        showRightSidebar({rightSidebarVisible: true})
    }
    useEffect(() => {
        let miscellaneousProjectArr = []
        if (pid === 'miscellaneous') {
            allProjectList.map((v,k) => {
                if (v.Type !== 1) {
                    miscellaneousProjectArr.push(v)
                }
            })
            let tempOtherOkrLength = 0
            miscellaneousProjectArr.map((v,k) => {
                if (objectiveData?.filter((arr) => (v.ID === arr.ProjectID)).length !== 0) {
                    tempOtherOkrLength = tempOtherOkrLength + 1                                    
                }
            })
            setOtherOkrLength(tempOtherOkrLength)
            setOtherProject(miscellaneousProjectArr)
        }
    }, [pid])
    useEffect(() => {
        console.log(otherProject)
    }, [otherProject])
    return (
        <div id="appCapsule">
            <div class="pt-4 pb-sm-0 overflow-auto">
                <div class="btn-group pe-1 ps-1" role="group">
                    <div onClick={() => {handleProjectFilter('all')}}>
                        <input type="radio" class="btn-check" checked={(pid === 'all') ? true : false} name="projectList" id={"projectNameAll"} />
                        <label class="btn btn-outline-primary border-primary text-nowrap pe-1 ps-1 me-2 ms-2" style={{height: '35px', borderRadius: '5px'}} for={"projectNameAll"}>All</label>
                    </div>
                    {allProjectList?.map((v,k) => {
                        return <>
                            {v.Type === 1 &&
                                <div onClick={() => {handleProjectFilter(v.ID)}}>
                                    <input type="radio" class="btn-check" name="projectList" id={"projectName" + k} checked={(pid === v.ID) ? true : false} />
                                    <label class="btn btn-outline-primary border-primary text-nowrap pe-1 ps-1 me-2 ms-2" style={{height: '35px', borderRadius: '5px'}} for={"projectName" + k}>{v.Name}</label>
                                </div>
                            }
                        </>
                    })}
                    <div onClick={() => {handleProjectFilter('miscellaneous')}}>
                        <input type="radio" class="btn-check" checked={(pid === 'miscellaneous') ? true : false} name="projectList" id={"projectNameMiscellaneous"} />
                        <label class="btn btn-outline-primary border-primary text-nowrap pe-1 ps-1 me-2 ms-2" style={{height: '35px', borderRadius: '5px'}} for={"projectNameMiscellaneous"}>Miscellaneous</label>
                    </div>
                </div>
            </div>
            <div class="container-fluid d-flex justify-content-center">
                <div class="row mt-2 w-100">
                    {pid === 'miscellaneous' &&
                        <>
                            {otherProject.map((v,k) => {
                                return <>
                                    {objectiveData?.filter((arr) => (v.ID === arr.ProjectID)).map((v1,k1) => {
                                        return <>
                                            {(v1.Level !== 5 && v1.Level === 2) &&
                                                <TaskCard taskCardData={v1} />
                                            }
                                        </>
                                    })}
                                </>
                            })}
                            {otherOkrLength === 0 &&
                                <div class="col-md-12">
                                    <h1 class="text-danger d-flex justify-content-center">No OKR's</h1>
                                </div>
                            }
                        </>    
                    }
                    {objectiveData?.filter((arr) => (pid === 'all' || pid === 'miscellaneous') ? arr : (pid === arr.ProjectID)).length !== 0 &&
                        <>
                            <div class="col-md-4">
                                {pid !== 'miscellaneous' &&
                                    <>
                                        {objectiveData?.filter((arr) => (pid === 'all') ? arr : (pid === arr.ProjectID || arr.ProjectID === null)).map((v,k) => {
                                            return <>
                                                {(v.Level !== 5 && v.Level === 2) &&
                                                    <TaskCard taskCardData={v} />
                                                }
                                            </>
                                        })}
                                    </>
                                }
                            </div>
                            <div class="col-md-4">
                                {objectiveData?.filter((arr) => (pid === 'all') ? arr : (pid === arr.ProjectID || arr.ProjectID === null)).map((v,k) => {
                                    return <>
                                        {(v.Level !== 5 && v.Level === 3) &&
                                            <TaskCard taskCardData={v} />
                                        }
                                    </>
                                })}
                            </div>
                            <div class="col-md-4">
                                {objectiveData?.filter((arr) => (pid === 'all') ? arr : (pid === arr.ProjectID || arr.ProjectID === null)).map((v,k) => {
                                    return <>
                                        {(v.Level !== 5 && v.Level === 4) &&
                                            <TaskCard taskCardData={v} />
                                        }
                                    </>
                                })}
                            </div>
                        </>
                    }
                    {pid !== 'miscellaneous' &&
                        <>
                            {objectiveData?.filter((arr) => (pid === 'all') ? arr : (pid === arr.ProjectID)).length === 0 &&
                                <div class="col-md-12">
                                    <h1 class="text-danger d-flex justify-content-center">No OKR's</h1>
                                </div>
                            }
                        </>
                    }
                </div>
            </div>
            <RightSidebar />
            {role === 2 &&
                <div class="btn btn-secondary p-0 d-flex align-items-center justify-content-center" onClick={() => {handleRightSidebar()}} style={{position: 'fixed', right: 0, bottom: 35, borderTopLeftRadius: '5px', borderBottomLeftRadius: '5px', borderTopRightRadius: '0px', borderBottomRightRadius: '0px', zIndex: 999, height: '40px', width: '40px'}}>
                    <ion-icon name="arrow-forward-outline" class="me-0"></ion-icon>
                </div>
            }
        </div>
    );
};

const mapStateToProps = ({ data, auth }) => {
    const {
        allUserList,
        allProjectList,
        objectiveData,
        renderCount
    } = data
    const {
        role
    } = auth
    return {
        allUserList,
        allProjectList,
        objectiveData,
        renderCount,
        role
    }
}
  
const mapDispatchToProps = {
    showPreloader,
    showRightSidebar,
    setObjectiveData
}

export default connect(mapStateToProps, mapDispatchToProps)(OkrDashboard);