import React, { useState, useRef } from 'react'
import './UserChatView.css'
import {
  Form,
  InputGroup,
  Button,
  Badge,
  Overlay,
  Tooltip
} from 'react-bootstrap'
import { PlusCircle, Image, Mic, Send } from 'react-bootstrap-icons'
import { Link } from 'react-router-dom'
import ReactionBar from './ReactionBar '

function MessageAction({ setAction }) {
  return (
    <div className="messageAction">
      <button className="btn btn-sm btn-success" title="Move Down">
        <ion-icon name="arrow-down-outline"></ion-icon>
      </button>
    </div>
  )
}

function Badges() {
  return (
    <div className="ticketbadge ">
      <Badge bg="secondary">1985</Badge>
      &nbsp;
      <Badge bg="secondary">9756</Badge>
      &nbsp;
      <Badge bg="secondary">9756</Badge>
      &nbsp;
      <Badge bg="secondary">9756</Badge>
      &nbsp;
      <Badge bg="secondary">9756</Badge>
      &nbsp;
      <Badge bg="secondary">9756</Badge>
      &nbsp;
      <Badge bg="secondary">9756</Badge>
      &nbsp;
      <Badge bg="secondary">9756</Badge>
      &nbsp;
      <Badge bg="secondary">9756</Badge>
      &nbsp;
      <Badge bg="secondary">1358</Badge>
      &nbsp;
      <Badge bg="secondary">9872</Badge>
      &nbsp;
      <Badge bg="secondary">9872</Badge>
      &nbsp;
      <Badge bg="secondary">9872</Badge>
      &nbsp;
      <Badge bg="secondary">9872</Badge>
      &nbsp;
      <Badge bg="secondary">9872</Badge>
      &nbsp;
      <Badge bg="secondary">+28</Badge>
      &nbsp;
    </div>
  )
}

function UserChatView({ setAction }) {
  const [show, setShow] = useState(false)
  const [target, setTarget] = useState(null)
  const ref = useRef(null)

  const handleMouseEnter = event => {
    setShow(true)
    setTarget(event.target)
  }

  const handleMouseLeave = () => {
    setShow(false)
  }

  const [message, setMessage] = useState('')
  const [showMenu, setShowMenu] = useState(false)

  // Handle input change
  const handleInputChange = e => {
    const value = e.target.value
    setMessage(value)

    // Trigger menu when typing '/'
    if (value.includes('/')) {
      setShowMenu(true)
    } else {
      setShowMenu(false)
    }
  }

  // Select command from the menu
  const selectCommand = command => {
    setMessage(command)
    setShowMenu(false)
  }

  return (
    <div className="position-relative UserChatView">
      <div class="chatHeader">
        <Link
          to="/Conversations"
          className="btn btn-outline-primary outline-none border-0 btn-sm px-0 d-flex align-items-center justify-content-center d-sm-none"
        >
          <ion-icon name="chevron-back-outline"></ion-icon>
        </Link>
        <img src="assets/img/apps/whatsapp.svg" alt="image" className="image" />
        <div className="in">
          <div>Juan Esteban Sarmiento</div>
          <Badges className="headerbadge" />

          {/* <span className="text-muted">Edit</span> */}
        </div>
      </div>
      <div style={{ padding: '10px' }}>
        <div class="message-divider">Friday, Sep 20, 10:40 AM</div>

        <div
          class="message-item"
          // onMouseEnter={handleMouseEnter}
          // onMouseLeave={handleMouseLeave}
          // ref={ref}
        >
          <img
            src="assets/img/sample/avatar/avatar1.jpg"
            alt="avatar"
            class="avatar"
          />
          <div class="content">
            <div class="title">John</div>
            <div class="bubble">Hi everyone, how are you?</div>
            <div class="footer">8:40 AM</div>
          </div>
          {/* Reactions modal */}
          &nbsp;&nbsp;&nbsp;
          <ReactionBar />
          {/* <Overlay
            show={show}
            target={target}
            placement="top"
            container={ref.current}
            containerPadding={20}
          >
            <Tooltip id="reactions-tooltip" className="reactions-tooltip">
              <Button variant="light" size="sm">
                👍
              </Button>
              <Button variant="light" size="sm">
                ❤️
              </Button>
              <Button variant="light" size="sm">
                😂
              </Button>
              <Button variant="light" size="sm">
                😮
              </Button>
            </Tooltip>
          </Overlay> */}
        </div>

        <div class="message-item">
          <img
            src="assets/img/sample/avatar/avatar2.jpg"
            alt="avatar"
            class="avatar"
          />
          <div class="content">
            <div class="title">Marry</div>
            <div class="bubble">
              I'm fine, how are you today john, do you feel good?
            </div>
            <div class="footer">
              {/* <MessageAction setAction={setAction} /> */}
              10:40 AM
            </div>
          </div>
        </div>

        <div class="message-item user">
          <div class="content">
            <div class="title">Dave</div>
            <div class="bubble">
              Would you please repost the photo you sent yesterday?
            </div>
            <div class="footer">
              {/* <MessageAction setAction={setAction} /> */}
              <Badge bg="success">Open</Badge>
              10:40 AM
            </div>
          </div>
          <img
            src="assets/img/sample/avatar/avatar2.jpg"
            alt="avatar"
            class="avatar"
          />
        </div>

        <div class="message-divider">Friday, Sep 20, 10:40 AM</div>

        <div class="message-item">
          <img
            src="assets/img/sample/avatar/avatar2.jpg"
            alt="avatar"
            class="avatar"
          />
          <div class="content">
            <div class="title">Marry</div>
            <div class="bubble">
              <img
                src="assets/img/sample/photo/1.jpg"
                alt="photo"
                class="imaged w160"
              />
            </div>
            <MessageAction setAction={setAction} />
            <div class="footer">10:40 AM</div>
          </div>
        </div>

        <div class="message-item">
          <img
            src="assets/img/sample/avatar/avatar4.jpg"
            alt="avatar"
            class="avatar"
          />
          <div class="content">
            <div class="title">Katie</div>
            <div class="bubble">Nice photo !</div>
            <div class="footer">10:40 AM</div>
          </div>
        </div>

        <div class="message-item">
          <img
            src="assets/img/sample/avatar/avatar2.jpg"
            alt="avatar"
            class="avatar"
          />
          <div class="content">
            <div class="title">Marry</div>
            <div class="bubble">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque
              vitae nisl et nibh iaculis sagittis. In hac habitasse platea
              dictumst. Sed eu massa lacinia, interdum ex et, sollicitudin elit.
            </div>
            <div class="footer">10:40 AM</div>
          </div>
        </div>

        <div class="message-item user">
          <div class="content">
            <div class="title">Marry</div>
            <div class="bubble">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque
              vitae nisl et nibh iaculis sagittis. In hac habitasse platea
              dictumst. Sed eu massa lacinia, interdum ex et, sollicitudin elit.
            </div>
            <div class="footer">
              <Badge bg="danger">Closed</Badge>10:40 AM
            </div>
          </div>
          <img
            src="assets/img/sample/avatar/avatar2.jpg"
            alt="avatar"
            class="avatar"
          />
        </div>
      </div>

      <div className="border-top bg-white p-2 chatFooter">
        <Form
        // onSubmit={handleSubmit}
        >
          <InputGroup className="input-with-icon">
            <div
              className="mobilebadge"
              // style={{ marginBottom: '10px', width: '100%' }}
            >
              <Badges />
              {/* <Button variant="light" className="border-0">
              <Image size={24} />
            </Button> */}

              <Form.Control
                type="text"
                placeholder="Message..."
                value={message}
                onChange={handleInputChange}
                // onChange={e => setMessage(e.target.value)}
                className="rounded-pill bg-light"
              />
            </div>
            <div className="plus-icon-btn">
              <Button
                variant="light"
                className="border-0"
                data-bs-toggle="offcanvas"
                data-bs-target="#actionSheetAdd"
              >
                <PlusCircle size={24} />
              </Button>
              <Button variant="light" className="border-0">
                <Mic size={24} />
              </Button>
              <Button
                type="submit"
                variant="light"
                className="border-0 text-primary"
              >
                <Send size={24} />
              </Button>
            </div>
          </InputGroup>
          {/* Sliding Menu */}
          {showMenu && (
            <div className="command-menu">
              <ul>
                <li onClick={() => selectCommand('/start')}>
                  <div class="Slidingmenu">
                    <img
                      src="assets/img/sample/avatar/avatar2.jpg"
                      alt="avatar"
                      class="avatar"
                    />
                    /start
                  </div>
                </li>
                <li onClick={() => selectCommand('/off')}>
                  <div class="Slidingmenu">
                    <img
                      src="assets/img/sample/avatar/avatar2.jpg"
                      alt="avatar"
                      class="avatar"
                    />
                    /off
                  </div>
                </li>
                <li onClick={() => selectCommand('/off')}>
                  <div class="Slidingmenu">
                    <img
                      src="assets/img/sample/avatar/avatar2.jpg"
                      alt="avatar"
                      class="avatar"
                    />
                    /Restart
                  </div>
                </li>
              </ul>
            </div>
          )}
        </Form>
      </div>
    </div>
  )
}

export default UserChatView
