import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import axios from '../../../AxiosConfig'
import { Modal } from 'react-bootstrap'
import { hideCreateOkrModal } from '../../../features/modal/CreateOkrSlice'
import { setRenderCount } from '../../../features/data/dataSlice'

const CreateOkrModal = ({
    createOkrModalVisible,
    hideCreateOkrModal,
    allProjectList,
    allUserList,
    parentOkrData,
    setRenderCount,
    renderCount
}) => {
    const [okrTitle, setOkrTitle] = useState(null)
    const [okrProjectId, setOkrProjectId] = useState(null)
    const [okrDuration, setOkrDuration] = useState('52-week')
    const [okrResource, setOkrResource] = useState(null)
    const [okrParentId, setOkrParentId] = useState(1)
    const [parentObjective, setParentObjective] = useState(null)

    function getOkrDurationObj(params) {
        axios.get('/objectives/' + params).then(res => {
            if (res.data.message === 'success') {
                return res.data.data
            }
        })
    }
    
    const handleOkrData = (value, dataType) => {
        if (dataType === 'title') {
            setOkrTitle(value)
        } else if (dataType === 'projectId') {
            setOkrProjectId(value)
        } else if (dataType === 'duration') {
            setOkrDuration(value)
            setParentObjective(getOkrDurationObj(value))
        } else if (dataType === 'resource') {
            setOkrResource(value)
        } else if (dataType === 'parentId') {
            setOkrParentId(value)
        }
    }

    useEffect(() => {
        if (parentOkrData) {
            setOkrProjectId(parentOkrData.ProjectID)
            if (parentOkrData.Duration === '52-week') {
                getOkrDurationObj(parentOkrData.Duration)
                setOkrDuration('13-week')
            } else if (parentOkrData.Duration === '13-week') {
                setOkrDuration('2-week')
            } else {
                setOkrDuration('52-week')
            }
            setOkrParentId(parentOkrData.ID)
        }
    }, [parentOkrData])

    const handleCreateOkr = (e) => {
        e.preventDefault()
        let Level = 0
        if (okrDuration === '52-week') {
            Level = 2
        } else if (okrDuration === '13-week') {
            Level = 3
        } else if (okrDuration === '2-week' || okrDuration === '1-week') {
            Level = 4
        }
        const okrData = {
            'Title': okrTitle,
            'ProjectID': okrProjectId,
            'Duration': okrDuration,
            'Resource': okrResource,
            'ParentID': okrParentId,
            'Level': Level,
            'Status': 0,
            'Score': 0
        }
        axios.post('/objectives', okrData).then(res => {
            if (res.data.message === 'Success') {
                setOkrTitle(null)
                setOkrProjectId(null)
                setOkrDuration('52-week')
                setOkrResource(null)
                setOkrParentId(1)
                setParentObjective(null)
                setRenderCount({renderCount: parseInt(renderCount) + 1})
                hideCreateOkrModal()
            }
        })
    }
  return (
    <Modal
      show={createOkrModalVisible}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      onHide={() => {hideCreateOkrModal()}}
    >
        <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
                <span>Add OKR</span>
            </Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <div class="section full">
                <form onSubmit={(e) => {handleCreateOkr(e)}}>
                    <div class="row">
                        <div class="col-md-12">
                            <div class="form-group boxed">
                                <div class="input-wrapper">
                                    <label class="form-label" for="title">Objective</label>
                                    <textarea id="title" rows="4" onChange={(e) => {handleOkrData(e.target.value, 'title')}} value={okrTitle} required class="form-control"></textarea>
                                    <i class="clear-input">
                                        <ion-icon name="close-circle"></ion-icon>
                                    </i>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group boxed">
                                <div class="input-wrapper">
                                    <label class="form-label" for="project">Project</label>
                                    <select class="form-control form-select" onChange={(e) => {handleOkrData(e.target.value, 'projectId')}} required value={okrProjectId} id="project">
                                        <option value="0">Select a city</option>
                                        {allProjectList?.map((v,k) => {
                                            return <option value={v.ID}>{v.Name}</option>
                                        })}
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group boxed">
                                <div class="input-wrapper">
                                    <label class="form-label" for="duration">Duration</label>
                                    <select class="form-control form-select" onChange={(e) => {handleOkrData(e.target.value, 'duration')}} required value={okrDuration} id="duration">
                                        <option value="52-week">1 Year</option>
                                        <option value="13-week">Quarterly</option>
                                        <option value="2-week">2 Week</option>
                                        <option value="1-week">1 Week</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        {(okrDuration === '13-week') &&
                            <div class="col-md-12">
                                <div class="form-group boxed">
                                    <div class="input-wrapper">
                                        <label class="form-label" for="resource">Resource</label>
                                        <select class="form-control form-select" required onChange={(e) => {handleOkrData(e.target.value, 'resource')}}  value={okrResource} id="resource">
                                            <option value="">Select User</option>
                                            {allUserList.map((v,k) => {
                                                return <option value={v.ID}>{v.UserName}</option>
                                            })}
                                        </select>
                                    </div>
                                </div>
                            </div>
                        }
                        {/* {(okrDuration === '2-week' || okrDuration === '13-week') &&
                            <div class="col-md-12">
                                <div class="form-group boxed">
                                    <div class="input-wrapper">
                                        <label class="form-label" for="objective">Objective</label>
                                        <select class="form-control form-select" onChange={(e) => {handleOkrData(e.target.value, 'parentId')}} value={okrParentId} required id="objective">
                                            <option value="">Select Objective</option>
                                            {parentObjective?.map((v,k) => {
                                                return <option value={v.ID}>{v.Title}</option> 
                                            })}
                                        </select>
                                    </div>
                                </div>
                            </div>
                        } */}
                    </div>
                    <button class="btn btn-success position-absolute" style={{top: -55, right: 50}}><ion-icon name="add-outline"></ion-icon> Add</button>
                </form>
            </div>
        </Modal.Body>
    </Modal>
  )
}

const mapStateToProps = ({ createOkr, data }) => {
    const {
        createOkrModalVisible,
        parentOkrData
    } = createOkr
    const {
        allProjectList,
        allUserList,
        renderCount
    } = data
  return {
    createOkrModalVisible,
    allProjectList,
    allUserList,
    parentOkrData,
    renderCount
  }
}

const mapDispatchToProps = {
    hideCreateOkrModal,
    setRenderCount
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateOkrModal)
