import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    addTaskModalVisible: false,
    parentOkrData: null
}

export const AddTaskSlice = createSlice({
  name: 'addTask',
  initialState,
  reducers: {
    showAddTaskModal: (state, action) => {
      const {
        addTaskModalVisible,
        parentOkrData
      } = action.payload
      state.addTaskModalVisible = addTaskModalVisible
      state.parentOkrData = parentOkrData
    },
    hideAddTaskModal: (state, action) => {
        state.addTaskModalVisible = false
      }
  }
})

export const { showAddTaskModal, hideAddTaskModal } = AddTaskSlice.actions;


export default AddTaskSlice.reducer
