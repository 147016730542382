import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    rightSidebarVisible: false
}

export const rightSidebarSlice = createSlice({
  name: 'rightSidebar',
  initialState,
  reducers: {
    showRightSidebar: (state, action) => {
      const {
        rightSidebarVisible
      } = action.payload
      state.rightSidebarVisible = rightSidebarVisible
    },
    hideRightSidebar: (state, action) => {
        state.rightSidebarVisible = false
      }
  }
})

export const { showRightSidebar, hideRightSidebar } = rightSidebarSlice.actions;


export default rightSidebarSlice.reducer
