import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    createOkrModalVisible: false,
    parentOkrData: null
}

export const CreateOkrSlice = createSlice({
  name: 'createOkr',
  initialState,
  reducers: {
    showCreateOkrModal: (state, action) => {
      const {
        createOkrModalVisible,
        parentOkrData
      } = action.payload
      state.createOkrModalVisible = createOkrModalVisible
      state.parentOkrData = parentOkrData
    },
    hideCreateOkrModal: (state, action) => {
        state.createOkrModalVisible = false
      }
  }
})

export const { showCreateOkrModal, hideCreateOkrModal } = CreateOkrSlice.actions;


export default CreateOkrSlice.reducer
