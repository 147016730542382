import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Tooltip, OverlayTrigger } from 'react-bootstrap';
import moment from 'moment';
import axios from '../../../AxiosConfig';
import Target from '../../images/icon/target.png';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { showCreateOkrModal } from '../../../features/modal/CreateOkrSlice';
import { showAddTaskModal } from '../../../features/modal/AddTaskSlice';
import { setRenderCount } from '../../../features/data/dataSlice'
import { showOkrScoreModal } from '../../../features/modal/OkrScoreSlice'

const TaskCard = ({
    taskCardData,
    objectiveData,
    showCreateOkrModal,
    showAddTaskModal,
    role,
    userData,
    renderCount,
    setRenderCount,
    showOkrScoreModal
}) => {
    const [uid, setUid] = useState(null) 
    const [pid, setPid] = useState(null) 
    const [qid, setQid] = useState(null)
    const [disableButton, setDisableButton] = useState(false)
    const [workingUserList, setWorkingUserList] = useState({})
    const [searchParams] = useSearchParams();
    const [userTooltip, setUserTooltip] = useState(null)
    
    let navigate = useNavigate();
    useEffect(() => {
        setUid(searchParams.get('uid'));
        setPid(searchParams.get('pid'));
        setQid(searchParams.get('qid'));
        // navigate('?pid=' + pid + '&uid=' + uid + '&qid=' + qid);
    }, [uid, pid, qid])
    const handleNextLevel = (data) => {
        // navigate('?pid=' + pid + '&uid=' + uid + '&qid=' + data.ID);
        setQid(data.ID)
    }
    const handleCreateOkr = (data) => {
        if (data.Level !== 4) {
            showCreateOkrModal({createOkrModalVisible: true, parentOkrData: data})
        } else {
            showAddTaskModal({addTaskModalVisible: true, parentOkrData: data})
        }
    }
    const handleTaskAssign = (data) => {
        setDisableButton(true)
        const currentDateTime = moment().format('YYYY-MM-DD HH:mm:ss')
        const playTaskData = {
            "TaskID": data.TaskID,
            "UserID": userData.ID,
            "DateTime": currentDateTime
        }
        axios.post('/Entries/startTask', playTaskData).then(res => {
            if (res.data.message === 'Success') {
                setDisableButton(false)
                setRenderCount({renderCount: parseInt(renderCount) + 1})
            } else {
                setDisableButton(false)
            }
        })
    }
    const showDetailView = (currentData) => {
        // console.log(currentData)
    }
    const handleOKRScore = (data) => {
        showOkrScoreModal({okrScoreModalVisible: true, okrScoreData: data})
    }
    useEffect(() => {
        // console.log(objectiveData)
        let userDataList = []
        let tempWorkingUser = {}
        if (objectiveData) {
            let tempObjectiveData = JSON.parse(JSON.stringify(objectiveData))
            tempObjectiveData.map((v,k) => {
                if (v.ParentID === v.ID) {
                    if (v.UserID) {
                        userDataList.push(v.UserID)
                    }
                }
                if (v.UserID) {
                    if (!tempWorkingUser.hasOwnProperty(v.ParentID)) {
                        tempWorkingUser[v.ParentID] = []
                        tempWorkingUser[v.ParentID].push(v.UserName)
                    } else {
                        if (!tempWorkingUser[v.ParentID].includes(v.UserName)) {
                            tempWorkingUser[v.ParentID].push(v.UserName)
                        }
                    }
                }
            })
        }
        setUserTooltip (
            <Tooltip>
                {tempWorkingUser[taskCardData.ID]?.join(', ')}
            </Tooltip>
        );
    }, [objectiveData])
    return (
        <div class={(taskCardData.Level !== 1) ? `card mb-2 me-sm-2 text-dark cursor-pointer` : `card text-dark cursor-pointer`} style={{backgroundColor: (taskCardData.Level == 2) ? 'gainsboro' : '', position: 'relative'}} onClick={() => {handleNextLevel(taskCardData)}}>
            <div className="position-absolute bg-success text-success" style={{ top: 0, right: 0, left: 0, height: '3px', width: parseInt((objectiveData?.filter((itm) => itm.ParentID === taskCardData?.ID)).length) ? `${(parseInt((objectiveData?.filter((itm) => itm.TaskStatus === 'completed' && itm.ParentID === taskCardData?.ID)).length) / parseInt((objectiveData?.filter((itm) => itm.ParentID === taskCardData?.ID)).length)) * 100}%` : '0%' }}></div>
            <div class="card-body p-1" onClick={() => {showDetailView(taskCardData)}}>
                <div class="d-flex flex-column">
                    <span class="fs-6 pb-1">
                        <span class="fw-bold d-flex justify-content-between">
                            <div class="d-flex">
                                {taskCardData.Level === 1 &&
                                    <img src={Target} class="me-1" style={{height: '20px', width: '20px', minHeight: '20px', minWidth: '20px', borderRadius: '100px'}} />
                                }
                                {taskCardData.Level == 2 &&
                                    <span class="bg-success d-flex justify-content-center align-items-center me-1" style={{height: '20px', width: '20px', minHeight: '20px', minWidth: '20px', borderRadius: '100px'}}>Y</span>
                                }
                                {taskCardData.Level == 3 &&
                                    <span class="bg-primary d-flex justify-content-center align-items-center me-1" style={{height: '20px', width: '20px', minHeight: '20px', minWidth: '20px', borderRadius: '100px'}}>Q</span>
                                }
                                {taskCardData.Level == 4 &&
                                    <span class="bg-dark d-flex justify-content-center align-items-center me-1" style={{height: '20px', width: '20px', minHeight: '20px', minWidth: '20px', borderRadius: '100px'}}>S</span>
                                }
                                {taskCardData?.Title}
                            </div>
                            {(taskCardData.Level !== 1 && workingUserList[taskCardData.ID]) &&
                                <OverlayTrigger
                                    placement="top"
                                    delay={{ show: 250, hide: 400 }}
                                    overlay={userTooltip}
                                >
                                    <span class="badge bg-secondary" style={{fontSize: '9px', height: '16px', minWidth: '90px'}}>{(workingUserList[taskCardData.ID]) ? workingUserList[taskCardData.ID][0] : ''} + {(workingUserList[taskCardData.ID]) ? workingUserList[taskCardData.ID].length - 1 : ''}</span>
                                </OverlayTrigger>
                            }
                        </span>
                        {role === 2 &&
                            <>
                                {taskCardData.Level !== 1 &&
                                    <div class="divider bg-success mt-2 mb-2" onClick={() => {handleCreateOkr(taskCardData)}}>
                                        <div class="badge bg-success">
                                            <ion-icon name="add-outline"></ion-icon>&nbsp;
                                            {taskCardData.Level === 2 &&
                                                <span>Add Querterly</span>
                                            }
                                            {taskCardData.Level === 3 &&
                                                <span>Add Sprint</span>
                                            }
                                            {taskCardData.Level === 4 &&
                                                <span>Add Task</span>
                                            }
                                        </div>
                                    </div>
                                }
                            </>
                        }
                    </span>
                    <ul class={"ps-2 mt-1 mb-1"}>
                        {objectiveData.map((v,k) => {
                            return <>
                                {v.TaskStatus !== 'completed' &&
                                    <>
                                        {taskCardData.ID === v.ParentID &&
                                            <li class={"cursor-pointer d-flex justify-content-between align-items-center"} onClick={(v.Level === 5) ? (() => {handleOKRScore(v)}) : null}>
                                                <span>
                                                    <ion-icon name="square-outline" class="me-1" style={{minWidth: '15px', marginTop: '5px'}}></ion-icon>
                                                {v.Title}
                                                </span>
                                                {(v.Level === 5 && v.TaskStatus === 'toDo') &&
                                                    <>
                                                        {!disableButton &&
                                                            <span class="badge bg-success" onClick={() => {handleTaskAssign(v)}} style={{minWidth: '55px'}}>
                                                                <ion-icon name="play"></ion-icon>&nbsp;Start
                                                            </span>
                                                        }
                                                        {disableButton &&
                                                            <span class="badge bg-success" style={{minWidth: '75px', opacity: '0.7'}}>
                                                                Assigning...
                                                            </span>
                                                        }
                                                    </>
                                                }
                                                <span class="ms-1">
                                                    ({v.Score})
                                                </span>
                                            </li> 
                                        }
                                    </>
                                }
                                {v.TaskStatus === 'completed' &&
                                    <>
                                        {v.Level === 5 &&
                                            <>
                                                {taskCardData.ID === v.ParentID &&    
                                                    <li class={"cursor-pointer d-flex align-items-center  justify-content-between text-muted"} style={{ fontSize: 'initial'}} onClick={(v.Level === 5) ? (() => {handleOKRScore(v)}) : null}>
                                                        <span style={{textDecoration: 'line-through'}}>
                                                            <ion-icon name="checkbox-outline" class="me-1" style={{marginTop: '5px'}}></ion-icon>
                                                            {v.Title}
                                                        </span>
                                                        <span>
                                                            ({v.Score})
                                                        </span>
                                                        {/* {(v.Level === 5 && v.TaskStatus === 'completed') &&
                                                            <>
                                                                <span class="badge bg-success" style={{minWidth: '75px'}}>
                                                                    <ion-icon name="checkmark-done-outline"></ion-icon>&nbsp;Completed
                                                                </span>
                                                            </>
                                                        } */}
                                                    </li> 
                                                }
                                            </>
                                        }
                                    </>
                                }
                            </>
                        })}
                    </ul>
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = ({ data, auth }) => {
    const {
        objectiveData,
        renderCount
    } = data
    const {
        role,
        userData
    } = auth
    return {
        objectiveData,
        role,
        userData,
        renderCount
    }
}
  
const mapDispatchToProps = {
    showCreateOkrModal,
    showAddTaskModal,
    setRenderCount,
    showOkrScoreModal
}

export default connect(mapStateToProps, mapDispatchToProps)(TaskCard);