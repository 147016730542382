import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import moment from 'moment'
import { showPreloader } from '../../features/data/dataSlice';

const Ranking = ({
    allUserList,
    showPreloader
}) => {
    const [userID, setUserID] = useState(null)
    const [maxDate, setMaxDate] = useState('')
    const [fromDate, setFromDate] = useState('')
    const [toDate, setToDate] = useState('')

    const handleUserFilter = (value) => {
        setUserID(value)
    }
    useEffect(() => {
        const moment = require('moment');
        const firstDateOfMonth = moment().startOf('month').format('YYYY-MM-DD');
        const yesterdayDate = moment().subtract(1, 'days').format('YYYY-MM-DD');
        // const yesterday = moment().subtract(1, 'day').format('YYYY-MM-DD');
        setFromDate(firstDateOfMonth)
        setToDate(yesterdayDate)
        setMaxDate(yesterdayDate)
        showPreloader({preloaderVisible: false})
    }, [])
    return (
        <div id="appCapsule">
            <div class="section mt-2 mb-2">
                <div class="row">
                    {/* <div class="col-12 col-sm-4 d-flex">
                        <div class="form-group boxed">
                            <div class="input-wrapper w-100">
                                <label class="form-label" for="userList">Select User</label>
                                <select class="form-control form-select" onChange={(e) => {handleUserFilter(e.target.value)}} id="userList">
                                    <option value="">Select user name</option>
                                    {allUserList?.map((v,k) => {
                                        return <option value={v.ID}>{v.UserName}</option>
                                    })}
                                </select>
                            </div>
                        </div>
                    </div> */}
                    <div class="col-12 col-sm-12">
                        <div class="row">
                            <div class="col-12 col-sm-12">
                                <div class="form-group boxed d-flex justify-content-between justify-content-sm-end">
                                    <div class="input-wrapper me-1">
                                        <label class="form-label" for="from">From</label>
                                        <input type="date" max={maxDate} onChange={(e) => {setFromDate(e.target.value)}} value={fromDate} class="form-control form-control-sm" id="datepicker" placeholder="" autocomplete="off" />
                                        <i class="clear-input">
                                            <ion-icon name="close-circle"></ion-icon>
                                        </i>
                                    </div>
                                    <div class="input-wrapper">
                                        <label class="form-label" for="to">To</label>
                                        <input type="date" max={maxDate} value={toDate} onChange={(e) => {setToDate(e.target.value)}} class="form-control form-control-sm" id="datepicker" placeholder="" autocomplete="off" />
                                        <i class="clear-input">
                                            <ion-icon name="close-circle"></ion-icon>
                                        </i>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="pageTitle fs-4 fw-bold mt-2 mb-2 d-flex justify-content-between align-items-center">
                        Top Performer
                        <button class="btn btn-link pe-0" style={{height: '0px'}}>View all</button>
                    </div>
                    <div class="col-md-4">
                        <div class="card text-white mb-1">
                            <div class="d-flex justify-content-center align-items-center fs-1 fw-bold text-white" style={{background: 'linear-gradient(135deg, #c5a12e, #ffd700)', position: 'absolute', borderBottomRightRadius: '100px', top: 0, left: 0, height: '105px', width: '105px', padding: '0px 25px 20px 0px'}}>#1</div>
                            <div class="card-body">
                                <h5 class="card-title text-center ps-5">Nikhil Jadhav</h5>
                                <p class="card-text text-center ps-5 fs-5 text-dark">150 hrs 35 mins</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="card mb-1">
                        <div class="d-flex justify-content-center align-items-center fs-1 fw-bold text-white" style={{background: 'linear-gradient(135deg, #808080, #C0C0C0)', position: 'absolute', borderBottomRightRadius: '100px', top: 0, left: 0, height: '105px', width: '105px', padding: '0px 25px 20px 0px'}}>#2</div>
                            <div class="card-body">
                                <h5 class="card-title text-center ps-5">Amruta Patankar</h5>
                                <p class="card-text text-center ps-5 fs-5">118 hrs 48 mins</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="card mb-1">
                        <div class="d-flex justify-content-center align-items-center fs-1 fw-bold text-white" style={{background: 'linear-gradient(135deg, #8C5B3D, #D19A5A)', position: 'absolute', borderBottomRightRadius: '100px', top: 0, left: 0, height: '105px', width: '105px', padding: '0px 25px 20px 0px'}}>#3</div>
                            <div class="card-body">
                                <h5 class="card-title text-center ps-5">Rohit Patankar</h5>
                                <p class="card-text text-center ps-5 fs-5">115 hrs 36 mins</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="pageTitle fs-4 fw-bold mt-2 mb-2 d-flex justify-content-between align-items-center">
                        Attendance Leader
                        <button class="btn btn-link pe-0" style={{height: '0px'}}>View all</button>
                    </div>
                    <div class="col-md-4">
                        <div class="card text-white mb-1">
                            <div class="d-flex justify-content-center align-items-center fs-1 fw-bold" style={{background: 'linear-gradient(135deg, #c5a12e, #ffd700)', position: 'absolute', borderBottomRightRadius: '100px', top: 0, left: 0, height: '105px', width: '105px', padding: '0px 25px 20px 0px'}}>#1</div>
                            <div class="card-body">
                                <h5 class="card-title text-center">Card 1</h5>
                                <p class="card-text text-center text-dark">Height: 150px</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="card mb-1">
                        <div class="d-flex justify-content-center align-items-center fs-1 fw-bold text-white" style={{background: 'linear-gradient(135deg, #808080, #C0C0C0)', position: 'absolute', borderBottomRightRadius: '100px', top: 0, left: 0, height: '105px', width: '105px', padding: '0px 25px 20px 0px'}}>#2</div>
                            <div class="card-body">
                                <h5 class="card-title text-center">Card 1</h5>
                                <p class="card-text text-center">Height: 150px</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="card mb-1">
                        <div class="d-flex justify-content-center align-items-center fs-1 fw-bold text-white" style={{background: 'linear-gradient(135deg, #8C5B3D, #D19A5A)', position: 'absolute', borderBottomRightRadius: '100px', top: 0, left: 0, height: '105px', width: '105px', padding: '0px 25px 20px 0px'}}>#3</div>
                            <div class="card-body">
                                <h5 class="card-title text-center">Card 1</h5>
                                <p class="card-text text-center">Height: 150px</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="pageTitle fs-4 fw-bold mt-2 mb-2 d-flex justify-content-between align-items-center">
                        Punctuality Champion
                        <button class="btn btn-link pe-0" style={{height: '0px'}}>View all</button>
                    </div>
                    <div class="col-md-4">
                        <div class="card text-white mb-1">
                            <div class="d-flex justify-content-center align-items-center fs-1 fw-bold" style={{background: 'linear-gradient(135deg, #c5a12e, #ffd700)', position: 'absolute', borderBottomRightRadius: '100px', top: 0, left: 0, height: '105px', width: '105px', padding: '0px 25px 20px 0px'}}>#1</div>
                            <div class="card-body">
                                <h5 class="card-title text-center">Card 1</h5>
                                <p class="card-text text-center text-dark">Height: 150px</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="card mb-1">
                        <div class="d-flex justify-content-center align-items-center fs-1 fw-bold text-white" style={{background: 'linear-gradient(135deg, #808080, #C0C0C0)', position: 'absolute', borderBottomRightRadius: '100px', top: 0, left: 0, height: '105px', width: '105px', padding: '0px 25px 20px 0px'}}>#2</div>
                            <div class="card-body">
                                <h5 class="card-title text-center">Card 1</h5>
                                <p class="card-text text-center">Height: 150px</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="card mb-1">
                        <div class="d-flex justify-content-center align-items-center fs-1 fw-bold text-white" style={{background: 'linear-gradient(135deg, #8C5B3D, #D19A5A)', position: 'absolute', borderBottomRightRadius: '100px', top: 0, left: 0, height: '105px', width: '105px', padding: '0px 25px 20px 0px'}}>#3</div>
                            <div class="card-body">
                                <h5 class="card-title text-center">Card 1</h5>
                                <p class="card-text text-center">Height: 150px</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="pageTitle fs-4 fw-bold mt-2 mb-2 d-flex justify-content-between align-items-center">
                        Best Break Management
                        <button class="btn btn-link pe-0" style={{height: '0px'}}>View all</button>
                    </div>
                    <div class="col-md-4">
                        <div class="card text-white mb-1">
                            <div class="d-flex justify-content-center align-items-center fs-1 fw-bold" style={{background: 'linear-gradient(135deg, #c5a12e, #ffd700)', position: 'absolute', borderBottomRightRadius: '100px', top: 0, left: 0, height: '105px', width: '105px', padding: '0px 25px 20px 0px'}}>#1</div>
                            <div class="card-body">
                                <h5 class="card-title text-center">Card 1</h5>
                                <p class="card-text text-center text-dark">Height: 150px</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="card mb-1">
                        <div class="d-flex justify-content-center align-items-center fs-1 fw-bold text-white" style={{background: 'linear-gradient(135deg, #808080, #C0C0C0)', position: 'absolute', borderBottomRightRadius: '100px', top: 0, left: 0, height: '105px', width: '105px', padding: '0px 25px 20px 0px'}}>#2</div>
                            <div class="card-body">
                                <h5 class="card-title text-center">Card 1</h5>
                                <p class="card-text text-center">Height: 150px</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="card mb-1">
                        <div class="d-flex justify-content-center align-items-center fs-1 fw-bold text-white" style={{background: 'linear-gradient(135deg, #8C5B3D, #D19A5A)', position: 'absolute', borderBottomRightRadius: '100px', top: 0, left: 0, height: '105px', width: '105px', padding: '0px 25px 20px 0px'}}>#3</div>
                            <div class="card-body">
                                <h5 class="card-title text-center">Card 1</h5>
                                <p class="card-text text-center">Height: 150px</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = ({ data }) => {
    const { allUserList } = data
    return {
      allUserList
    }
}
  
const mapDispatchToProps = {
    showPreloader
}

export default connect(mapStateToProps, mapDispatchToProps)(Ranking);