import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  usersListData: [],
  taskList: null,
  attendanceList: null,
  projectsList: null,
  refresh: 0,
  renderCount: 0,
  attendanceListData: [],
  renderProgressBar: 0,
  preloaderVisible: true,
  disabledAllButtons: false,
  allUserList: null,
  allProjectList: null,
  objectiveData: null
}

export const dataSlice = createSlice({
  name: 'data',
  initialState,
  reducers: {
    setUsersListData: (state, action) => {
      const {
        usersListData
      } = action.payload
      state.usersListData = usersListData
    },
    setUsersList: (state, action) => {
      const {
        usersList
      } = action.payload
      state.usersList = usersList
    },
    setTasksList: (state, action) => {
      const {
        taskList
      } = action.payload
      state.taskList = taskList
    },
    setAttendanceList: (state, action) => {
      const {
        attendanceList
      } = action.payload
      state.attendanceList = attendanceList
    },
    setAttendanceListData: (state, action) => {
      const {
        attendanceListData
      } = action.payload
      state.attendanceListData = attendanceListData
    },
    setProjectsList: (state, action) => {
      const {
        projectsList
      } = action.payload
      state.projectsList = projectsList
    },
    setRefresh : (state, action) => {
      state.refresh = action.payload
    },
    setRenderCount: (state, action) => {
      const {
        renderCount
      } = action.payload
      state.renderCount = renderCount
    },
    setRenderProgressBar: (state, action) => {
      const {
        renderProgressBar
      } = action.payload
      state.renderProgressBar = renderProgressBar
    },
    showPreloader: (state, action) => {
      const {
        preloaderVisible
      } = action.payload
      state.preloaderVisible = preloaderVisible
    },
    actionDisabledAllButtons: (state, action) => {
      const {
        disabledAllButtons
      } = action.payload
      state.disabledAllButtons = disabledAllButtons
    },
    setAllUserList: (state, action) => {
      const {
        allUserList
      } = action.payload
      state.allUserList = allUserList
    },
    setAllProjectList: (state, action) => {
      const {
        allProjectList
      } = action.payload
      state.allProjectList = allProjectList
    },
    setObjectiveData: (state, action) => {
      const {
        objectiveData
      } = action.payload
      state.objectiveData = objectiveData
    },
  }
})

export const { setUsersListData, setUsersList, setTasksList, setAttendanceList, setAttendanceListData, setProjectsList, setRefresh, setRenderCount, setRenderProgressBar, showPreloader, actionDisabledAllButtons, setAllUserList, setAllProjectList, setObjectiveData } = dataSlice.actions;


export default dataSlice.reducer
