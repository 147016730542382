import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { Modal } from 'react-bootstrap'
import { hideOkrScoreModal } from '../../../features/modal/OkrScoreSlice'
import axios from '../../../AxiosConfig'
import { showPreloader, setRenderCount } from '../../../features/data/dataSlice'

const ScoreModal = ({ 
    okrScoreModalVisible,
    hideOkrScoreModal,
    okrScoreData,
    showPreloader,
    setRenderCount,
    renderCount
 }) => {
    const [score, setScore] = useState((okrScoreData) ? okrScoreData.Score : null)
    const updateScore = (score) => {
        showPreloader({preloaderVisible: true})
        setScore(score)
        const scoreData = {
            'Score': score
        }
        axios.post('/tasks/update/' + okrScoreData.TaskID, scoreData).then(res => {
            if (res.data.message === 'Success') {
                console.log(res.data.data)
                showPreloader({preloaderVisible: false})
                setRenderCount({renderCount: parseInt(renderCount) + 1})
                hideOkrScoreModal()
            } else {
                console.log('Something went wrong')
            }
        }).catch(err => {
            console.error(err);
        });
    }
    useEffect(() => {
        if (okrScoreData) {
            setScore(okrScoreData.Score)
        }
    }, [okrScoreData])
  return (
    <Modal
      show={okrScoreModalVisible}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      onHide={() => {hideOkrScoreModal()}}
    >
        <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
                <h3 class="text-wrap mb-0">{(okrScoreData) ? okrScoreData.Title : 'Score Modal'}</h3>
            </Modal.Title>
        </Modal.Header>
        <Modal.Body class="p-2 pb-0">
            <div class="section full">
                <div class="row d-flex flex-nowrap overflow-scroll pb-0">
                    <div class="col ps-1 pe-1">
                        <div class="btn-group" role="group">
                            <input type="radio" class="btn-check" name="btnradiog" id="btnradios1" value="1" onChange={(e) => {updateScore(e.target.value)}} checked={(score == 1) ? true : false} />
                            <label class="btn btn-icon btn-outline-primary" for="btnradios1">1</label>
                        </div>
                    </div>

                    <div class="col ps-1 pe-1">
                        <div class="btn-group" role="group">
                            <input type="radio" class="btn-check" name="btnradiog" id="btnradios2" value="2" onChange={(e) => {updateScore(e.target.value)}} checked={(score == 2) ? true : false} />
                            <label class="btn btn-icon btn-outline-primary" for="btnradios2">2</label>
                        </div>
                    </div>

                    <div class="col ps-1 pe-1">
                        <div class="btn-group" role="group">
                            <input type="radio" class="btn-check" name="btnradiog" id="btnradios3" value="3" onChange={(e) => {updateScore(e.target.value)}} checked={(score == 3) ? true : false} />
                            <label class="btn btn-icon btn-outline-primary" for="btnradios3">3</label>
                        </div>
                    </div>

                    <div class="col ps-1 pe-1">
                        <div class="btn-group" role="group">
                            <input type="radio" class="btn-check" name="btnradiog" id="btnradios4" value="4" onChange={(e) => {updateScore(e.target.value)}} checked={(score == 4) ? true : false} />
                            <label class="btn btn-icon btn-outline-primary" for="btnradios4">4</label>
                        </div>
                    </div>

                    <div class="col ps-1 pe-1">
                        <div class="btn-group" role="group">
                            <input type="radio" class="btn-check" name="btnradiog" id="btnradios5" value="5" onChange={(e) => {updateScore(e.target.value)}} checked={(score == 5) ? true : false} />
                            <label class="btn btn-icon btn-outline-primary" for="btnradios5">5</label>
                        </div>
                    </div>

                    <div class="col ps-1 pe-1">
                        <div class="btn-group" role="group">
                            <input type="radio" class="btn-check" name="btnradiog" id="btnradios6" value="6" onChange={(e) => {updateScore(e.target.value)}} checked={(score == 6) ? true : false} />
                            <label class="btn btn-icon btn-outline-primary" for="btnradios6">6</label>
                        </div>
                    </div>

                    <div class="col ps-1 pe-1">
                        <div class="btn-group" role="group">
                            <input type="radio" class="btn-check" name="btnradiog" id="btnradios7" value="7" onChange={(e) => {updateScore(e.target.value)}} checked={(score == 7) ? true : false} />
                            <label class="btn btn-icon btn-outline-primary" for="btnradios7">7</label>
                        </div>
                    </div>

                    <div class="col ps-1 pe-1">
                        <div class="btn-group" role="group">
                            <input type="radio" class="btn-check" name="btnradiog" id="btnradios8" value="8" onChange={(e) => {updateScore(e.target.value)}} checked={(score == 8) ? true : false} />
                            <label class="btn btn-icon btn-outline-primary" for="btnradios8">8</label>
                        </div>
                    </div>

                    <div class="col ps-1 pe-1">
                        <div class="btn-group" role="group">
                            <input type="radio" class="btn-check" name="btnradiog" id="btnradios9" value="9" onChange={(e) => {updateScore(e.target.value)}} checked={(score == 9) ? true : false} />
                            <label class="btn btn-icon btn-outline-primary" for="btnradios9">9</label>
                        </div>
                    </div>

                    <div class="col ps-1 pe-1">
                        <div class="btn-group" role="group">
                            <input type="radio" class="btn-check" name="btnradiog" id="btnradios10" value="10" onChange={(e) => {updateScore(e.target.value)}} checked={(score == 10) ? true : false} />
                            <label class="btn btn-icon btn-outline-primary" for="btnradios10">10</label>
                        </div>
                    </div>
                </div>
            </div>
        </Modal.Body>
    </Modal>
  )
}

const mapStateToProps = ({ okrScore, data }) => {
    const {
        okrScoreModalVisible,
        okrScoreData
    } = okrScore
    const {
        renderCount
    } = data
  return {
    okrScoreModalVisible,
    okrScoreData,
    renderCount
  }
}

const mapDispatchToProps = {
    hideOkrScoreModal,
    showPreloader,
    setRenderCount
}

export default connect(mapStateToProps, mapDispatchToProps)(ScoreModal)
