import React from 'react'
import UsersChatsList from './UsersChatsList'
import UserChatView from './UserChatView'
import { useSearchParams } from 'react-router-dom'

const Conversations = () => {
  const [searchParams, setSearchParams] = useSearchParams({ chatId: '' })

  const chatId = searchParams.get('chatId')
  return (
    <>
      <div id="appCapsule">
        <div className="section full">
          <div className="row">
            <div
              className={`col-12 ${
                chatId === '' ? '' : 'd-none'
              } d-sm-block col-sm-5 col-md-4 col-lg-3 bg-light px-0`}
            >
              <UsersChatsList />
            </div>
            <div
              className={`col-12 ${
                chatId === '' ? 'd-none' : ''
              } col-sm-7 col-md-8 col-lg-9 px-0`}
            >
              <UserChatView />
            </div>
          </div>
        </div>
      </div>
      <div
        class="offcanvas offcanvas-bottom action-sheet inset"
        tabindex="-1"
        id="actionSheetAdd"
      >
        <div class="offcanvas-header">
          <h5 class="offcanvas-title">Share</h5>
        </div>
        <div class="offcanvas-body">
          <ul class="action-button-list">
            <li>
              <a href="#" class="btn btn-list" data-bs-dismiss="offcanvas">
                <span>
                  <ion-icon name="camera-outline"></ion-icon>
                  Take a photo
                </span>
              </a>
            </li>
            <li>
              <a href="#" class="btn btn-list" data-bs-dismiss="offcanvas">
                <span>
                  <ion-icon name="videocam-outline"></ion-icon>
                  Video
                </span>
              </a>
            </li>
            <li>
              <a href="#" class="btn btn-list" data-bs-dismiss="offcanvas">
                <span>
                  <ion-icon name="image-outline"></ion-icon>
                  Upload from Gallery
                </span>
              </a>
            </li>
            <li>
              <a href="#" class="btn btn-list" data-bs-dismiss="offcanvas">
                <span>
                  <ion-icon name="document-outline"></ion-icon>
                  Documents
                </span>
              </a>
            </li>
            <li>
              <a href="#" class="btn btn-list" data-bs-dismiss="offcanvas">
                <span>
                  <ion-icon name="musical-notes-outline"></ion-icon>
                  Sound file
                </span>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </>
  )
}

export default Conversations
