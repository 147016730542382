import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux'
import { useNavigate } from 'react-router-dom';
import { showPreloader } from '../../features/data/dataSlice';
import axios from '../../AxiosConfig';
import moment from 'moment';
import Moment from 'react-moment';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import { Tooltip, Popover } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import { showPinModal } from '../../features/modal/PinModalSlice';

const UserRecord = ({
    showPreloader,
    role,
    showPinModal
}) => {
    const [userList, setUserList] = useState([])
    const [maxDate, setMaxDate] = useState('')
    const [fromDate, setFromDate] = useState('')
    const [toDate, setToDate] = useState('')
    const [userID, setUserID] = useState('')
    const [userReport, setUserReport] = useState([])
    const [totalWorkingDuration, setTotalWorkingDuration] = useState(null)
    const [totalProductiveDuration, setTotalProductiveDuration] = useState('')
    const [totalDays,setTotalDays] = useState(0)
    const [totalCheckInCount, setTotalCheckInCount] = useState(0)
    const [totalOTCount, setTotalOTCount] = useState(0)
    const [totalLateCount, setTotalLateCount] = useState(0)
    const [workingPercentage, setWorkingPercentage] = useState(0)
    const [productivePercentage, setProductivePercentage] = useState(0)
    const [uniqueProjectCount, setUniqueProjectCount] = useState(0)
    const [actualWorkingHour, setActualWorkingHour] = useState(0)
    const [totalBreakDuration, setTotalBreakDuration] = useState(0)
    const [totalBreakDurationPercentage, setTotalBreakDurationPercentage] = useState(0)
    let navigate = useNavigate()
    let uniqueProjectArr = []
    let sumTotalBreakDuration = 0
    useEffect(() => {
        if (role != 2) {
            navigate('/')
        }
        const moment = require('moment');
        const firstDateOfMonth = moment().startOf('month').format('YYYY-MM-DD');
        const yesterdayDate = moment().subtract(1, 'days').format('YYYY-MM-DD');
        setFromDate(firstDateOfMonth)
        setToDate(yesterdayDate)
        const verified = localStorage.getItem('verified')
        if (verified) {
            showPinModal({pinModalVisible: false})    
        } else {
            showPinModal({pinModalVisible: true})
        }
    }, [])
    useEffect(() => {
        const yesterday = moment().subtract(1, 'day').format('YYYY-MM-DD');
        setMaxDate(yesterday)
        axios.get('/users').then(res => {
            if (res.data.message === 'Success') {
                setUserList(res.data.data)
                showPreloader({preloaderVisible: false})
            }
        })
    }, [])
    function isBeforeOrAfter10AM(dateTime) {
        const date = new Date(dateTime);
      
        const checkTime = new Date(date.getFullYear(), date.getMonth(), date.getDate(), 10, 31, 0, 0);
      
        return date < checkTime;
    }
    function isAfter11AM(dateTime) {
        const date = new Date(dateTime);
      
        const checkTime = new Date(date.getFullYear(), date.getMonth(), date.getDate(), 11, 1, 0, 0);
      
        return date < checkTime;
    }
    function formatDuration(startTime, endTime) {
        const start = moment(startTime);
        const end = moment(endTime);
      
        const duration = moment.duration(end.diff(start));
      
        const hours = Math.floor(duration.asHours());
        const minutes = duration.minutes();
      
        return `${hours} hrs ${minutes} mins`;
    }
    function sumDurations(durations) {
        let totalMinutes = 0;
    
        durations.forEach(item => {
            const [hrs, mins] = item.duration.replace(/-/g, '').trim().match(/(\d+)\s*hrs\s*(\d+)\s*mins/).slice(1, 3).map(Number);
            totalMinutes += hrs * 60 + mins;
        });
    
        const totalHrs = Math.floor(totalMinutes / 60);
        const remainingMins = totalMinutes % 60;
    
        return `${totalHrs} hrs ${remainingMins} mins`;
    }
    function isMoreThanEightHours(duration) {
        const [hrs, mins] = duration.match(/(\d+)\s*hrs\s*(\d+)\s*mins/).slice(1, 3).map(Number);
        const totalMinutes = hrs * 60 + mins;
        
        // 8 hours = 480 minutes
        return totalMinutes > 480;
    }
    useEffect(() => {
        if (fromDate && toDate && userID) {
            showPreloader({preloaderVisible: true})
            axios.get('/attendanceUserWise/' + userID + '/' + fromDate + '/' + toDate).then(res => {
                if (res.data.message === 'Success') {
                    showPreloader({preloaderVisible: false})
                    const tempUserRecordData = res.data.data;
                    let tempTotalWorkingDuration = moment.duration(0); 
                    tempUserRecordData.map((v,k) => {
                        let tempArr = []
                        let tempBreakArr = []
                        const checkIn = v.CheckIn;
                        const checkOut = v.CheckOut;
                        {v.Timeline.map((v1,k1) => {
                            let tempObj = {
                            }
                            if (k1 > 0) {
                                if (v1.Status == 'onGoing' && (v1.TaskID == v.Timeline[k1 - 1].TaskID) && ((v.Timeline[k1 - 1].Status == 'completed') || v.Timeline[k1 - 1].Status == 'paused') && v1.TaskName != 'BREAK') {
                                    tempObj.duration = formatDuration(v1.DateTime, v.Timeline[k1 - 1].DateTime)
                                    tempObj.taskname = v1.TaskName
                                    tempObj.projectName = v1.ProjectName
                                    tempArr.push(tempObj) 
                                }
                                if (v1.Status == 'onGoing' && (901 == v.Timeline[k1 - 1].TaskID) && ((v.Timeline[k1 - 1].Status == 'completed') || v.Timeline[k1 - 1].Status == 'paused')) {
                                    tempObj.duration = formatDuration(v1.DateTime, v.Timeline[k1 - 1].DateTime)
                                    tempObj.taskname = v1.TaskName
                                    tempObj.projectName = v1.ProjectName
                                    tempBreakArr.push(tempObj) 
                                }
                            }
                        })}
                        tempUserRecordData[k].TotalProductiveDuration = sumDurations(tempArr);
                        tempUserRecordData[k].TotalBreakDuration = sumDurations(tempBreakArr)
                        tempUserRecordData[k].isMoreThanEightHours = isMoreThanEightHours(sumDurations(tempArr))
                        let projectArr = []
                        tempArr.map((v,k) => {
                            if (!projectArr.includes(v.projectName)) {   
                                projectArr.push(v.projectName)
                            }
                            if (!uniqueProjectArr.includes(v.projectName)) {
                                uniqueProjectArr.push(v.projectName)
                            }
                        })
                        
                        tempUserRecordData[k].ProjectArr = projectArr
                        tempUserRecordData[k].CheckInStatus = isBeforeOrAfter10AM(v.CheckIn)
                        tempUserRecordData[k].IsAfter11AM = isAfter11AM(v.CheckIn)
                        const start = moment(checkIn);
                        const end = moment(checkOut);

                        const duration = moment.duration(end.diff(start));

                        const formattedDuration = duration.format("h [hrs] m [mins]");
                        const isMoreThanNineHours = duration.asHours() > 9;
                        tempUserRecordData[k].IsMoreThanNineHours = isMoreThanNineHours;
                        tempUserRecordData[k].WorkingDuration = formattedDuration;
                        tempTotalWorkingDuration.add(duration);
                        
                    })
                    setUniqueProjectCount(uniqueProjectArr.length)
                    const tempFromDate = moment(fromDate);
                    const tempToDate = moment(toDate);

                    const generateDateRange = (start, end) => {
                        const dates = [];
                        let current = moment(start);
                        while (current <= end) {
                            dates.push(current.format("YYYY-MM-DD"));
                            current.add(1, 'days');
                        }
                        return dates;
                    };
                    const allDates = generateDateRange(tempFromDate, tempToDate);
                    const existingData = tempUserRecordData.reduce((acc, item) => {
                        acc[item.Date] = item;
                        return acc;
                    }, {});
                    const updatedData = [];

                    allDates.forEach(date => {
                        if (existingData[date]) {
                            updatedData.push(existingData[date]);
                        } else {
                            updatedData.push({
                                Date: date,
                                CheckIn: null,
                                CheckOut: null,
                                IsMoreThanNineHours: false,
                                WorkingDuration: "-"
                            });
                        }
                    });
                    const formattedTotalWorkingDuration = moment.duration(tempTotalWorkingDuration).format("h [hrs] m [mins]");
                    setTotalWorkingDuration(formattedTotalWorkingDuration);
                    setUserReport(updatedData)
                    console.log(updatedData)
                    let tempTotalDay = 0
                    let tempTotalCheckIn = 0
                    let tempTotalOT = 0
                    let tempTotalLateCount = 0
                    let tempTotalProductiveDuration = ''
                    updatedData.map((v,k) => {
                        if (v.TotalProductiveDuration) {
                            const [tempHours, mins] = v.TotalProductiveDuration.split(' ').filter(str => /\d/.test(str)).map(Number);
                            totalProductiveMinutes += (tempHours * 60) + mins;

                            const hours = Math.floor(totalProductiveMinutes / 60);
                            const minutes = totalProductiveMinutes % 60;
                            setTotalProductiveDuration(`${hours} hrs ${minutes} mins`);
                            tempTotalProductiveDuration = `${hours}hrs ${minutes}mins`
                        }
                        if ((checkOddEvenSaturday(v.Date) && (moment(v.Date).format('ddd') == 'Sat') || (moment(v.Date).format('ddd') == 'Sun'))) {
                            
                        } else {
                            tempTotalDay += 1
                        }
                        if (v.CheckInStatus != true) {
                            if ((checkOddEvenSaturday(v.Date) && (moment(v.Date).format('ddd') == 'Sat') || (moment(v.Date).format('ddd') == 'Sun'))) {
                                
                            } else {
                                if (v.CheckIn) {
                                    tempTotalLateCount += 1
                                }
                            }
                        }
                        setTotalLateCount(tempTotalLateCount)
                        setTotalDays(tempTotalDay)
                        if (v.CheckIn) {
                            tempTotalCheckIn += 1
                        }
                        setTotalCheckInCount(tempTotalCheckIn)
                        if (v.isMoreThanEightHours) {
                            tempTotalOT += 1
                        }
                        setTotalOTCount(tempTotalOT)
                        let actualWorkingHours
                        if (formattedTotalWorkingDuration) {
                            let totalWorkingHours = (tempTotalCheckIn * 9) + 'hrs 00mins'
                            let workingMinutes = convertToMinutes(formattedTotalWorkingDuration);
                            let totalMinutes = convertToMinutes(totalWorkingHours);
                            let tempWorkingPercentage = (workingMinutes / totalMinutes) * 100;
                            setWorkingPercentage(tempWorkingPercentage.toFixed(2))

                            let tempActualWorkingHours = (formattedTotalWorkingDuration).split(' ')
                            let tempActualHours = parseInt(tempActualWorkingHours[0]) - parseInt(tempTotalCheckIn)
                            actualWorkingHours = `${tempActualHours} ${tempActualWorkingHours[1]} ${tempActualWorkingHours[2]} ${tempActualWorkingHours[3]}`
                            setActualWorkingHour(actualWorkingHours)
                        }
                        if (tempTotalProductiveDuration) {
                            // let totalProductiveHours = (tempTotalCheckIn * 8) + 'hrs 00mins'
                            let productiveMinutes = convertToMinutes(tempTotalProductiveDuration);
                            let totalProductiveMinutes = convertToMinutes(actualWorkingHours);
    
                            let tempProductivePercentage = (productiveMinutes / totalProductiveMinutes) * 100;
                            setProductivePercentage(tempProductivePercentage.toFixed(2))
                        }
                        if (updatedData[k].hasOwnProperty('TotalBreakDuration')) {
                            const breakDurationMins = convertToMinutes(v.TotalBreakDuration)
                            sumTotalBreakDuration += breakDurationMins
                            console.log(convertToMinutes(v.TotalBreakDuration), sumTotalBreakDuration)
                        }
                    })
                    let totalBreakMinutes = convertToMinutes(formattedTotalWorkingDuration);
                    const BreakDurationPercentage = sumTotalBreakDuration / totalBreakMinutes * 100
                    const tempBreakDuration = moment.duration(sumTotalBreakDuration, 'minutes');
                    const formattedBreakDuration = `${Math.floor(tempBreakDuration.asHours())} hrs ${tempBreakDuration.minutes()} mins`;
                    setTotalBreakDuration(formattedBreakDuration)
                    setTotalBreakDurationPercentage(BreakDurationPercentage.toFixed(2))
                }
            })
        }
    }, [fromDate, toDate, userID])

    function convertToMinutes(timeString) {
        let [hours, minutes] = timeString.split('hrs ');
        minutes = minutes.replace('mins', '');
        return parseInt(hours) * 60 + parseInt(minutes);
    }
    const checkOddEvenSaturday = (input) => {
        const date = moment(input, 'YYYY-MM-DD');
        
        if (date.day() === 6) {
            return date.date() % 2 === 0;
        } else {
            return false;
        }
    };
    const renderWorkingDurationTooltip = (props) => (
        <Tooltip id="button-tooltip" {...props}>
            <span class="text-nowrap">{totalWorkingDuration} / {totalCheckInCount * 9} hrs 00 mins</span>
        </Tooltip>
    );
    const renderProductiveDurationTooltip = (props) => (
        <Tooltip id="button-tooltip" {...props}>
            <span class="text-nowrap">{totalProductiveDuration} / {actualWorkingHour}</span>
        </Tooltip>
    );
    const renderBreakDurationTooltip = (props) => (
        <Tooltip id="button-tooltip" {...props}>
            <span class="text-nowrap">{totalBreakDuration} / {totalWorkingDuration}</span>
        </Tooltip>
    );
    let totalProductiveMinutes = 0;
    const handleLocationOnMap = (latLong) => {
        let newLatLong = latLong.split(' ');
        window.open('https://www.google.com/maps/?q=' + newLatLong[0] + ',' + newLatLong[2], '_blank');
    }
    const handleUserFilter = (value) => {
        setUserID(value)
    }
    return (
        <div id="appCapsule">
            <div class="section mt-2">
                <div class="row">
                    <div class="col-12 col-sm-4 d-flex">
                        <div class="form-group boxed">
                            <div class="input-wrapper w-100">
                                <label class="form-label" for="userList">Select User</label>
                                <select class="form-control form-select" onChange={(e) => {handleUserFilter(e.target.value)}} id="userList">
                                    <option value="">Select user name</option>
                                    {userList?.map((v,k) => {
                                        return <option value={v.ID}>{v.UserName}</option>
                                    })}
                                </select>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-sm-8">
                        <div class="row">
                            <div class="col-12 col-sm-12">
                                <div class="form-group boxed d-flex justify-content-between justify-content-sm-end">
                                    <div class="input-wrapper me-1">
                                        <label class="form-label" for="from">From</label>
                                        <input type="date" max={maxDate} onChange={(e) => {setFromDate(e.target.value)}} value={fromDate} class="form-control form-control-sm" id="datepicker" placeholder="" autocomplete="off" />
                                        <i class="clear-input">
                                            <ion-icon name="close-circle"></ion-icon>
                                        </i>
                                    </div>
                                    <div class="input-wrapper">
                                        <label class="form-label" for="to">To</label>
                                        <input type="date" max={maxDate} value={toDate} onChange={(e) => {setToDate(e.target.value)}} class="form-control form-control-sm" id="datepicker" placeholder="" autocomplete="off" />
                                        <i class="clear-input">
                                            <ion-icon name="close-circle"></ion-icon>
                                        </i>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-sm-12">
                        {userReport.length > 0 &&
                            <div class="table-responsive border">
                                <table class="table table-striped">
                                    <thead>
                                        <tr>
                                            <th scope="col" class="fw-bold text-center">
                                                <div class="d-flex justify-content-center flex-column">
                                                    <span class="badge bg-primary m-auto" style={{width: 'fit-content'}}>{totalCheckInCount} / {totalDays}</span>
                                                    <span class="text-nowrap">Date</span>
                                                </div>
                                            </th>
                                            <th scope="col" class="fw-bold text-center text-nowrap">
                                                <div class="d-flex justify-content-center flex-column">
                                                    <span class="badge bg-primary m-auto" style={{width: 'fit-content'}}>{totalLateCount}</span>
                                                    <span class="text-nowrap">Late Mark</span>
                                                </div>
                                            </th>
                                            <th scope="col" class="fw-bold text-center">
                                                <div class="d-flex justify-content-center flex-column">
                                                    <div class="d-flex justify-content-center">
                                                        {/* <span class="badge bg-primary m-auto me-1" style={{width: 'fit-content'}}>{totalWorkingDuration}</span> */}
                                                        
                                                        <OverlayTrigger
                                                            placement="bottom"
                                                            overlay={renderWorkingDurationTooltip}
                                                        >
                                                            <span class={(workingPercentage >= 80) ? "badge bg-success" : "badge bg-danger"}>{(workingPercentage) ? workingPercentage : 0}%</span>
                                                        </OverlayTrigger>
                                                    </div>
                                                    <span class="text-nowrap">Working Duration</span>
                                                </div>
                                            </th>
                                            <th scope="col" class="fw-bold text-center">
                                                <div class="d-flex justify-content-center flex-column">
                                                    <div class="d-flex justify-content-center">
                                                        {/* <span class="badge bg-primary m-auto me-1" style={{width: 'fit-content'}}>{totalProductiveDuration}</span> */}
                                                        <OverlayTrigger
                                                            placement="bottom"
                                                            overlay={renderProductiveDurationTooltip}
                                                        >
                                                            <span class={(productivePercentage >= 80) ? "badge bg-success" : "badge bg-danger"}>{(productivePercentage) ? productivePercentage : 0}%</span>
                                                        </OverlayTrigger>
                                                    </div>
                                                    <span class="text-nowrap">Productive Duration</span>
                                                </div>
                                            </th>
                                            <th scope="col" class="fw-bold text-center">
                                                <div class="d-flex justify-content-center flex-column">
                                                    <div class="d-flex justify-content-center">
                                                        <OverlayTrigger
                                                            placement="bottom"
                                                            overlay={renderBreakDurationTooltip}
                                                        >
                                                            <span class={(totalBreakDurationPercentage <= 10) ? "badge bg-success" : "badge bg-danger"}>{(totalBreakDurationPercentage) ? totalBreakDurationPercentage : 0}%</span>
                                                        </OverlayTrigger>
                                                    </div>
                                                    <span class="text-nowrap">Break Duration</span>
                                                </div>
                                            </th>
                                            <th scope="col" class="fw-bold text-center text-nowrap">
                                                <div class="d-flex justify-content-center flex-column">
                                                    <span class="badge bg-primary m-auto" style={{width: 'fit-content'}}>{totalOTCount}</span>
                                                    <span class="text-nowrap">Overtime</span>
                                                </div>
                                            </th>
                                            <th scope="col" class="fw-bold text-center text-nowrap">
                                                <div class="d-flex justify-content-center flex-column">
                                                    <span class="badge bg-primary m-auto" style={{width: 'fit-content'}}>{uniqueProjectCount}</span>
                                                    <span class="text-nowrap">Projects</span>
                                                </div>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {userReport?.map((v,k) => {
                                            console.log(v)
                                            const renderCheckInIpTooltip = (props) => (
                                                <Tooltip id="button-tooltip" {...props}>
                                                    <span class="text-nowrap">{v.CheckInIp}</span>
                                                </Tooltip>
                                            );
                                            const renderCheckOutIpTooltip = (props) => (
                                                <Tooltip id="button-tooltip" {...props}>
                                                    <span class="text-nowrap">{v.CheckOutIp}</span>
                                                </Tooltip>
                                            );
                                            const popoverClickRootClose = (
                                                <Popover id="popover-trigger-click-root-close">
                                                   <div class="card">
                                                        <div class="card-body p-1">
                                                            <div>
                                                                <p class="p-1 fs-6 mb-0">{v.Note}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Popover>
                                            );
                                            return <tr class={((checkOddEvenSaturday(v.Date) && (moment(v.Date).format('ddd') == 'Sat') || (moment(v.Date).format('ddd') == 'Sun'))) ? 'text-white bg-danger' : ''}>
                                                <th scope="row" class="text-nowrap align-middle">
                                                    <div class="d-flex">
                                                        <OverlayTrigger trigger="click"
                                                            rootClose
                                                            placement="right"
                                                            overlay={popoverClickRootClose}
                                                        >
                                                            <div class="cursor-pointer">
                                                                {((v.Flag === 0 || v.Flag === null || v.Flag === undefined)) && 
                                                                    <ion-icon name="flag-outline" class="me-1 fs-5"></ion-icon>
                                                                }
                                                                {v.Flag === 1 && 
                                                                    <ion-icon name="flag" class="me-1 fs-5 text-warning"></ion-icon>
                                                                }
                                                                {v.Flag === 2 &&
                                                                    <ion-icon name="flag" class="me-1 fs-5 text-danger"></ion-icon>
                                                                }
                                                            </div>
                                                        </OverlayTrigger>
                                                        <Moment format="ddd DD MMM YY">{v.Date}</Moment>
                                                    </div>
                                                </th>
                                                <td class="fw-bold text-center">
                                                    {v.CheckIn ?
                                                        <>
                                                            {(checkOddEvenSaturday(v.Date) && (moment(v.Date).format('ddd') == 'Sat') || (moment(v.Date).format('ddd') == 'Sun')) ?
                                                                '-'
                                                            :
                                                                <>
                                                                    {(v.CheckInStatus && v.IsAfter11AM) &&
                                                                        <span class={"text-success"}><ion-icon name="checkmark-done-outline" class="fs-2"></ion-icon></span>
                                                                    }
                                                                    {(!v.CheckInStatus && v.IsAfter11AM) &&
                                                                        <span class={"text-danger"}><ion-icon name="checkmark-outline" class="fs-2"></ion-icon></span>
                                                                    }
                                                                    {(!v.CheckInStatus && !v.IsAfter11AM) &&
                                                                        <span class={"text-warning"}><ion-icon name="warning-outline" class="fs-2"></ion-icon></span>
                                                                    }
                                                                </>                                                        
                                                            }
                                                        </>
                                                    :
                                                        <>
                                                            {(checkOddEvenSaturday(v.Date) && (moment(v.Date).format('ddd') == 'Sat') || (moment(v.Date).format('ddd') == 'Sun')) ?
                                                                '-'
                                                            :
                                                                <span class="text-danger"><ion-icon name="close-circle-outline" class="fs-2"></ion-icon></span>                                                        
                                                            }
                                                        </>
                                                    }
                                                </td>
                                                <td class="fw-bold text-center">
                                                    {v.WorkingDuration == '-' ?
                                                        <span>
                                                            --
                                                        </span>
                                                    :
                                                        <div class="d-flex flex-column">
                                                            <span class={(v.IsMoreThanNineHours) ? "text-success" : "text-danger"}>
                                                                {v.WorkingDuration}
                                                            </span>
                                                            <span class="text-nowrap fs-10">
                                                                <OverlayTrigger
                                                                    placement="bottom"
                                                                    overlay={renderCheckInIpTooltip}
                                                                >
                                                                    <a href='javscript:void(0)' class="fs-10 text-dark" onClick={() => { handleLocationOnMap(v.CheckInLocation) }}>
                                                                        <Moment format="hh:mm A">{v.CheckIn}</Moment>
                                                                    </a> 
                                                                </OverlayTrigger>
                                                                    - 
                                                                <OverlayTrigger
                                                                    placement="bottom"
                                                                    overlay={renderCheckOutIpTooltip}
                                                                >
                                                                    <a href='javascript:void(0)' class="fs-10 text-dark" onClick={() => { handleLocationOnMap(v.CheckOutLocation) }}>
                                                                        <Moment format="hh:mm A">{v.CheckOut}</Moment>
                                                                    </a>
                                                                </OverlayTrigger>
                                                            </span>
                                                        </div>
                                                    }
                                                </td>
                                                <td class={(v.isMoreThanEightHours) ? "text-center text-success" : "text-center text-danger"}>{v.TotalProductiveDuration} {v.isMoreThanEightHours}</td>
                                                <td class={"text-center text-danger"}>{v.TotalBreakDuration} </td>
                                                <td class="fw-bold text-center">
                                                    {(checkOddEvenSaturday(v.Date) && (moment(v.Date).format('ddd') == 'Sat') || (moment(v.Date).format('ddd') == 'Sun')) ?
                                                        '-'
                                                    :
                                                        <span class="">
                                                            {v.isMoreThanEightHours ?
                                                                <ion-icon name="checkmark-outline" class="fs-2 text-success"></ion-icon>
                                                            :
                                                                <ion-icon name="close-outline" class="fs-2 text-danger"></ion-icon>
                                                            }
                                                        </span>
                                                    }
                                                </td>
                                                <td class="text-center">
                                                    {v?.ProjectArr?.map((v1,k1) => {
                                                        return (<span class="me-1">{v1},</span>)
                                                    })}
                                                </td>
                                            </tr> 
                                        })}
                                    </tbody>
                                </table>
                            </div>
                        }
                        {userReport.length == 0 &&
                            <h1 class="text-danger text-center mt-2">No Records Found</h1>
                        }
                    </div>
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = ({ auth }) => {
    const {
        role
    } = auth
    return {
        role
    }
}
  
const mapDispatchToProps = {
    showPreloader,
    showPinModal
}

export default connect(mapStateToProps, mapDispatchToProps)(UserRecord);