import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import axios from '../../../AxiosConfig';
import moment from 'moment';
import { Tooltip, OverlayTrigger } from 'react-bootstrap';
import { setObjectiveData, showPreloader, setRenderCount } from '../../../features/data/dataSlice';
import { showOkrScoreModal } from '../../../features/modal/OkrScoreSlice';

const OkrBucketView = ({
    objectiveData,
    setObjectiveData,
    renderCount,
    allProjectList,
    setRenderCount,
    userData,
    showPreloader,
    showOkrScoreModal
}) => {
    const [pid, setPid] = useState(null)
    const [taskList, setTaskList] = useState(null)
    const [toDoList, setToDoList] = useState(null)
    const [onGoingList, setOnGoingList] = useState(null)
    const [pausedList, setPausedList] = useState(null)
    const [completedList, setCompletedList] = useState(null)
    const [scoredList, setScoredList] = useState(null)
    const [projectWiseCount, setProjectWiseCount] = useState(0)
    const userOnBreak = localStorage.getItem('break')
    useEffect(() => {
        axios.get('/objectives').then(res => {
            if (res.data.message === 'success') {
                setObjectiveData({objectiveData: res.data.data})
                const filteredTasks = res.data.data.filter(item => ((item.Level === 5)));
                setTaskList(filteredTasks)
                let obj = {}
                filteredTasks.map((v,k) => {
                    if (!obj.hasOwnProperty(v.ProjectID)) {
                        obj[v.ProjectID] = 1
                    } else {
                        obj[v.ProjectID] = obj[v.ProjectID] + 1
                    }
                })
                console.log(obj)
                setProjectWiseCount(obj)
            } else {
                console.log('Something went wrong')
            }
        }).catch(err => {
            console.error(err);
        });
    }, [renderCount])
    useEffect(() => {
        if (!pid) {
            setPid('all')
        }
    }, [pid])
    const userTooltip = (
        <Tooltip>
            Hussain, Amruta, Nikhil, Saurabh, Rutuja
        </Tooltip>
    );
    const handleProjectFilter = (ProjectID) => {
        setPid(ProjectID)
        if (ProjectID === 'all') {
            const filteredTasks = objectiveData.filter(item => ((item.Level === 5)));
            setTaskList(filteredTasks)   
        } else {
            const filteredTasks = objectiveData.filter(item => ((item.ProjectID === ProjectID)));
            setTaskList(filteredTasks)
        }
    }
    useEffect(() => {
        let toDoArr = []
        let onGoingArr = []
        let pausedArr = []
        let completedArr = []
        let scoredArr = []
        if (taskList) {
            taskList.map((v,k) => {
                if (v.TaskStatus === 'toDo') {
                    toDoArr.push(v)
                } else if (v.TaskStatus === 'onGoing') {
                    onGoingArr.push(v)
                } else if (v.TaskStatus === 'paused') {
                    pausedArr.push(v)
                } else if (v.TaskStatus === 'completed' && (v.Score == 0 || v.Score == null || v.Score == undefined)) {
                    completedArr.push(v)
                } else if (v.TaskStatus === 'completed' && v.Score > 0) {
                    scoredArr.push(v)
                }
            })
            setToDoList(toDoArr)
            setOnGoingList(onGoingArr)
            setPausedList(pausedArr)
            setCompletedList(completedArr)
            setScoredList(scoredArr)
        }
    }, [taskList, renderCount])
    const handleTaskAssign = (data) => {
        const currentDateTime = moment().format('YYYY-MM-DD HH:mm:ss')
        const playTaskData = {
            "TaskID": data.TaskID,
            "UserID": userData.ID,
            "DateTime": currentDateTime
        }
        axios.post('/Entries/startTask', playTaskData).then(res => {
            if (res.data.message === 'Success') {
                setRenderCount({renderCount: parseInt(renderCount) + 1})
            } else {
            }
        })
    }
    const updateTaskStatus = (taskStatus, statusData) => {
        if (userData.ID === statusData.UserID) {
            showPreloader({preloaderVisible: true})
            const moment = require('moment');
            const date = moment();
            const formattedDate = date.format('YYYY-MM-DD');
            const formattedDateTime = date.format('YYYY-MM-DD HH:mm:ss');
            const taskData = {
                'UserID': statusData.UserID,
                'Status': taskStatus,
                'DateTime': formattedDateTime,
                'Date': formattedDate,
                'Duration': null,
                'ProjectID': statusData.ProjectID,
                'TaskID': statusData.TaskID,
                'Description': '',
                'TaskName': statusData.Title,
                'UserName': statusData.UserName,
                'ProjectName': statusData.ProjectName,
                'Break': (userOnBreak == true || userOnBreak == 'true') ? true : false 
            }
            axios.put('/entries', taskData).then(res => {
                if (res.data.message === 'Success') {
                    setRenderCount({renderCount: parseInt(renderCount) + 1})
                }
            })
        } else {
            alert('PERMISSION RESTRICTED')
        }
    }
    const setTaskScore = (data) => {
        showOkrScoreModal({okrScoreModalVisible: true, okrScoreData: data})
    }
    return (
        <div id="appCapsule" class="pt-2">
            <div class="section">
                <div class="row mb-0 overflow-auto ps-1">
                    <div class="btn-group p-0" role="group">
                        <div onClick={() => {handleProjectFilter('all')}}>
                            <input type="radio" class="btn-check" checked={(pid === 'all') ? true : false} name="projectList" id={"projectNameAll"} />
                            <label class="btn btn-outline-primary border-primary text-nowrap pe-1 ps-1 me-2 ms-2" style={{height: '35px', borderRadius: '5px'}} for={"projectNameAll"}>All</label>
                        </div>
                        {allProjectList?.map((v,k) => {
                            return <>
                                <div onClick={() => {handleProjectFilter(v.ID)}}  style={{position: 'relative'}}>
                                    <input type="radio" class="btn-check" name="projectList" id={"projectName" + k} checked={(pid === v.ID) ? true : false} />
                                    <label class="btn btn-outline-primary border-primary text-nowrap pe-1 ps-1 me-2 ms-2" style={{height: '35px', borderRadius: '5px'}} for={"projectName" + k}>{v.Name}</label>
                                    <span class="badge bg-danger" style={{height: '18px', top: 0, right: 15, position: 'absolute'}}>{projectWiseCount[v.ID]}</span>
                                </div>
                            </>
                        })}
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 col-md-3 ps-1 pe-1 border-end" style={{background: '#b5dcf7'}}>
                        <h3 class="d-flex align-items-center justify-content-center pt-2">
                            TO DO
                            <span class="badge bg-secondary ms-1" style={{height: '18px'}}>
                                {toDoList?.length}
                            </span>
                        </h3>
                        {toDoList?.map((v,k) => {
                            return <>
                                {v.TaskStatus === 'toDo' &&
                                    <div class={`card mb-2 me-sm-0 text-dark cursor-pointer`}>
                                        <div class="card-body p-1">
                                            <div class="d-flex flex-column">
                                                <span class="fs-6 pb-1">
                                                    <span class="fw-bold d-flex justify-content-between">
                                                        <div class="d-flex align-items-center">
                                                            <span class="bg-primary d-flex justify-content-center align-items-center me-1" onClick={() => {handleTaskAssign(v)}} style={{height: '35px', width: '35px', minHeight: '35px', minWidth: '35px', borderRadius: '100px'}}>
                                                                <ion-icon name="play" class="fs-5"></ion-icon>
                                                            </span>
                                                        </div>
                                                        <div class="d-flex justify-content-between align-items-center">
                                                            <div class="badge bg-primary me-1">
                                                                {(v.UserName) ? v.UserName : 'Not Assigned'}
                                                            </div>
                                                            <div>
                                                                <OverlayTrigger
                                                                    placement="top"
                                                                    delay={{ show: 250, hide: 400 }}
                                                                    overlay={userTooltip}
                                                                >
                                                                    <span class="badge bg-secondary">{v.ProjectName}</span>
                                                                </OverlayTrigger>
                                                            </div>
                                                        </div>
                                                    </span>
                                                </span>
                                                <ul class={"ps-0 mt-1 mb-1"}> 
                                                    <li class={"cursor-pointer d-flex justify-content-between align-items-center"}>
                                                        <span class="d-flex align-items-center justify-content-center">
                                                            <p>{v.Title}</p>
                                                        </span>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                }
                            </>
                        })}
                        {toDoList?.length == 0 &&
                            <h3 class="text-danger mb-2">No Task Found</h3>
                        }
                    </div>
                    <div class="col-12 col-md-3 ps-1 pe-1 border-end" style={{background: '#ededed'}}>
                        <h3 class="d-flex align-items-center justify-content-center pt-2">STARTED <span class="badge bg-secondary ms-1" style={{height: '18px'}}>
                                {parseInt(onGoingList?.length) + parseInt(pausedList?.length)}
                            </span></h3>
                        <h6 class="d-flex align-items-center justify-content-between">
                            <span>In Progress</span>
                            {parseInt(onGoingList?.length)}
                        </h6>
                        {onGoingList?.map((v,k) => {
                            return <>
                                {v.TaskStatus === 'onGoing' &&
                                    <div class={`card mb-2 me-sm-0 text-dark cursor-pointer`}>
                                        <div class="card-body p-1">
                                            <div class="d-flex flex-column">
                                                <span class="fs-6 pb-1">
                                                    <span class="fw-bold d-flex justify-content-between">
                                                        <div class="d-flex align-items-center">
                                                            <span class="bg-secondary d-flex justify-content-center align-items-center me-1" onClick={() => {updateTaskStatus('paused', v)}} style={{height: '35px', width: '35px', minHeight: '35px', minWidth: '35px', borderRadius: '100px'}}>
                                                                <ion-icon name="pause" class="fs-5"></ion-icon>
                                                            </span>
                                                        </div>
                                                        <div class="d-flex justify-content-between align-items-center">
                                                            <div class={(v.UserName) ? "badge bg-primary me-1" : "badge bg-danger me-1"}>
                                                                {(v.UserName) ? v.UserName : 'Not Assigned'}
                                                            </div>
                                                            <div>
                                                                <OverlayTrigger
                                                                    placement="top"
                                                                    delay={{ show: 250, hide: 400 }}
                                                                    overlay={userTooltip}
                                                                >
                                                                    <span class="badge bg-secondary">{v.ProjectName}</span>
                                                                </OverlayTrigger>
                                                            </div>
                                                        </div>
                                                    </span>
                                                </span>
                                                <ul class={"ps-0 mt-1 mb-1"}> 
                                                    <li class={"cursor-pointer d-flex justify-content-between align-items-center"}>
                                                        <span class="d-flex align-items-center justify-content-center">
                                                            <p class="mb-1">{v.Title}</p>
                                                        </span>
                                                    </li>
                                                </ul>
                                                <div class="d-flex justify-content-center">
                                                    <span class="badge border-success d-flex justify-content-center align-items-center me-1 text-dark" onClick={() => {updateTaskStatus('completed', v)}}>
                                                        <ion-icon name="checkmark" class="fs-6"></ion-icon>&nbsp;
                                                        Complete Task&nbsp;
                                                        <ion-icon name="chevron-forward"></ion-icon>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                }
                            </>
                        })}
                        {onGoingList?.length == 0 &&
                            <h3 class="text-danger mb-2">No Task Found</h3>
                        }
                        <h6 class="d-flex align-items-center justify-content-between">
                            <span>Paused</span>
                            {parseInt(pausedList?.length)}
                        </h6>
                        {pausedList?.map((v,k) => {
                            return <>
                                {v.TaskStatus === 'paused' &&
                                    <div class={`card mb-2 me-sm-0 text-dark cursor-pointer`}>
                                        <div class="card-body p-1">
                                            <div class="d-flex flex-column">
                                                <span class="fs-6 pb-1">
                                                    <span class="fw-bold d-flex justify-content-between">
                                                        <div class="d-flex align-items-center">
                                                            <span class="bg-warning d-flex justify-content-center align-items-center me-1" onClick={() => {updateTaskStatus('onGoing', v)}} style={{height: '35px', width: '35px', minHeight: '35px', minWidth: '35px', borderRadius: '100px'}}>
                                                                <ion-icon name="play" class="fs-5"></ion-icon>
                                                            </span>
                                                        </div>
                                                        <div class="d-flex justify-content-between align-items-center">
                                                            <div class="badge bg-primary me-1">
                                                                {(v.UserName) ? v.UserName : 'Not Assigned'}
                                                            </div>
                                                            <div>
                                                                <OverlayTrigger
                                                                    placement="top"
                                                                    delay={{ show: 250, hide: 400 }}
                                                                    overlay={userTooltip}
                                                                >
                                                                    <span class="badge bg-secondary">{v.ProjectName}</span>
                                                                </OverlayTrigger>
                                                            </div>
                                                        </div>
                                                    </span>
                                                </span>
                                                <ul class={"ps-0 mt-1 mb-1"}> 
                                                    <li class={"cursor-pointer d-flex justify-content-between align-items-center"}>
                                                        <span class="d-flex align-items-center justify-content-center">
                                                            <p>{v.Title}</p>
                                                        </span>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                }
                            </>
                        })}
                        {pausedList?.length == 0 &&
                            <h3 class="text-danger mb-2">No Task Found</h3>
                        }
                    </div>
                    <div class="col-12 col-md-3 ps-1 pe-1 border-end" style={{background: '#fcdcae'}}>
                        <h3 class="d-flex align-items-center justify-content-center pt-2">COMPLETED
                            <span class="badge bg-secondary ms-1" style={{height: '18px'}}>
                                {completedList?.length}
                            </span>
                        </h3>
                        {completedList?.map((v,k) => {
                            return <>
                                {v.TaskStatus === 'completed' &&
                                    <div class={`card mb-2 me-sm-0 text-dark cursor-pointer`} onClick={() => {setTaskScore(v)}}>
                                        <div class="card-body p-1">
                                            <div class="d-flex flex-column">
                                                <span class="fs-6 pb-1">
                                                    <span class="fw-bold d-flex justify-content-between">
                                                        <div class="d-flex align-items-center" style={{textDecoration: 'line-through'}}>
                                                            <span class="bg-warning d-flex justify-content-center align-items-center me-1" style={{height: '35px', width: '35px', minHeight: '35px', minWidth: '35px', borderRadius: '100px'}}>
                                                                <ion-icon name="checkmark-done" class="fs-5"></ion-icon>
                                                            </span>
                                                        </div>
                                                        <div class="d-flex justify-content-between align-items-center">
                                                            <div class="badge bg-primary me-1">
                                                                {(v.UserName) ? v.UserName : 'Not Assigned'}
                                                            </div>
                                                            <div>
                                                                <OverlayTrigger
                                                                    placement="top"
                                                                    delay={{ show: 250, hide: 400 }}
                                                                    overlay={userTooltip}
                                                                >
                                                                    <span class="badge bg-secondary">{v.ProjectName}</span>
                                                                </OverlayTrigger>
                                                            </div>
                                                        </div>
                                                    </span>
                                                </span>
                                                <ul class={"ps-0 mt-1 mb-1"}> 
                                                    <li class={"cursor-pointer d-flex justify-content-between align-items-center"}>
                                                        <span class="d-flex align-items-center justify-content-center">
                                                            <p style={{textDecoration: 'line-through'}}>{v.Title}</p>
                                                        </span>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                }
                            </>
                        })}
                        {completedList?.length == 0 &&
                            <h3 class="text-danger mb-2">No Task Found</h3>
                        }
                    </div>
                    <div class="col-12 col-md-3 ps-1 pe-1" style={{background: '#a7fabc'}}>
                        <h3 class="d-flex align-items-center justify-content-center pt-2">SCORED
                            <span class="badge bg-secondary ms-1" style={{height: '18px'}}>
                                {scoredList?.length}
                            </span>
                        </h3>
                        {scoredList?.map((v,k) => {
                            return <>
                                <div class={`card mb-2 me-sm-0 text-dark cursor-pointer`}>
                                    <div class="card-body p-1">
                                        <div class="d-flex flex-column">
                                            <span class="fs-6 pb-1">
                                                <span class="fw-bold d-flex justify-content-between">
                                                    <div class="d-flex align-items-center">
                                                        <span class="bg-success d-flex justify-content-center align-items-center me-1" style={{height: '35px', width: '35px', minHeight: '35px', minWidth: '35px', borderRadius: '100px'}}>
                                                            {/* <ion-icon name="star" class="fs-5"></ion-icon> */}
                                                            {v.Score}
                                                        </span>
                                                    </div>
                                                    <div class="d-flex justify-content-between align-items-center">
                                                        <div class="badge bg-primary me-1">
                                                            {(v.UserName) ? v.UserName : 'Not Assigned'}
                                                        </div>
                                                        <div>
                                                            <OverlayTrigger
                                                                placement="top"
                                                                delay={{ show: 250, hide: 400 }}
                                                                overlay={userTooltip}
                                                            >
                                                                <span class="badge bg-secondary">{v.ProjectName}</span>
                                                            </OverlayTrigger>
                                                        </div>
                                                    </div>
                                                </span>
                                            </span>
                                            <ul class={"ps-0 mt-1 mb-1"}> 
                                                <li class={"cursor-pointer d-flex justify-content-between align-items-center"}>
                                                    <span class="d-flex align-items-center justify-content-center">
                                                        <p style={{textDecoration: 'line-through'}}>{v.Title}</p>
                                                    </span>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </>
                        })}
                        {scoredList?.length == 0 &&
                            <h3 class="text-danger mb-2">No Task Found</h3>
                        }
                    </div>
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = ({ data, auth }) => {
    const {
        objectiveData,
        renderCount,
        allProjectList
    } = data
    const {
        userData
    } = auth
    return {
        objectiveData,
        renderCount,
        allProjectList,
        userData
    }
}
  
const mapDispatchToProps = {
    setObjectiveData,
    setRenderCount,
    showPreloader,
    showOkrScoreModal
}

export default connect(mapStateToProps, mapDispatchToProps)(OkrBucketView);