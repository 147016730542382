import React, { useState } from 'react'
import { OverlayTrigger, Tooltip, Dropdown } from 'react-bootstrap'
import { EmojiSmile } from 'react-bootstrap-icons'
import './ReactionBar.css'

const ReactionBar = () => {
  const [showReactions, setShowReactions] = useState(false)
  const [selectedReaction, setSelectedReaction] = useState(null)
  const reactions = ['👍', '❤️', '😂', '😲', '😢', '🙏']

  // Toggle reaction bar visibility
  const toggleReactions = () => {
    setShowReactions(!showReactions)
  }

  // Handle emoji selection and toggle the same emoji off if clicked again
  const selectReaction = reaction => {
    if (selectedReaction === reaction) {
      setSelectedReaction(null) // Remove the emoji if it's the same
    } else {
      setSelectedReaction(reaction) // Set new emoji
    }
    setShowReactions(false) // Hide the reaction bar after selection
  }

  return (
    <div className="message-container">
      {/* Message */}
      {/* <div className="message">
        <p>Wo call karta hu fir</p>
        <span className="message-time">7:27 PM</span>
      </div> */}

      {/* Selected emoji displayed below the message */}
      {selectedReaction && (
        <div className="selected-reaction">
          <span>{selectedReaction}</span>
        </div>
      )}

      {/* Reaction bar, only visible when showReactions is true */}
      {showReactions && (
        <div className="reaction-bar slide-right-animation">
          {reactions.map((reaction, index) => (
            <OverlayTrigger
              key={index}
              placement="top"
              overlay={<Tooltip>{reaction}</Tooltip>}
            >
              <span
                className="reaction"
                onClick={() => selectReaction(reaction)}
              >
                {reaction}
              </span>
            </OverlayTrigger>
          ))}
          <Dropdown>
            <Dropdown.Toggle
              variant="link"
              id="reaction-more"
              className="more-btn"
            >
              <EmojiSmile />
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item>More Reactions...</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      )}

      {/* Click to toggle reactions */}
      <div className="click-trigger" onClick={toggleReactions}>
        <EmojiSmile className="reaction-icon" />
      </div>
    </div>
  )
}

export default ReactionBar
