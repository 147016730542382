import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    okrScoreModalVisible: false,
    okrScoreData: null
}

export const OkrScoreSlice = createSlice({
  name: 'okrScore',
  initialState,
  reducers: {
    showOkrScoreModal: (state, action) => {
      const {
        okrScoreModalVisible,
        okrScoreData
      } = action.payload
      state.okrScoreModalVisible = okrScoreModalVisible
      state.okrScoreData = okrScoreData
    },
    hideOkrScoreModal: (state, action) => {
        state.okrScoreModalVisible = false
      }
  }
})

export const { showOkrScoreModal, hideOkrScoreModal } = OkrScoreSlice.actions;


export default OkrScoreSlice.reducer
