import React from 'react'
import Offcanvas from 'react-bootstrap/Offcanvas'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { hideRightSidebar } from '../../../features/sidebar/RightSidebarSlice'
import { showCreateOkrModal } from '../../../features/modal/CreateOkrSlice'

const RightSidebar = ({
    rightSidebarVisible,
    hideRightSidebar,
    showCreateOkrModal
}) => {
  const handleCreateOkr = () => {
    hideRightSidebar()
    showCreateOkrModal({createOkrModalVisible: true})
  }
  return (
    <Offcanvas show={rightSidebarVisible} placement={'end'} backdrop={true} style={{width: '200px'}} onHide={() => {hideRightSidebar()}}>
      <Offcanvas.Body class="pt-0 pb-0 ps-2 pe-2">
        <ul class="listview flush transparent no-line image-listview">
            <li class="border-bottom d-flex justify-content-center">
            <a
              href="#!"
              class="item p-0 pb-1 pt-1"
              style={{minHeight: 'auto'}}
              onClick={() => {handleCreateOkr()}}
            >
              <div class="d-flex align-items-center">
                <ion-icon name="add-outline" class="fs-4 me-2"></ion-icon>
              </div>
              <div class="in">Create OKR</div>
            </a>
          </li>
          <li>
            <Link
              to="#!"
              class="item p-0 pb-1 pt-1"
              style={{minHeight: 'auto'}}
            >
              <div class="d-flex align-items-center">
                <ion-icon name="pricetag-outline" class="fs-4 me-2" style={{rotate: '45deg'}}></ion-icon>
              </div>
              <div class="in">Quarterly</div>
            </Link>
          </li>
          <li>
            <Link
              to="#!"
              class="item p-0 pb-1 pt-1"
              style={{minHeight: 'auto'}}
            >
              <div class="d-flex align-items-center">
                <ion-icon name="pricetag-outline" class="fs-4 me-2" style={{rotate: '45deg'}}></ion-icon>
              </div>
              <div class="in">Sprint</div>
            </Link>
          </li>
          <li>
            <Link
              to="#!"
              class="item p-0 pb-1 pt-1"
              style={{minHeight: 'auto'}}
            >
              <div class="d-flex align-items-center">
                <ion-icon name="pricetag-outline" class="fs-4 me-2" style={{rotate: '45deg'}}></ion-icon>
              </div>
              <div class="in">Yearly</div>
            </Link>
          </li>
        </ul>
      </Offcanvas.Body>
    </Offcanvas>
  )
}

const mapStateToProps = ({ rightSidebar }) => {
    const {
        rightSidebarVisible
    } = rightSidebar
  return {
    rightSidebarVisible
  }
}

const mapDispatchToProps = {
    hideRightSidebar,
    showCreateOkrModal
}

export default connect(mapStateToProps, mapDispatchToProps)(RightSidebar)
