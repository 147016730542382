import React, {useEffect, useState} from 'react'
import { connect } from 'react-redux'
import axios from '../../AxiosConfig'
import moment from 'moment'
import Moment from 'react-moment'
import 'moment-timezone'
import Accordion from 'react-bootstrap/Accordion'
import ProgressBar from 'react-bootstrap/ProgressBar';
import { OverlayTrigger, Tooltip, Popover, Button } from 'react-bootstrap'
import { useCalculateTaskTime } from '../../Hooks/useCalculateTaskTime'
import { setRenderCount, setTasksList, setUsersListData, setAttendanceListData, setRenderProgressBar, showPreloader, actionDisabledAllButtons } from '../../features/data/dataSlice'
import TaskListView from './smallComponents/TaskListView'
import jwt_decode from 'jwt-decode'
import { showAddTaskModal } from '../../features/modal/AddTaskSlice'
import ProjectView from './smallComponents/ProjectView'
import OkrBucketView from './smallComponents/OkrBucketView'
import { NutFill } from 'react-bootstrap-icons'

const Dashboard = ({
    userData,
    authToken,
    renderCount,
    setRenderCount,
    setAttendanceListData,
    showAddTaskModal,
    attendanceListData,
    showPreloader,
    disabledAllButtons,
    actionDisabledAllButtons,
    allUserList,
    role,
    allProjectList
}) => {
    const [selectedDate, setSelectedDate] = useState(null)
    const [selectedDay, setSelectedDay] = useState('')
    const [userList, setUserList] = useState([])
    const [userCurrentLocation, setUserCurrentLocation] = useState('')
    const [latLong, setLatLong] = useState(null)
    const [disableSubmitButton, setDisableSubmitButton] = useState(false)
    const [userID, setUserID] = useState('')
    const [userLoginStatus, setUserLoginStatus] = useState(false)
    const [totalUsersCount, setTotalUsersCount] = useState(null)
    const [totalAttendance, setTotalAttendance] = useState(0)
    const [allUsersTotalDuration, setAllUsersTotalDuration] = useState(0)
    const [allUsersTotalMinutes, setAllUsersTotalMinutes] = useState(0)
    const [totalProdutiveTime, setTotalProdutiveTime] = useState(0)
    const [productivityPercentage, setProductivityPercentage] = useState(0)
    const [totalAbsent, setTotalAbsent] = useState(0)
    const [disabledOnBreakButton, setDisabledOnBreakButton] = useState(false)
    const [finalUserList, setFinalUserList] = useState(null)
    const [timeOutBar, setTimeOutBar]=  useState(null)
    const [view, setView] = useState('user')
    const [finalUserListOngoing, setFinalUserListOngoing] = useState(null)
    const [finalUserListIdle, setFinalUserListIdle] = useState(null)
    const [finalUserListBreak , setFinalUserListBreak] = useState(null)
    const [absentUserList, setAbsentUserList] = useState(null)
    const [finalUserListLoggedout, setFinalUserListLoggedout] = useState(null)
    const [showAbsentUsers, setShowAbsentUsers] = useState(false)
    const [note, setNote] = useState('')
    const [flag, setFlag] = useState(0)
    const [attendanceID, setAttendanceID] = useState(null)
    const [pid, setPid] = useState(null)
    const [projectID, setProjectID] = useState(null)

    useEffect(() => {
        if (!pid) {
            setPid('all')
        }
    }, [pid])

    // State to track if the browser tab is active
    const [isTabActive, setIsTabActive] = useState(document.visibilityState === 'visible');

    const today = new Date()
    const formattedTodaysDate = today.toISOString().split('T')[0]
    const filterId = null;
    const { TaskProgressCalculation } = useCalculateTaskTime();

    const tokenData = jwt_decode(authToken);

    useEffect(() => {
        setUserID(tokenData.ID)
        setSelectedDate(today.toISOString().split('T')[0])
    }, [authToken])

    useEffect(() => {
        axios.get('/users').then(res => {
            if (res.data.message === 'Success') {
                setUserList(res.data.data)
                setTotalUsersCount(res.data.data.length)
            }
        })
    }, [])
    const todaysDate = moment().format('YYYY-MM-DD');
    function processProgressBar(arg1) {
        if (userList.length > 0) {
            axios.get('/attendanceTest/' + ((selectedDate) ? selectedDate : formattedTodaysDate)).then(res1 => {
                if (res1.data.message === 'Success') {
                    let tempUserList = null
                    setAttendanceListData({attendanceListData: res1.data.data})
                    const attendanceList = res1.data.data
                    let attendanceCount = 0
                    let absentCount = 0
                    let totalDuration = 0

                    attendanceList.map((v,k) => {
                        if (v.CheckIn && !v.CheckOut) {
                            attendanceCount = attendanceCount + 1
                        } else if (!v.CheckIn) {
                            absentCount = absentCount + 1
                        }
                        const checkInTime = moment(v.CheckIn)
                        const currentTime = (v.CheckOut) ? moment(v.CheckOut) : moment()
                        const totalDurationInMinutes = moment.duration(currentTime.diff(checkInTime)).asMinutes()
                        totalDuration = totalDuration + Math.ceil(totalDurationInMinutes)
                        setTotalAttendance(attendanceCount)
                        setTotalAbsent(absentCount)
                        setAllUsersTotalDuration(totalDuration)
                    })
                    let userListTemp = JSON.parse(JSON.stringify(userList))
                    userListTemp.map((v,k) => {
                        let newObj = JSON.parse(JSON.stringify(v))
                        newObj.UserTaskList = []
                        newObj.UserAttendanceData = []
                        attendanceList.map((v2,k2) => {
                            if (v.ID == v2.ID) {
                                newObj.UserAttendanceData.push(v2)
                                const checkInTime = moment(v2.CheckIn)
                                const currentTime = (v2.CheckOut) ? moment(v2.CheckOut) : moment()
                                const userDuration = moment.duration(currentTime.diff(checkInTime)).asMinutes()
                                newObj.TotalDuration = Math.round(userDuration)
                            }   
                        })
                        userListTemp[k] = newObj
                    })
                    let reorderedData = sortUsers(userListTemp)
                    let newDataInput = JSON.parse(JSON.stringify(reorderedData)) 
                    newDataInput.map((v,k) => {
                        let tempTaskEntries = v.UserAttendanceData[0].TaskEntries
                        let ongoingTaskRearrange = []
                        let pausedTaskRearrange = []
                        let completedTaskRearrange = []
                        {tempTaskEntries.map((v1,k1) => {
                            if (v1.Status == 'onGoing') {
                                ongoingTaskRearrange.push(v1)
                            }
                            if (v1.Status == 'paused' || v1.Status == 'toDo') {
                                pausedTaskRearrange.push(v1)
                            }
                            if (v1.Status == 'completed') {
                                completedTaskRearrange.push(v1)
                            }
                        })}
                        const ongoingPaused = ongoingTaskRearrange.concat(pausedTaskRearrange); 
                        const ongoingPausedCompleted = ongoingPaused.concat(completedTaskRearrange); 
                        newDataInput[k].UserAttendanceData[0].TaskEntries = ongoingPausedCompleted
                        if (newDataInput[k].ID == userData.ID) {
                            if (newDataInput[k].UserAttendanceData[0].TaskEntries.length > 0 && newDataInput[k].UserAttendanceData[0].TaskEntries[0].ID == 901) {
                                newDataInput[k].OnBreak = true
                            } else {
                                newDataInput[k].OnBreak = false
                            }
                        }
                    })
                    tempUserList = newDataInput
                    const today = new Date();
                    const yyyy = today.getFullYear();
                    const mm = String(today.getMonth() + 1).padStart(2, '0'); // Months are zero-based
                    const dd = String(today.getDate()).padStart(2, '0');
                    const checkInDate = moment("2024-08-19T05:36:09.620Z").format('YYYY-MM-DD')
                    if (tempUserList.length > 0) {
                        let newUserList = tempUserList.map((v,k) => {
                            if (v.ID == userData.ID) {
                                if (v.UserAttendanceData[0].CheckIn && (v.UserAttendanceData[0].CheckOut == '' || v.UserAttendanceData[0].CheckOut == null || v.UserAttendanceData[0].CheckOut == undefined)) {
                                    actionDisabledAllButtons({disabledAllButtons: false})
                                } else if ((v.UserAttendanceData[0].CheckIn == '' || v.UserAttendanceData[0].CheckIn == null ||v.UserAttendanceData[0].CheckIn == undefined) && (v.UserAttendanceData[0].CheckOut == '' || v.UserAttendanceData[0].CheckOut == null || v.UserAttendanceData[0].CheckOut == undefined)) {
                                    actionDisabledAllButtons({disabledAllButtons: true})
                                } else if (v.UserAttendanceData[0].CheckIn && v.UserAttendanceData[0].CheckOut) {
                                    actionDisabledAllButtons({disabledAllButtons: true})
                                }
                            }
                            let updatedData = JSON.parse(JSON.stringify(v))
                            const revsersedArr = JSON.parse(JSON.stringify(updatedData.UserAttendanceData[0].Timeline)).reverse();
                            if (updatedData.UserAttendanceData[0].CheckOut) {
                                updatedData.ProgressBar = calculateTasksPercentage(revsersedArr, updatedData.UserAttendanceData[0].CheckIn, `(${checkInDate})T${moment(updatedData.UserAttendanceData[0].CheckOut).format('HH:mm')}:00.000Z`)
                                const isAfter7PM = moment(`${yyyy}-${mm}-${dd}T${moment(updatedData.UserAttendanceData[0].CheckOut).format('HH:mm')}:00.000Z`).isBefore(moment(`${yyyy}-${mm}-${dd}T${moment(updatedData.UserAttendanceData[0].CheckOut).format('HH:mm')}:00.000Z`).set({ hour: 19, minute: 0, second: 0 }));
                                if (!isAfter7PM) {
                                    updatedData.ProgressBar.push(createObject(revsersedArr, updatedData.UserAttendanceData[0].CheckIn, 0, `${yyyy}-${mm}-${dd}T${moment(updatedData.UserAttendanceData[0].CheckOut).format('HH:mm')}:00.000Z`, `${yyyy}-${mm}-${dd}T19:00:00.000Z`, 'EARLY', 'white'))
                                }
                            } else {
                                updatedData.ProgressBar = calculateTasksPercentage(revsersedArr, updatedData.UserAttendanceData[0].CheckIn)
                                if (revsersedArr.length == 0) {
                                    const options = { hour: 'numeric', minute: 'numeric', second: 'numeric', hour12: false };
                                    let localTime  = new Date().toLocaleTimeString('en-US', options)
                                    updatedData.ProgressBar.push(createObject(revsersedArr, updatedData.UserAttendanceData[0].CheckIn, 0, `${yyyy}-${mm}-${dd}T${moment(updatedData.UserAttendanceData[0].CheckIn).format('HH:mm')}:00.000Z`, yyyy + '-' + mm + '-' + dd + 'T' + localTime + '.000Z', 'IDLE', 'danger', 'striped'))
                                }
                            }
                            updatedData.ProgressBar = calculateTimeDifferences(updatedData.ProgressBar)
                            let totalPorductiveTime = 0
                            let totalBreakTime = 0
                            setTotalProdutiveTime(0)
                            updatedData.ProgressBar.map((v1,k1) => {
                                if (v1.Label !== 'LATE' &&  v1.Label !== 'EARLY' && v1.Label !== 'IDLE' && v1.Label !== 'BREAK') {
                                    if (v1.hasOwnProperty('DurationInMinutes')) {
                                        tempAllUsersTotalMinutes = parseInt(tempAllUsersTotalMinutes) + parseInt(v1.DurationInMinutes)
                                        totalPorductiveTime = parseInt(totalPorductiveTime) + parseInt(v1.DurationInMinutes)
                                    }
                                }
                                if (v1.Label === 'BREAK') {
                                    if (v1.hasOwnProperty('DurationInMinutes')) {
                                        tempAllUsersTotalBreakMinutes = parseInt(tempAllUsersTotalMinutes) + parseInt(v1.DurationInMinutes)
                                        totalBreakTime = parseInt(totalBreakTime) + parseInt(v1.DurationInMinutes)
                                    }
                                }
                                if (updatedData.ID == userData.ID) {
                                    if (updatedData.ProgressBar[updatedData.ProgressBar.length - 1].Label === 'BREAK') {
                                        setDisabledOnBreakButton(true)
                                        localStorage.setItem('break', true)
                                    } else {
                                        setDisabledOnBreakButton(false)
                                        localStorage.setItem('break', false)
                                    }
                                }
                                updatedData.ProductiveTime = totalPorductiveTime 
                                updatedData.BreakTime = totalBreakTime
                            })
                            setTotalProdutiveTime(tempAllUsersTotalMinutes)
                            return updatedData
                        })
                        tempUserList = newUserList
                    }
                    let finalUserOngoing = []
                    let finalUserIdle = []
                    let finalUserBreak = []
                    let absentUser = []
                    let loggedOutUserList = []
                    console.log(projectID)
                    if (projectID === 'all' || projectID === null || projectID === undefined) {
                        tempUserList.map((v,k) => {
                            if (v.UserAttendanceData[0].hasOwnProperty('CheckIn')) {
                                if ((v.UserAttendanceData[0]?.Timeline[0]?.Status == "toDo")) {
                                    if (v.UserAttendanceData[0]?.Timeline[1]?.Status == 'onGoing') {
                                        finalUserOngoing.push(v)
                                    }
                                } else if ((v.ProgressBar[v.ProgressBar.length - 1].Color == 'primary' && v.ProgressBar[v.ProgressBar.length - 1].Label != 'BREAK')) {
                                    finalUserOngoing.push(v)
                                }
                                if ((v.UserAttendanceData[0]?.Timeline[0]?.Status == "toDo")) {
                                    if (v.UserAttendanceData[0]?.Timeline[1]?.Status != 'onGoing') {
                                        finalUserIdle.push(v)
                                    }
                                } else if ((v.ProgressBar[v.ProgressBar.length - 1].Label == 'IDLE') && (v.UserAttendanceData[0].CheckOut == '' || v.UserAttendanceData[0].CheckOut == null ||v.UserAttendanceData[0].CheckOut == undefined)) {
                                    finalUserIdle.push(v)
                                }
                                if (v.ProgressBar[v.ProgressBar.length - 1].Label == 'BREAK') {
                                    finalUserBreak.push(v)
                                }
                                if (v.UserAttendanceData[0].CheckOut) {
                                    loggedOutUserList.push(v)
                                }
                            } else {
                                absentUser.push(v)
                            }
                        })
                    } else {
                        finalUserOngoing = tempUserList?.filter(itm => (projectID == itm?.UserAttendanceData[0]?.TaskEntries[0]?.ProjectID) && itm.UserAttendanceData[0].hasOwnProperty('CheckIn'))
                    }
                    setFinalUserListOngoing(finalUserOngoing)
                    setFinalUserListIdle(finalUserIdle)
                    setFinalUserListBreak(finalUserBreak)
                    setAbsentUserList(absentUser)
                    setFinalUserListLoggedout(loggedOutUserList)
                    setFinalUserList(tempUserList)
                    renderAgain(arg1)
                    // setRenderProgressBar({renderProgressBar: renderProgressBar + 1})
                    // setRenderCount({renderCount: renderCount + 1})
                }
            })
        }
        
        setSelectedDay(moment(selectedDate).format('dddd'))
    }
    function renderAgain(arg1) {
        if (selectedDate != todaysDate) {
            clearTimeout(timeOutBar)
            setTimeOutBar(null)
        } else {
            if (arg1 == 'runAgain') {
                const tempTimeout = setTimeout(() => {
                    processProgressBar('runAgain')
                }, 60000);
                setTimeOutBar(tempTimeout)
            } else {
                showPreloader({preloaderVisible: false})
            }
        }
    }
    function sortUsers(users) {
        // Move user with ID 3 to the top
        const userWithID = users.find(user => user.ID === tokenData.ID);
        const usersWithoutID = users.filter(user => user.ID !== tokenData.ID);
      
        // Check if users have a CheckIn key and sort accordingly
        const usersWithCheckIn = usersWithoutID.filter(user => user.UserAttendanceData.some(att => att.CheckIn));
        const usersWithoutCheckIn = usersWithoutID.filter(user => !user.UserAttendanceData.some(att => att.CheckIn));
        showPreloader({preloaderVisible: false})
        // Concatenate the sorted arrays
        return [userWithID, ...usersWithCheckIn, ...usersWithoutCheckIn];
    }
    // useEffect(() => {
    //     processProgressBar('runAgain')
    // }, [userList])
    useEffect(() => {
        if (userList) {
            if (selectedDate == todaysDate) {
                processProgressBar('runAgain')
            } else {
                processProgressBar()
            }
        }
    }, [renderCount, selectedDate, userList])
    function calculateAboveEightHours(totalTime, CheckIn) {
        const timeStart = compareTimePercentage(CheckIn);
        if (totalTime === undefined || totalTime === null || totalTime === '') {
            if (CheckIn) {
                if (timeStart >= 100) {
                    return ''
                    // <ProgressBar striped variant="success" now="100" key={1} />
                }
                return ''
                // <ProgressBar striped animated variant="primary" now={timeStart} key={1} />
            }
            return '--'
        }
        const durationParts = totalTime.split(' ');
        const hours = parseInt(durationParts[0], 10);
        const minutes = parseInt(durationParts[2], 10);

        const momentDuration = moment.duration({ hours, minutes });

        const eightHours = moment.duration(9, 'hours');

        const isAboveEightHours = momentDuration.asHours() >= eightHours.asHours();
        if (isAboveEightHours) {
            return <>
                <span class="text-success fw-bold">{totalTime}</span>
                {/* <ProgressBar striped variant="success" now="100" key={1} /> */}
            </>
        } else {
            return <>
                <span class="text-danger fw-bold">{totalTime}</span>
                {/* <ProgressBar striped variant="danger" now={timeStart} key={1} /> */}
            </>
        }
    }
    const TotalWorkingHours = 9;
    const covertToSecondsTWH = TotalWorkingHours * 60 * 60
    function compareTimePercentage(givenTimeString) {
        const givenTime = new Date(givenTimeString);

        const currentTime = new Date();

        const givenTimeInSeconds = givenTime.getTime() / 1000;
        const currentTimeInSeconds = currentTime.getTime() / 1000;

        const timeDifference = currentTimeInSeconds - givenTimeInSeconds;
        if (timeDifference < covertToSecondsTWH) {
            const percentage = (timeDifference / covertToSecondsTWH) * 100;
            return Math.round(percentage);
        } else {
            return 100;
        }
    }
    useEffect(() => {
        const fetchLocation = async () => {
            if ('geolocation' in navigator) {
                try {
                    const position = await new Promise((resolve, reject) => {
                        navigator.geolocation.getCurrentPosition(resolve, reject)
                    })

                    const { latitude, longitude } = position.coords

                    const response = await fetch(
                        `https://nominatim.openstreetmap.org/reverse?format=json&lat=${latitude}&lon=${longitude}`
                    )
                    const data = await response.json()
                    const city =
                        data.address.city ||
                        data.address.town ||
                        data.address.village ||
                        data.address.hamlet ||
                        ''
                    const country = data.address.country || ''
                    setUserCurrentLocation({ userCurrentLocation: city })
                    setLatLong({ 'lat': latitude, 'long': longitude })
                } catch (error) {
                    console.error('Error getting location:', error.message)
                }
            } else {
                console.error('Geolocation is not supported by this browser.')
            }
        }
        fetchLocation()
    }, [])
    const handleSubmitLoginLogout = (userID, actionType, username) => {
        let text = "Hi, " + username + "!!\nAre you sure you want to " + actionType;
        if (window.confirm(text) == true) {
            const localDateTime = new Date();
            const moment = require('moment');
            const date = moment();
            const formattedDate = date.format('YYYY-MM-DD');
            let checkInOut = {
                UserID: userID,
                Date: formattedDate,
                Location: userCurrentLocation,
                Lat: latLong?.lat,
                Long: latLong?.long
            }
            if (checkInOut.Lat && checkInOut.Long) {
                axios.put('/attendance', checkInOut).then(res => {
                    if (res.data.message === 'Success') {
                        // setShowSuccessModal(true)
                        if (actionType === 'login') {
                            localStorage.setItem('loggedIn', true)
                        } else {
                            localStorage.clear()
                        }
                        setTimeout(() => {
                            // setShowSuccessModal(false)
                            setDisableSubmitButton(false)
                            // navigate('/addTask')
                        }, 2000);
                        setRenderCount({renderCount: parseInt(renderCount) + 1});
                    }
                })
            } else {
                setDisableSubmitButton(false)
                alert('Enable Location to Proceed')
            }
        } else {
            text = "You canceled!";
        }
    }

    const isAfter10AM = (time) => {
        const timeToCheck = moment(time, 'hh:mm A'); // Parse the time using moment
        const tenAM = moment('10:30 AM', 'hh:mm A'); // Define 10 AM using moment
        return timeToCheck.isAfter(tenAM) // Check if the given time is after 10 AM
    };

    const handleIsAfter10AM = (time) => {
        const timeToCheck = moment(time, 'hh:mm A'); // Parse the time using moment
        const tenAM = moment('10:00 AM', 'hh:mm A'); // Define 10 AM using moment
        return timeToCheck.isAfter(tenAM) // Check if the given time is after 10 AM
    };

    const isLate10AM = (time) => {
        const timeToCheck = moment(time, 'hh:mm A'); // Parse the time using moment
        const isLate = moment('10:00 AM', 'hh:mm A'); // Define 10 AM using moment
        return timeToCheck.isAfter(isLate); // Check if the given time is after 10 AM
    };

    const isAfter7PM = (time) => {
        const timeToCheck = moment(time, 'hh:mm A'); // Parse the time using moment
        const sevenPM = moment('7:00 PM', 'hh:mm A'); // Define 7 PM using moment
        return timeToCheck.isAfter(sevenPM); // Check if the given time is after 7 PM
    };

    const handleLocationOnMap = (latLong) => {
        let newLatLong = latLong.split(' ');
        window.open('https://www.google.com/maps/?q=' + newLatLong[0] + ',' + newLatLong[2], '_blank');
    }

    const updateTaskStatus = (taskStatus) => {
        let text = "Hi, " + userData.UserName + "!!\nAre you sure you want to go on Break";
        if (window.confirm(text) == true) {
            setDisabledOnBreakButton(true)
            localStorage.setItem('break', true);
            const moment = require('moment');
            const date = moment();
            const formattedDate = date.format('YYYY-MM-DD');
            const formattedDateTime = date.format('YYYY-MM-DD HH:mm:ss');
            const taskData = {
                'UserID': userData.ID,
                'Status': taskStatus,
                'DateTime': formattedDateTime,
                'Date': formattedDate,
                'Duration': 0,
                'ProjectID': 0,
                'TaskID': 901,
                'Description': '',
                'TaskName': 'Break',
                'UserName': userData.UserName,
                'ProjectName': 'SkyQ'
            }
            axios.put('/entries', taskData).then(res => {
                if (res.data.message === 'Success') {
                    setRenderCount({renderCount: parseInt(renderCount) + 1})
                }
            })
        } else {
            text = "You canceled!";
        }
    }

    const calculateDurationInMinutes = (startTime, endTime) => {
        const start = new Date(`${startTime}`);
        const end = new Date(`${endTime}`);
        const duration = (end - start) / 60000; // converting milliseconds to minutes
        return duration;
    };

    let tempAllUsersTotalMinutes = 0
    let tempAllUsersTotalBreakMinutes = 0

    function convertMinutes(minutes) {
        const hours = Math.floor(minutes / 60);
        const remainingMinutes = minutes % 60;
        return `${hours} Hours ${remainingMinutes} Minutes`;
    }

    function createObject(events, loginTime, index, startTime, endTime, label, color, taskStatus) {
        let tempNewObj = {}
        tempNewObj.StartTime = startTime
        tempNewObj.EndTime = endTime
        tempNewObj.Label = label
        tempNewObj.Color = color
        if (taskStatus == 'striped') {
            tempNewObj.Striped = true
        }

        const start = new Date(`${startTime}`);
        const startMilliseconds = start.getTime()
        const end = new Date(endTime);
        const endMilliseconds = end.getTime()
    
        const diffInMilliseconds = endMilliseconds - startMilliseconds;
        tempNewObj.DurationInMinutes = Math.round(diffInMilliseconds / 60000);

        tempNewObj.Percentage = tempNewObj.DurationInMinutes / 540 * 100
        // tempArr.push(tempNewObj)
        return tempNewObj
    }
    function calculateTasksPercentage(events, loginTime, logoutTime) {
        const options = { hour: 'numeric', minute: 'numeric', second: 'numeric', hour12: false };
        const options1 = { year: 'numeric', month: '2-digit', day: '2-digit'};
        let localTime  = new Date().toLocaleTimeString('en-us', options)
        let localDate = new Date().toLocaleDateString('en-GB', options1)
        let day = localDate.split('/')[0]
        let month = localDate.split('/')[1]
        let year = localDate.split('/')[2]
        let tempArr = []

        const today = new Date();
        const yyyy = today.getFullYear();
        const mm = String(today.getMonth() + 1).padStart(2, '0'); // Months are zero-based
        const dd = String(today.getDate()).padStart(2, '0');
        const formattedDate = `${yyyy}-${mm}-${dd}T10:00:00.000Z`;
        const formattedLoginDateTime = `${yyyy}-${mm}-${dd}T${moment(loginTime).format('HH:mm')}:00.000Z`;

        tempArr.push(createObject(events, loginTime, 0, formattedDate, formattedLoginDateTime, 'LATE', 'white'))

        let reverseSampleData = events;
        
        for (let index = 0; index < reverseSampleData.length; index++) {
            // if (reverseSampleData[index].UserID == 4) {
                if (((new Date(reverseSampleData[0].DateTime).getTime()) != (new Date(loginTime).getTime()) && index == 0)) {
                    // LATE
                    
                    tempArr.push(createObject(events, loginTime, index, formattedLoginDateTime, reverseSampleData[index].DateTime, 'IDLE', 'danger'))

                    const options1 = { year: 'numeric', month: '2-digit', day: '2-digit'};
                    let localTime  = new Date().toLocaleTimeString('en-us', options)
                    let localDate = new Date().toLocaleDateString('en-GB', options1)
                    let day = localDate.split('/')[0]
                    let month = localDate.split('/')[1]
                    let year = localDate.split('/')[2]
                    if ((reverseSampleData[reverseSampleData.length - 1].Status == 'onGoing') && (reverseSampleData.length - 1 == index)) {
                        let tempNewObj = {}
                        tempNewObj.StartTime = reverseSampleData[reverseSampleData.length - 1].DateTime
                        
                        tempNewObj.EndTime = year + '-' + month + '-' + day + 'T' + localTime + '.000Z'
                        tempNewObj.Label = reverseSampleData[reverseSampleData.length - 1].TaskName
                        tempNewObj.Color = 'primary'
                        tempNewObj.Striped = true
                        // tempNewObj.Status = reverseSampleData[reverseSampleData.length - 1].Status
                        
                        const start = new Date(`${reverseSampleData[reverseSampleData.length - 1].DateTime}`);
                        const end = new Date(year + '-' + month + '-' + day + 'T' + localTime + '.000Z');
                    
                        const diffInMilliseconds = end - start;
                        tempNewObj.DurationInMinutes = Math.round(diffInMilliseconds / 60000);
                        tempNewObj.Percentage = tempNewObj.DurationInMinutes / 540 * 100
                        tempArr.push(tempNewObj)
                    }
                } else {
                    if (index !== 0) {
                        if (reverseSampleData[index].Status == 'completed') {
                            let tempNewObj = {}
                            if (reverseSampleData[index - 1].Status == 'onGoing' && (reverseSampleData[index].TaskID == reverseSampleData[index - 1].TaskID)) {
                                tempNewObj.StartTime = reverseSampleData[index - 1].DateTime
                                tempNewObj.EndTime = reverseSampleData[index].DateTime
                                tempNewObj.Label = reverseSampleData[index].TaskName
                                tempNewObj.Color = 'success'
                                // tempNewObj.Status = reverseSampleData[index].Status
                                
                                const start = new Date(`${reverseSampleData[index - 1].DateTime}`);
                                const end = new Date(`${reverseSampleData[index].DateTime}`);
                            
                                const diffInMilliseconds = end - start;
                                tempNewObj.DurationInMinutes = Math.round(diffInMilliseconds / 60000);
                                tempNewObj.Percentage = tempNewObj.DurationInMinutes / 540 * 100
                            } else {
                                // console.log('ELSE', reverseSampleData[index - 1], reverseSampleData[index])
                            }
                            tempArr.push(tempNewObj)
                        } else if (reverseSampleData[index].Status == 'paused') {
                            let tempNewObj = {}
                            if (reverseSampleData[index - 1].Status == 'onGoing' && (reverseSampleData[index].TaskID == reverseSampleData[index - 1].TaskID)) {
                                tempNewObj.StartTime = reverseSampleData[index - 1].DateTime
                                tempNewObj.EndTime = reverseSampleData[index].DateTime
                                tempNewObj.Label = reverseSampleData[index].TaskName
                                tempNewObj.Color = 'warning'
                                
                                const start = new Date(`${reverseSampleData[index - 1].DateTime}`);
                                const end = new Date(`${reverseSampleData[index].DateTime}`);
                            
                                const diffInMilliseconds = end - start;
                                tempNewObj.DurationInMinutes = Math.round(diffInMilliseconds / 60000);
                                tempNewObj.Percentage = tempNewObj.DurationInMinutes / 540 * 100
                            } else {
                                // console.log('ELSE', reverseSampleData[index - 1], reverseSampleData[index])
                            }
                            tempArr.push(tempNewObj)
                        }
                    }
                    if ((reverseSampleData[reverseSampleData.length - 1].Status == 'onGoing') && (reverseSampleData.length - 1 == index)) {
                        let tempNewObj = {}
                        tempNewObj.StartTime = reverseSampleData[reverseSampleData.length - 1].DateTime
                        
                        tempNewObj.EndTime = year + '-' + month + '-' + day + 'T' + localTime + '.000Z'
                        tempNewObj.Label = reverseSampleData[reverseSampleData.length - 1].TaskName
                        tempNewObj.Color = 'primary'
                        tempNewObj.Striped = true
                        // tempNewObj.Status = reverseSampleData[reverseSampleData.length - 1].Status
                        
                        const start = new Date(`${reverseSampleData[reverseSampleData.length - 1].DateTime}`);
                        const end = new Date(year + '-' + month + '-' + day + 'T' + localTime + '.000Z');
                    
                        const diffInMilliseconds = end - start;
                        tempNewObj.DurationInMinutes = Math.round(diffInMilliseconds / 60000);
                        tempNewObj.Percentage = tempNewObj.DurationInMinutes / 540 * 100
                        tempArr.push(tempNewObj)
                    }
                }
                if (index == (reverseSampleData.length - 1)) {
                    if (reverseSampleData[reverseSampleData.length - 1].Status == 'completed' || reverseSampleData[reverseSampleData.length - 1].Status == 'paused') {
                        if (logoutTime) {
                            tempArr.push(createObject(events, loginTime, index, reverseSampleData[reverseSampleData.length - 1].DateTime, logoutTime, 'IDLE', 'danger', 'striped'))
                        } else {
                            tempArr.push(createObject(events, loginTime, index, reverseSampleData[reverseSampleData.length - 1].DateTime, year + '-' + month + '-' + day + 'T' + localTime + '.000Z', 'IDLE', 'danger', 'striped'))
                        }
                    }
                }
            // }
        }

        return tempArr
    }

    const calculateTimeDifferences = (tasks) => {
        let newArr = []
        // newArr.push(data[0])
        const today = new Date();
        const yyyy = today.getFullYear();
        const mm = String(today.getMonth() + 1).padStart(2, '0'); // Months are zero-based
        const dd = String(today.getDate()).padStart(2, '0');
        const formattedDate = `${yyyy}-${mm}-${dd}T10:00:00.000Z`;
        for (let i = 0; i < tasks.length; i++) {
            const task = tasks[i]
            const prevEndTime = new Date((i == 0) ? formattedDate : tasks[i - 1].EndTime);
            const currentStartTime = new Date(task.StartTime);
            const timeDifferenceInMinutes = Math.round((currentStartTime - prevEndTime) / (1000 * 60));
            const timeDifferenceInPercentage = timeDifferenceInMinutes / 540 * 100
            if (timeDifferenceInMinutes > 0) {
                const newVar = {
                    StartTime: tasks[i - 1].EndTime,
                    EndTime: task.StartTime,
                    Label: 'IDLE',
                    Color: 'danger',
                    DurationInMinutes: timeDifferenceInMinutes,
                    Percentage: timeDifferenceInPercentage
                }
                newArr.push(newVar)
            }
            newArr.push(task)
        }
        return newArr
    };

    useEffect(() => {
        // Function to handle visibility change
        const handleVisibilityChange = () => {
        setIsTabActive(document.visibilityState === 'visible');
        };

        // Add event listener for visibility change
        document.addEventListener('visibilitychange', handleVisibilityChange);

        // Cleanup event listener on component unmount
        return () => {
        document.removeEventListener('visibilitychange', handleVisibilityChange);
        };
    }, []);

    useEffect(() => {
        attendanceListData.map((v,k) => {
          if (v.ID == userData.ID) {
            if (v.CheckIn) {
              setUserLoginStatus(true)
            } else {
              setUserLoginStatus(false)
            }
          }
        })
    }, [[attendanceListData, renderCount]])
    useEffect(() => {
        if (allUsersTotalDuration && totalProdutiveTime) {
            setProductivityPercentage(Math.floor(totalProdutiveTime / allUsersTotalDuration * 100))
        }
    }, [allUsersTotalDuration, totalProdutiveTime])
    
    const popoverClickRootClose = (
        <Popover id="popover-trigger-click-root-close">
           <div class="card">
                <div class="card-body p-1">
                    {role === 2 &&
                        <form onSubmit={(e) => {handleSubmitNote(e)}}>
                            <div class="form-group boxed">
                                <div class="input-wrapper">
                                    <textarea id="note" rows="4" onChange={(e) => setNote(e.target.value)} value={note} placeholder={'Note'} class="form-control"></textarea>
                                    <i class="clear-input">
                                        <ion-icon name="close-circle"></ion-icon>
                                    </i>
                                </div>
                            </div>
                            <div class="btn-group w-100" role="group">
                                <input type="radio" class="btn-check" name="warningFlags" onChange={() => {setFlag(0)}} checked={(!flag || flag === 0) ? true : false} id="btnradioa1" />
                                <label class="btn btn-outline-primary" for="btnradioa1"><ion-icon name="ban-outline" class="me-0"></ion-icon></label>

                                <input type="radio" class="btn-check" name="warningFlags" onChange={() => {setFlag(1)}} checked={(flag === 1) ? true : false} id="btnradioa2" />
                                <label class="btn btn-outline-warning" for="btnradioa2"><ion-icon name="flag" class="me-0"></ion-icon></label>

                                <input type="radio" class="btn-check" name="warningFlags" onChange={() => {setFlag(2)}} checked={(flag === 2) ? true : false} id="btnradioa3" />
                                <label class="btn btn-outline-danger" for="btnradioa3"><ion-icon name="flag" class="me-0"></ion-icon></label>
                            </div>
                            <button type="submit" class="btn btn-success d-grid m-auto mt-1">Save</button>
                        </form>
                    }
                    {role !== 2 &&
                        <div>
                            <p class="p-1 fs-6 mb-0">{note}</p>
                        </div>
                    }
                </div>
            </div>
        </Popover>
    );

    const handleSubmitNote = (e) => {
        e.preventDefault()
        const noteData = {
            'Note': (note) ? note : null,
            'Flag': (flag) ? flag : 0,
            'AttendanceID': (attendanceID) ? attendanceID : null
        }
        axios.post('/attendance', noteData).then(res => {
            if (res.data.message === 'Success') {
                setRenderCount({renderCount: renderCount + 1})
            }
        })
    }

    const handleSetUserID = (e, userData) => {
        e.stopPropagation()
        setNote(userData.UserAttendanceData[0].Note)
        setFlag(userData.UserAttendanceData[0].Flag)
        setAttendanceID(userData.UserAttendanceData[0].AttendanceID)
        setUserID(userData.ID)
    }

    const handleProjectFilter = (ProjectID) => {
        setProjectID(ProjectID)
        processProgressBar()
    }
  return (
    <div id="appCapsule" class="pb-0">
        <div class="mt-2">
            <div class="row d-flex align-items-center w-100">
                <div class="col-3 col-md-3 d-flex align-items-center justify-content-center justify-content-md-start mb-1 order-1 order-md-1">
                    <div class="ms-2 btn-group me-1" role="group">
                        <input type="radio" class="btn-check" name="switchView" value="user" onChange={(e) => {setView(e.target.value)}} checked={(view === 'user')} id="userView" />
                        <label class="btn btn-icon btn-outline-primary btn-sm" for="userView">
                            <ion-icon name="person-outline"></ion-icon>
                        </label>
                        <input type="radio" class="btn-check" name="switchView" value="project" id="projectView" onChange={(e) => {setView(e.target.value)}} checked={(view === 'project')} />
                        <label class="btn btn-icon btn-outline-primary btn-sm" for="projectView">
                            <ion-icon name="list-outline"></ion-icon>
                        </label>
                    </div>
                </div>
                <div class="col-5 col-md-6 order-3 order-md-2">
                    <div class="d-flex justify-content-center flex-column flex-sm-row text-center" style={{fontSize: '13px'}}>
                        <span class="me-1"><ion-icon name="person-outline"></ion-icon>&nbsp; <span class="fw-bold">{totalAttendance}/{totalUsersCount}&nbsp;({totalAbsent})</span></span>
                        <span class="me-1"> 
                            <ion-icon name="timer-outline"></ion-icon>&nbsp;
                            <span class="">
                                <span class="fw-bold" title={convertMinutes(totalProdutiveTime) + ' / ' + convertMinutes(allUsersTotalDuration)}>{convertMinutes(totalProdutiveTime)}</span>&nbsp;
                            </span>
                            <span class="fw-bold">({productivityPercentage}%)</span>
                        </span>
                    </div>
                    <div class="form-group basic d-grid p-0">
                        <div class="input-wrapper m-auto">
                            <input type="date" class="form-control form-control-sm p-0" style={{height: '0px', fontSize: '11px'}} id="datepicker" placeholder="" autocomplete="off" max={todaysDate} value={selectedDate} onChange={(e) => {setSelectedDate(e.target.value)}} />
                            <i class="clear-input">
                                <ion-icon name="close-circle"></ion-icon>
                            </i>
                        </div>
                    </div>
                </div>
                <div class="col-4 col-md-3 text-end d-flex justify-content-center justify-content-md-end pe-md-3 order-3 order-md-2">
                    {!disabledOnBreakButton &&
                        <button class="btn btn-outline-danger me-1 text-nowrap btn-sm" onClick={() => { updateTaskStatus('onGoing') }} disabled={disabledOnBreakButton}>
                            <ion-icon name="cafe-outline" class="me-0"></ion-icon> 
                            <span class="d-none d-sm-block">Break</span>
                        </button>
                    }
                    {disabledOnBreakButton &&
                        <button class="btn btn-outline-success me-1 text-nowrap btn-sm" onClick={() => { updateTaskStatus('paused') }} disabled={!disabledOnBreakButton}>
                            <ion-icon name="play-outline" class="me-0"></ion-icon> 
                            <span class="d-none d-sm-block">Resume</span>
                        </button>
                    }
                    <button class="btn btn-success text-nowrap btn-sm" onClick={() => {showAddTaskModal({addTaskModalVisible: true})}}>
                        <ion-icon name="add-outline" class="me-0"></ion-icon> 
                        <span class="d-none d-sm-block">Task</span>
                    </button>
                </div>
            </div>
        </div>
        {view == 'user' &&
            <>
                <div class="mt-2">
                    <div class="row mb-0 overflow-auto ps-2 pe-2">
                        <div class="btn-group p-0" role="group">
                            <div onClick={() => {handleProjectFilter('all')}}>
                                <input type="radio" class="btn-check" checked={(pid === 'all') ? true : false} name="projectList" id={"projectNameAll"} />
                                <label class="btn btn-outline-primary border-primary text-nowrap pe-1 ps-1 me-2 ms-2" style={{height: '35px', borderRadius: '5px'}} for={"projectNameAll"}>All</label>
                            </div>
                            {allProjectList?.map((v,k) => {
                                return <>
                                    <div style={{position: 'relative'}} onClick={() => {handleProjectFilter(v.ID)}}>
                                        <input type="radio" class="btn-check" name="projectList" id={"projectName" + k} checked={(projectID === v.ID) ? true : false} />
                                        <label class="btn btn-outline-primary border-primary text-nowrap pe-1 ps-1 me-2 ms-2" style={{height: '35px', borderRadius: '5px'}} for={"projectName" + k}>{v.Name}</label>
                                        {(finalUserList?.filter(itm => (v.ID == itm?.UserAttendanceData[0]?.TaskEntries[0]?.ProjectID)).length) > 0 &&
                                            <span class="badge bg-danger" style={{height: '18px', top: 0, right: 15, position: 'absolute'}}>{(finalUserList?.filter(itm => (v.ID == itm?.UserAttendanceData[0]?.TaskEntries[0]?.ProjectID)).length)}</span>
                                        }
                                    </div>
                                </>
                            })}
                        </div>
                    </div>
                </div>
                <div class="p-1 pb-0">
                    <div class="row">
                        <div class="col-12 col-md-8 border-end" style={{backgroundColor: '#34c7594f', height: '82vh', overflow: 'scroll'}}>
                            <h3 class="pt-2 text-dark text-center d-flex justify-content-center">ONGOING&nbsp; <span class="badge bg-secondary text-white" style={{height: '18px'}}>{finalUserListOngoing?.length}</span></h3>
                            <div class="row">
                                {finalUserListOngoing && finalUserListOngoing.map((v,k) => {
                                    const after10AM = isAfter10AM(moment(v?.UserAttendanceData[0]?.CheckIn).format('hh:mm A'));
                                    const tempAfter10AM = handleIsAfter10AM(moment(v?.UserAttendanceData[0]?.CheckIn).format('hh:mm A'));
                                    const late10AM = isLate10AM(moment(v?.UserAttendanceData[0]?.CheckIn).format('hh:mm A'));
                                    const today = new Date();
                                    const yyyy = today.getFullYear();
                                    const mm = String(today.getMonth() + 1).padStart(2, '0'); // Months are zero-based
                                    const dd = String(today.getDate()).padStart(2, '0');

                                    // need to create function
                                    const formattedDate = `${yyyy}-${mm}-${dd}T10:00:00.000Z`;
                                    const formattedLoginDateTime = `${yyyy}-${mm}-${dd}T${moment(v.UserAttendanceData[0].CheckIn).format('HH:mm')}:00.000Z`;
                                    const isoStringStartTime = formattedDate;
                                    const isoStringEndTime = formattedLoginDateTime;
                                    const duration = moment.duration(moment(isoStringEndTime).diff(moment(isoStringStartTime)));
                                    let hours
                                    if (tempAfter10AM) {
                                        hours = Math.floor(duration.asHours());    
                                    } else {
                                        hours = Math.ceil(duration.asHours());
                                    }
                                    // const hours = Math.floor(duration.asHours());
                                    const minutes = duration.minutes();
                                    const TotalDuration = hours + ' hr ' + minutes + ' min'
                                    // need to create function
                                    const formattedDate1 = `${yyyy}-${mm}-${dd}T19:00:00.000Z`;
                                    const formattedLogoutDateTime1 = `${yyyy}-${mm}-${dd}T${moment(v.UserAttendanceData[0].CheckOut).format('HH:mm')}:00.000Z`;
                                    const isoStringStartTime1 = formattedDate1;
                                    const isoStringEndTime1 = formattedLogoutDateTime1;
                                    const duration1 = moment.duration(moment(isoStringEndTime1).diff(moment(isoStringStartTime1)));
                                    const hours1 = Math.round(duration1.asHours());
                                    const minutes1 = duration1.minutes();
                                    const TotalDuration1 = '[' + hours1 + ' hr ' + minutes1 + ' min]'
                                    return <>
                                            {/* {(v.UserAttendanceData[0].Timeline.length > 0) && (v.ProgressBar[v.ProgressBar.length - 1].Color == 'primary' && v.ProgressBar[v.ProgressBar.length - 1].Label != 'BREAK') && */}
                                                <div class="col-md-6 col-12">
                                                    <Accordion style={{background: 'transparent', border: 'none'}}>
                                                        <>
                                                            <div class="card mb-2">
                                                                <div class="card-body p-0">
                                                                    <Accordion.Item eventKey={k} class="clockyAcod">
                                                                        <Accordion.Header>
                                                                            <div class="row w-100">
                                                                                <div class="col-12 col-md-12 mb-0 d-flex justify-content-between">
                                                                                    <div class="d-flex align-items-center justify-content-between w-100">
                                                                                        <div class="d-flex align-items-center justify-content-between w-100">
                                                                                            <div class="d-flex align-items-center">
                                                                                                <span class="fw-bold me-1 d-flex align-items-center">
                                                                                                    <ion-icon name="person-outline" class="fs-5 me-0"></ion-icon> {v.UserName}   
                                                                                                </span>
                                                                                                {(Math.ceil(v.ProductiveTime / v.TotalDuration * 100) <= 50) &&
                                                                                                    <span class="badge bg-danger text-white ms-1">
                                                                                                        {Math.ceil(v.ProductiveTime / v.TotalDuration * 100) + '%'}
                                                                                                    </span>
                                                                                                }
                                                                                                {(Math.ceil(v.ProductiveTime / v.TotalDuration * 100) <= 80 && Math.ceil(v.ProductiveTime / v.TotalDuration * 100) > 50) &&
                                                                                                    <span class="badge bg-warning text-white ms-1">
                                                                                                        {Math.ceil(v.ProductiveTime / v.TotalDuration * 100) + '%'}
                                                                                                    </span>
                                                                                                }
                                                                                                {(Math.ceil(v.ProductiveTime / v.TotalDuration * 100) > 80) &&
                                                                                                    <span class="badge bg-success text-white ms-1">
                                                                                                        {Math.ceil(v.ProductiveTime / v.TotalDuration * 100) + '%'}
                                                                                                    </span>
                                                                                                }
                                                                                            </div>
                                                                                            <OverlayTrigger trigger="click"
                                                                                                rootClose
                                                                                                placement="left"
                                                                                                overlay={popoverClickRootClose}
                                                                                            >
                                                                                                <div onClick={(e) => {handleSetUserID(e, v)}}>
                                                                                                    {(role === 2 && v.UserAttendanceData[0].Flag !== 1 && v.UserAttendanceData[0].Flag !== 2) &&
                                                                                                        <ion-icon name="flag-outline" class="me-0"></ion-icon>
                                                                                                    }
                                                                                                    {v.UserAttendanceData[0].Flag === 1 && 
                                                                                                        <ion-icon name="flag" class="me-0 text-warning"></ion-icon>
                                                                                                    }
                                                                                                    {v.UserAttendanceData[0].Flag === 2 && 
                                                                                                        <ion-icon name="flag" class="me-0 text-danger"></ion-icon>
                                                                                                    }
                                                                                                </div>
                                                                                            </OverlayTrigger>
                                                                                        </div>
                                                                                        <div>
                                                                                            {(calculateAboveEightHours(v.UserAttendanceData[0].TotalTime, v.UserAttendanceData[0].CheckIn, v.UserAttendanceData[0].TaskEntries) != '' && calculateAboveEightHours(v.UserAttendanceData[0].TotalTime, v.UserAttendanceData[0].CheckIn, v.UserAttendanceData[0].TaskEntries) != '--') &&
                                                                                                <span class="badge border-primary" style={{borderWidth: '1px'}}>
                                                                                                    {calculateAboveEightHours(v.UserAttendanceData[0].TotalTime, v.UserAttendanceData[0].CheckIn, v.UserAttendanceData[0].TaskEntries)}
                                                                                                </span>
                                                                                            }
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-12 col-md-12">
                                                                                    <div class="d-flex justify-content-between" style={{marginBottom: '3px'}}>
                                                                                        {v.UserAttendanceData[0].CheckIn &&
                                                                                            <h4 class="mb-0 d-flex align-items-cente">
                                                                                                <span class="cursor-pointer fs-10" onClick={() => { handleLocationOnMap(v.UserAttendanceData[0].CheckInLocation) }}>
                                                                                                    <ion-icon name="location-outline" class="me-0" style={{fontSize: '15px'}}></ion-icon>
                                                                                                </span>
                                                                                                <span class={(after10AM) ? "text-danger" : "text-success"}>
                                                                                                    <Moment format="hh:mm A">{v.UserAttendanceData[0].CheckIn}</Moment>
                                                                                                </span>&nbsp;&nbsp;
                                                                                                <span>
                                                                                                    {v?.UserAttendanceData[0]?.CheckInDevice?.includes('Desktop') &&
                                                                                                        <ion-icon name="desktop-outline" class="me-0" style={{fontSize: '15px'}}></ion-icon>
                                                                                                    }
                                                                                                    {v?.UserAttendanceData[0]?.CheckInDevice?.includes('Mobile') &&
                                                                                                        <ion-icon name="phone-portrait-outline" class="me-0" style={{fontSize: '15px'}}></ion-icon>
                                                                                                    }
                                                                                                    {v?.UserAttendanceData[0]?.CheckInDevice?.includes('Tablet') &&
                                                                                                        <ion-icon name="tablet-landscape-outline" class="me-0" style={{fontSize: '15px'}}></ion-icon>
                                                                                                    }
                                                                                                    {(v?.UserAttendanceData[0]?.CheckInDevice?.includes('Card')) &&
                                                                                                        <ion-icon name="card-outline" class="me-0" style={{fontSize: '15px'}}></ion-icon>
                                                                                                    }
                                                                                                </span>
                                                                                                <span class={(after10AM) ? "text-danger" : "text-success"} style={{fontSize: '11px'}}><span class="text-secondary fw-bold">{TotalDuration.replace(/-/g, '').trim()} {(late10AM) ? 'Late' : 'Early'}</span></span>
                                                                                            </h4>
                                                                                        }
                                                                                        {(v.UserAttendanceData[0].CheckIn && v.UserAttendanceData[0].CheckOut) &&
                                                                                            <h4 class="mb-0 d-flex align-items-center">
                                                                                                <span>
                                                                                                    <Moment format="hh:mm A">{v.UserAttendanceData[0].CheckOut}</Moment>
                                                                                                </span>
                                                                                                <span class="cursor-pointer" onClick={() => { handleLocationOnMap(v.UserAttendanceData[0].CheckOutLocation) }}>
                                                                                                    <ion-icon name="location-outline" style={{fontSize: '15px'}}></ion-icon>
                                                                                                </span>
                                                                                            </h4>
                                                                                        }
                                                                                    </div>
                                                                                    {v.UserAttendanceData[0].CheckIn ?
                                                                                        <>
                                                                                            <ProgressBar>
                                                                                                {v?.ProgressBar?.map((v1,k1) => {
                                                                                                    const label = v1.Label
                                                                                                    const isoStringStartTime = v1.StartTime;
                                                                                                    const isoStringEndTime = v1.EndTime;
                                                                                                    const formattedStartTime = moment(isoStringStartTime).utc().format('h:mm A');
                                                                                                    const formattedEndTime = moment(isoStringEndTime).utc().format('h:mm A');
                                                                                                    const duration = moment.duration(moment(isoStringEndTime).diff(moment(isoStringStartTime)));
                                                                                                    const hours = Math.floor(duration.asHours());
                                                                                                    const minutes = duration.minutes();
                                                                                                    const tooltip = <Tooltip>
                                                                                                        <b>{label}</b><br />
                                                                                                        {formattedStartTime}.{formattedEndTime}<br />
                                                                                                        {hours} Hours(s) {minutes} minutes
                                                                                                    </Tooltip>
                                                                                                    return (
                                                                                                        <OverlayTrigger placement="top" overlay={tooltip}>
                                                                                                            <ProgressBar isChild={true} style={{height: '12px', borderRight: '0.5px solid #e9ecef'}} variant={(label == 'BREAK') ? 'dark' : v1?.Color} striped={v1?.Striped} animated={v1?.Striped} now={Math.round(v1?.Percentage)} key={k1} label={(v1?.label == 'BREAK') ? 'BREAK' : ''} />
                                                                                                        </OverlayTrigger>
                                                                                                    )
                                                                                                })}
                                                                                            </ProgressBar>
                                                                                        </>
                                                                                    :
                                                                                        <>
                                                                                            <h2 class="text-danger">Absent</h2>
                                                                                        </>
                                                                                    }
                                                                                    {v.UserAttendanceData[0].CheckIn &&
                                                                                        <div class="d-flex justify-content-between" style={{marginTop: '3px'}}>
                                                                                            <>
                                                                                                {(v.UserAttendanceData[0].TaskEntries.length > 0) &&
                                                                                                    <>
                                                                                                        {v.UserAttendanceData[0].TaskEntries[0].Status == 'onGoing' &&
                                                                                                            <h5 class="mb-0 fw-bold d-flex align-items-center w-75">
                                                                                                                {v.UserAttendanceData[0].TaskEntries[0].TaskName}&nbsp;({v.UserAttendanceData[0].TaskEntries[0].ProjectName})
                                                                                                            </h5>
                                                                                                        }
                                                                                                        {((v.UserAttendanceData[0].TaskEntries[0].Status == 'paused' || v.UserAttendanceData[0].TaskEntries[0].Status == 'completed' || v.UserAttendanceData[0].TaskEntries[0].Status == 'toDo') && !v.UserAttendanceData[0].CheckOut) ?
                                                                                                            <h5 class="mb-0 fw-bold d-flex align-items-center">
                                                                                                                <ion-icon name="ban" class="fs-6 me-0 text-danger fw-bold"></ion-icon> IDLE
                                                                                                            </h5>
                                                                                                        :
                                                                                                            <span class="badge border-white text-dark" style={{borderWidth: '1px'}}>
                                                                                                                <ion-icon name="pie-chart-outline" class="me-0 fs-6" style={{width: '30px'}}></ion-icon>{convertMinutes(v.ProductiveTime)}       
                                                                                                            </span>
                                                                                                        }
                                                                                                    </>
                                                                                                }
                                                                                                {(v.UserAttendanceData[0].TaskEntries.length == 0 && v.UserAttendanceData[0].CheckIn) &&
                                                                                                    <h5 class="mb-0 fw-bold d-flex align-items-center">
                                                                                                        <ion-icon name="ban" class="fs-6 me-0 text-danger fw-bold"></ion-icon> IDLE
                                                                                                    </h5>
                                                                                                }
                                                                                            </>
                                                                                        </div>
                                                                                    }
                                                                                </div>
                                                                            </div>
                                                                        </Accordion.Header>
                                                                        <Accordion.Body>
                                                                            <ul class="listview image-listview flush">
                                                                                {v.UserAttendanceData[0].TaskEntries.map((v1,k1) => {
                                                                                    return <TaskListView taskList={v1}></TaskListView>
                                                                                })}
                                                                            </ul>
                                                                        </Accordion.Body>
                                                                    </Accordion.Item>
                                                                </div>
                                                            </div>
                                                        </>
                                                    </Accordion>
                                                </div>
                                            {/* } */}
                                    </>
                                })}
                            </div>
                        </div>
                        <div class="col-12 col-md-4 pb-0" style={{height: '82vh', overflow: 'scroll'}}>
                            <div class="row" style={{backgroundColor: '#ec44338f'}}>
                                <h3 class="pt-2 text-dark text-center d-flex justify-content-center">IDLE&nbsp; <span class="badge bg-secondary text-white" style={{height: '18px'}}>{finalUserListIdle?.length}</span></h3>
                                {finalUserListIdle && finalUserListIdle.map((v,k) => {
                                    const after10AM = isAfter10AM(moment(v?.UserAttendanceData[0]?.CheckIn).format('hh:mm A'));
                                    const tempAfter10AM = handleIsAfter10AM(moment(v?.UserAttendanceData[0]?.CheckIn).format('hh:mm A'));
                                    const late10AM = isLate10AM(moment(v?.UserAttendanceData[0]?.CheckIn).format('hh:mm A'));
                                    const today = new Date();
                                    const yyyy = today.getFullYear();
                                    const mm = String(today.getMonth() + 1).padStart(2, '0'); // Months are zero-based
                                    const dd = String(today.getDate()).padStart(2, '0');

                                    // need to create function
                                    const formattedDate = `${yyyy}-${mm}-${dd}T10:00:00.000Z`;
                                    const formattedLoginDateTime = `${yyyy}-${mm}-${dd}T${moment(v.UserAttendanceData[0].CheckIn).format('HH:mm')}:00.000Z`;
                                    const isoStringStartTime = formattedDate;
                                    const isoStringEndTime = formattedLoginDateTime;
                                    const duration = moment.duration(moment(isoStringEndTime).diff(moment(isoStringStartTime)));
                                    let hours
                                    if (tempAfter10AM) {
                                        hours = Math.floor(duration.asHours());    
                                    } else {
                                        hours = Math.ceil(duration.asHours());
                                    }
                                    // const hours = Math.floor(duration.asHours());
                                    const minutes = duration.minutes();
                                    const TotalDuration = hours + ' hr ' + minutes + ' min'
                                    // need to create function
                                    const formattedDate1 = `${yyyy}-${mm}-${dd}T19:00:00.000Z`;
                                    const formattedLogoutDateTime1 = `${yyyy}-${mm}-${dd}T${moment(v.UserAttendanceData[0].CheckOut).format('HH:mm')}:00.000Z`;
                                    const isoStringStartTime1 = formattedDate1;
                                    const isoStringEndTime1 = formattedLogoutDateTime1;
                                    const duration1 = moment.duration(moment(isoStringEndTime1).diff(moment(isoStringStartTime1)));
                                    const hours1 = Math.round(duration1.asHours());
                                    const minutes1 = duration1.minutes();
                                    const TotalDuration1 = '[' + hours1 + ' hr ' + minutes1 + ' min]'
                                    return <>
                                        <div class="col-md-12 col-12">
                                            <Accordion style={{background: 'transparent', border: 'none'}}>
                                                <>
                                                    <div class="card mb-2">
                                                        <div class="card-body p-0">
                                                            <Accordion.Item eventKey={k} class="clockyAcod">
                                                                <Accordion.Header>
                                                                    <div class="row w-100">
                                                                        <div class="col-12 col-md-12 mb-0 d-flex justify-content-between">
                                                                            <div class="d-flex align-items-center justify-content-between w-100">
                                                                                <div class="d-flex align-items-center">
                                                                                    <span class="fw-bold me-1 d-flex align-items-center"><ion-icon name="person-outline" class="fs-5 me-0"></ion-icon> {v.UserName}</span>
                                                                                    {(Math.ceil(v.ProductiveTime / v.TotalDuration * 100) <= 50) &&
                                                                                        <span class="badge bg-danger text-white ms-1">
                                                                                            {Math.ceil(v.ProductiveTime / v.TotalDuration * 100) + '%'}
                                                                                        </span>
                                                                                    }
                                                                                    {(Math.ceil(v.ProductiveTime / v.TotalDuration * 100) <= 80 && Math.ceil(v.ProductiveTime / v.TotalDuration * 100) > 50) &&
                                                                                        <span class="badge bg-warning text-white ms-1">
                                                                                            {Math.ceil(v.ProductiveTime / v.TotalDuration * 100) + '%'}
                                                                                        </span>
                                                                                    }
                                                                                    {(Math.ceil(v.ProductiveTime / v.TotalDuration * 100) > 80) &&
                                                                                        <span class="badge bg-success text-white ms-1">
                                                                                            {Math.ceil(v.ProductiveTime / v.TotalDuration * 100) + '%'}
                                                                                        </span>
                                                                                    }
                                                                                </div>
                                                                                <OverlayTrigger trigger="click"
                                                                                    rootClose
                                                                                    placement="left"
                                                                                    overlay={popoverClickRootClose}
                                                                                >
                                                                                    <div onClick={(e) => {handleSetUserID(e, v)}}>
                                                                                        {(role === 2 && v.UserAttendanceData[0].Flag !== 1 && v.UserAttendanceData[0].Flag !== 2) &&
                                                                                            <ion-icon name="flag-outline" class="me-0"></ion-icon>
                                                                                        }
                                                                                        {v.UserAttendanceData[0].Flag === 1 && 
                                                                                            <ion-icon name="flag" class="me-0 text-warning"></ion-icon>
                                                                                        }
                                                                                        {v.UserAttendanceData[0].Flag === 2 && 
                                                                                            <ion-icon name="flag" class="me-0 text-danger"></ion-icon>
                                                                                        }
                                                                                    </div>
                                                                                </OverlayTrigger>
                                                                                {(calculateAboveEightHours(v.UserAttendanceData[0].TotalTime, v.UserAttendanceData[0].CheckIn, v.UserAttendanceData[0].TaskEntries) != '' && calculateAboveEightHours(v.UserAttendanceData[0].TotalTime, v.UserAttendanceData[0].CheckIn, v.UserAttendanceData[0].TaskEntries) != '--') &&
                                                                                    <span class="badge border-primary" style={{borderWidth: '1px'}}>
                                                                                        {calculateAboveEightHours(v.UserAttendanceData[0].TotalTime, v.UserAttendanceData[0].CheckIn, v.UserAttendanceData[0].TaskEntries)}
                                                                                    </span>
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-12 col-md-12">
                                                                            <div class="d-flex justify-content-between" style={{marginBottom: '3px'}}>
                                                                                {v.UserAttendanceData[0].CheckIn &&
                                                                                    <h4 class="mb-0 d-flex align-items-cente">
                                                                                        <span class="cursor-pointer fs-10" onClick={() => { handleLocationOnMap(v.UserAttendanceData[0].CheckInLocation) }}>
                                                                                            <ion-icon name="location-outline" class="me-0" style={{fontSize: '15px'}}></ion-icon>
                                                                                        </span>
                                                                                        <span class={(after10AM) ? "text-danger" : "text-success"}>
                                                                                            <Moment format="hh:mm A">{v.UserAttendanceData[0].CheckIn}</Moment>
                                                                                        </span>&nbsp;&nbsp;
                                                                                        <span>
                                                                                            {v?.UserAttendanceData[0]?.CheckInDevice?.includes('Desktop') &&
                                                                                                <ion-icon name="desktop-outline" class="me-0" style={{fontSize: '15px'}}></ion-icon>
                                                                                            }
                                                                                            {v?.UserAttendanceData[0]?.CheckInDevice?.includes('Mobile') &&
                                                                                                <ion-icon name="phone-portrait-outline" class="me-0" style={{fontSize: '15px'}}></ion-icon>
                                                                                            }
                                                                                            {v?.UserAttendanceData[0]?.CheckInDevice?.includes('Tablet') &&
                                                                                                <ion-icon name="tablet-landscape-outline" class="me-0" style={{fontSize: '15px'}}></ion-icon>
                                                                                            }
                                                                                            {(v?.UserAttendanceData[0]?.CheckInDevice?.includes('Card')) &&
                                                                                                <ion-icon name="card-outline" class="me-0" style={{fontSize: '15px'}}></ion-icon>
                                                                                            }
                                                                                        </span>
                                                                                        <span class={(after10AM) ? "text-danger" : "text-success"} style={{fontSize: '11px'}}><span class="text-secondary fw-bold">{TotalDuration.replace(/-/g, '').trim()} {(late10AM) ? 'Late' : 'Early'}</span></span>
                                                                                    </h4>
                                                                                }
                                                                                {(v.UserAttendanceData[0].CheckIn && v.UserAttendanceData[0].CheckOut) &&
                                                                                    <h4 class="mb-0 d-flex align-items-center">
                                                                                        {/* <span class={(after7PM) ? 'text-success' : 'text-danger'} style={{fontSize: '12px'}}>{TotalDuration1} {(after7PM) ? 'Over Time' : 'Early'}</span>&nbsp;&nbsp; */}
                                                                                        <span>
                                                                                            <Moment format="hh:mm A">{v.UserAttendanceData[0].CheckOut}</Moment>
                                                                                        </span>
                                                                                        <span class="cursor-pointer" onClick={() => { handleLocationOnMap(v.UserAttendanceData[0].CheckOutLocation) }}>
                                                                                            <ion-icon name="location-outline" style={{fontSize: '15px'}}></ion-icon>
                                                                                        </span>
                                                                                    </h4>
                                                                                }
                                                                            </div>
                                                                            {v.UserAttendanceData[0].CheckIn ?
                                                                                <>
                                                                                    <ProgressBar>
                                                                                        {v?.ProgressBar?.map((v1,k1) => {
                                                                                            const label = v1.Label
                                                                                            const isoStringStartTime = v1.StartTime;
                                                                                            const isoStringEndTime = v1.EndTime;
                                                                                            const formattedStartTime = moment(isoStringStartTime).utc().format('h:mm A');
                                                                                            const formattedEndTime = moment(isoStringEndTime).utc().format('h:mm A');
                                                                                            const duration = moment.duration(moment(isoStringEndTime).diff(moment(isoStringStartTime)));
                                                                                            const hours = Math.floor(duration.asHours());
                                                                                            const minutes = duration.minutes();
                                                                                            const tooltip = <Tooltip>
                                                                                                <b>{label}</b><br />
                                                                                                {formattedStartTime}.{formattedEndTime}<br />
                                                                                                {hours} Hours(s) {minutes} minutes
                                                                                            </Tooltip>
                                                                                            return (
                                                                                                <OverlayTrigger placement="top" overlay={tooltip}>
                                                                                                    <ProgressBar isChild={true} style={{height: '12px', borderRight: '0.5px solid #e9ecef'}} variant={(label == 'BREAK') ? 'dark' : v1?.Color} striped={v1?.Striped} animated={v1?.Striped} now={Math.round(v1?.Percentage)} key={k1} label={(v1?.label == 'BREAK') ? 'BREAK' : ''} />
                                                                                                </OverlayTrigger>
                                                                                            )
                                                                                        })}
                                                                                    </ProgressBar>
                                                                                </>
                                                                            :
                                                                                <>
                                                                                    <h2 class="text-danger">Absent</h2>
                                                                                </>
                                                                            }
                                                                            {v.UserAttendanceData[0].CheckIn &&
                                                                                <div class="d-flex justify-content-between" style={{marginTop: '3px'}}>
                                                                                    <>
                                                                                        {(v.UserAttendanceData[0].TaskEntries.length > 0) &&
                                                                                            <>
                                                                                                {v.UserAttendanceData[0].TaskEntries[0].Status == 'onGoing' &&
                                                                                                    <h5 class="mb-0 fw-bold d-flex align-items-center w-75">
                                                                                                        {v.UserAttendanceData[0].TaskEntries[0].TaskName}&nbsp;({v.UserAttendanceData[0].TaskEntries[0].ProjectName})
                                                                                                    </h5>
                                                                                                }
                                                                                                {((v.UserAttendanceData[0].TaskEntries[0].Status == 'paused' || v.UserAttendanceData[0].TaskEntries[0].Status == 'completed' || v.UserAttendanceData[0].TaskEntries[0].Status == 'toDo') && !v.UserAttendanceData[0].CheckOut) ?
                                                                                                    <h5 class="mb-0 fw-bold d-flex align-items-center">
                                                                                                        <ion-icon name="ban" class="fs-6 me-0 text-danger fw-bold"></ion-icon> IDLE
                                                                                                    </h5>
                                                                                                :
                                                                                                    <span class="badge border-white text-dark" style={{borderWidth: '1px'}}>
                                                                                                        <ion-icon name="pie-chart-outline" class="me-0 fs-6" style={{width: '30px'}}></ion-icon>{convertMinutes(v.ProductiveTime)}       
                                                                                                    </span>
                                                                                                }
                                                                                            </>
                                                                                        }
                                                                                        {(v.UserAttendanceData[0].TaskEntries.length == 0 && v.UserAttendanceData[0].CheckIn) &&
                                                                                            <h5 class="mb-0 fw-bold d-flex align-items-center">
                                                                                                <ion-icon name="ban" class="fs-6 me-0 text-danger fw-bold"></ion-icon> IDLE
                                                                                            </h5>
                                                                                        }
                                                                                    </>
                                                                                </div>
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </Accordion.Header>
                                                                <Accordion.Body>
                                                                    <ul class="listview image-listview flush">
                                                                        {v.UserAttendanceData[0].TaskEntries.map((v1,k1) => {
                                                                            return <TaskListView taskList={v1}></TaskListView>
                                                                        })}
                                                                    </ul>
                                                                </Accordion.Body>
                                                            </Accordion.Item>
                                                        </div>
                                                    </div>
                                                </>
                                            </Accordion>
                                        </div>
                                    </>
                                })}
                            </div>
                            <div class="row" style={{backgroundColor: 'darkgray'}}>
                                <h3 class="pt-2 text-dark text-center d-flex justify-content-center">BREAK&nbsp; <span class="badge bg-secondary text-white" style={{height: '18px'}}>{finalUserListBreak?.length}</span></h3>
                                {finalUserListBreak && finalUserListBreak.map((v,k) => {
                                    const after10AM = isAfter10AM(moment(v?.UserAttendanceData[0]?.CheckIn).format('hh:mm A'));
                                    const tempAfter10AM = handleIsAfter10AM(moment(v?.UserAttendanceData[0]?.CheckIn).format('hh:mm A'));
                                    const late10AM = isLate10AM(moment(v?.UserAttendanceData[0]?.CheckIn).format('hh:mm A'));
                                    const today = new Date();
                                    const yyyy = today.getFullYear();
                                    const mm = String(today.getMonth() + 1).padStart(2, '0'); // Months are zero-based
                                    const dd = String(today.getDate()).padStart(2, '0');

                                    // need to create function
                                    const formattedDate = `${yyyy}-${mm}-${dd}T10:00:00.000Z`;
                                    const formattedLoginDateTime = `${yyyy}-${mm}-${dd}T${moment(v.UserAttendanceData[0].CheckIn).format('HH:mm')}:00.000Z`;
                                    const isoStringStartTime = formattedDate;
                                    const isoStringEndTime = formattedLoginDateTime;
                                    const duration = moment.duration(moment(isoStringEndTime).diff(moment(isoStringStartTime)));
                                    let hours
                                    if (tempAfter10AM) {
                                        hours = Math.floor(duration.asHours());    
                                    } else {
                                        hours = Math.ceil(duration.asHours());
                                    }
                                    // const hours = Math.floor(duration.asHours());
                                    const minutes = duration.minutes();
                                    const TotalDuration = hours + ' hr ' + minutes + ' min'
                                    // need to create function
                                    const formattedDate1 = `${yyyy}-${mm}-${dd}T19:00:00.000Z`;
                                    const formattedLogoutDateTime1 = `${yyyy}-${mm}-${dd}T${moment(v.UserAttendanceData[0].CheckOut).format('HH:mm')}:00.000Z`;
                                    const isoStringStartTime1 = formattedDate1;
                                    const isoStringEndTime1 = formattedLogoutDateTime1;
                                    const duration1 = moment.duration(moment(isoStringEndTime1).diff(moment(isoStringStartTime1)));
                                    const hours1 = Math.round(duration1.asHours());
                                    const minutes1 = duration1.minutes();
                                    const TotalDuration1 = '[' + hours1 + ' hr ' + minutes1 + ' min]'
                                    return <>
                                            {/* {((v.UserAttendanceData[0].Timeline.length > 0) && (v.ProgressBar[v.ProgressBar.length - 1].Label == 'BREAK') || v.UserAttendanceData[0].Timeline.length == 0) && */}
                                                <div class="col-md-12 col-12">
                                                    <Accordion style={{background: 'transparent', border: 'none'}}>
                                                        <>
                                                            <div class="card mb-2">
                                                                <div class="card-body p-0">
                                                                    <Accordion.Item eventKey={k} class="clockyAcod">
                                                                        <Accordion.Header>
                                                                            <div class="row w-100">
                                                                                <div class="col-12 col-md-12 mb-0 d-flex justify-content-between">
                                                                                    <div class="d-flex align-items-center justify-content-between w-100">
                                                                                        <div class="d-flex align-items-center">
                                                                                            <span class="fw-bold me-1 d-flex align-items-center"><ion-icon name="person-outline" class="fs-5 me-0"></ion-icon> {v.UserName}</span>
                                                                                            {(Math.ceil(v.ProductiveTime / v.TotalDuration * 100) <= 50) &&
                                                                                                <span class="badge bg-danger text-white ms-1">
                                                                                                    {Math.ceil(v.ProductiveTime / v.TotalDuration * 100) + '%'}
                                                                                                </span>
                                                                                            }
                                                                                            {(Math.ceil(v.ProductiveTime / v.TotalDuration * 100) <= 80 && Math.ceil(v.ProductiveTime / v.TotalDuration * 100) > 50) &&
                                                                                                <span class="badge bg-warning text-white ms-1">
                                                                                                    {Math.ceil(v.ProductiveTime / v.TotalDuration * 100) + '%'}
                                                                                                </span>
                                                                                            }
                                                                                            {(Math.ceil(v.ProductiveTime / v.TotalDuration * 100) > 80) &&
                                                                                                <span class="badge bg-success text-white ms-1">
                                                                                                    {Math.ceil(v.ProductiveTime / v.TotalDuration * 100) + '%'}
                                                                                                </span>
                                                                                            }
                                                                                        </div>
                                                                                        <OverlayTrigger trigger="click"
                                                                                            rootClose
                                                                                            placement="left"
                                                                                            overlay={popoverClickRootClose}
                                                                                        >
                                                                                            <div onClick={(e) => {handleSetUserID(e, v)}}>
                                                                                                {(role === 2 && v.UserAttendanceData[0].Flag !== 1 && v.UserAttendanceData[0].Flag !== 2) &&
                                                                                                    <ion-icon name="flag-outline" class="me-0"></ion-icon>
                                                                                                }
                                                                                                {v.UserAttendanceData[0].Flag === 1 && 
                                                                                                    <ion-icon name="flag" class="me-0 text-warning"></ion-icon>
                                                                                                }
                                                                                                {v.UserAttendanceData[0].Flag === 2 && 
                                                                                                    <ion-icon name="flag" class="me-0 text-danger"></ion-icon>
                                                                                                }
                                                                                            </div>
                                                                                        </OverlayTrigger>
                                                                                        {(calculateAboveEightHours(v.UserAttendanceData[0].TotalTime, v.UserAttendanceData[0].CheckIn, v.UserAttendanceData[0].TaskEntries) != '' && calculateAboveEightHours(v.UserAttendanceData[0].TotalTime, v.UserAttendanceData[0].CheckIn, v.UserAttendanceData[0].TaskEntries) != '--') &&
                                                                                            <span class="badge border-primary" style={{borderWidth: '1px'}}>
                                                                                                {calculateAboveEightHours(v.UserAttendanceData[0].TotalTime, v.UserAttendanceData[0].CheckIn, v.UserAttendanceData[0].TaskEntries)}
                                                                                            </span>
                                                                                        }
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-12 col-md-12">
                                                                                    <div class="d-flex justify-content-between" style={{marginBottom: '3px'}}>
                                                                                        {v.UserAttendanceData[0].CheckIn &&
                                                                                            <h4 class="mb-0 d-flex align-items-cente">
                                                                                                <span class="cursor-pointer fs-10" onClick={() => { handleLocationOnMap(v.UserAttendanceData[0].CheckInLocation) }}>
                                                                                                    <ion-icon name="location-outline" class="me-0" style={{fontSize: '15px'}}></ion-icon>
                                                                                                </span>
                                                                                                <span class={(after10AM) ? "text-danger" : "text-success"}>
                                                                                                    <Moment format="hh:mm A">{v.UserAttendanceData[0].CheckIn}</Moment>
                                                                                                </span>&nbsp;&nbsp;
                                                                                                <span>
                                                                                                    {v?.UserAttendanceData[0]?.CheckInDevice?.includes('Desktop') &&
                                                                                                        <ion-icon name="desktop-outline" class="me-0" style={{fontSize: '15px'}}></ion-icon>
                                                                                                    }
                                                                                                    {v?.UserAttendanceData[0]?.CheckInDevice?.includes('Mobile') &&
                                                                                                        <ion-icon name="phone-portrait-outline" class="me-0" style={{fontSize: '15px'}}></ion-icon>
                                                                                                    }
                                                                                                    {v?.UserAttendanceData[0]?.CheckInDevice?.includes('Tablet') &&
                                                                                                        <ion-icon name="tablet-landscape-outline" class="me-0" style={{fontSize: '15px'}}></ion-icon>
                                                                                                    }
                                                                                                    {(v?.UserAttendanceData[0]?.CheckInDevice?.includes('Card')) &&
                                                                                                        <ion-icon name="card-outline" class="me-0" style={{fontSize: '15px'}}></ion-icon>
                                                                                                    }
                                                                                                </span>
                                                                                                <span class={(after10AM) ? "text-danger" : "text-success"} style={{fontSize: '11px'}}><span class="text-secondary fw-bold">{TotalDuration.replace(/-/g, '').trim()} {(late10AM) ? 'Late' : 'Early'}</span></span>
                                                                                            </h4>
                                                                                        }
                                                                                        {(v.UserAttendanceData[0].CheckIn && v.UserAttendanceData[0].CheckOut) &&
                                                                                            <h4 class="mb-0 d-flex align-items-center">
                                                                                                {/* <span class={(after7PM) ? 'text-success' : 'text-danger'} style={{fontSize: '12px'}}>{TotalDuration1} {(after7PM) ? 'Over Time' : 'Early'}</span>&nbsp;&nbsp; */}
                                                                                                <span>
                                                                                                    <Moment format="hh:mm A">{v.UserAttendanceData[0].CheckOut}</Moment>
                                                                                                </span>
                                                                                                <span class="cursor-pointer" onClick={() => { handleLocationOnMap(v.UserAttendanceData[0].CheckOutLocation) }}>
                                                                                                    <ion-icon name="location-outline" style={{fontSize: '15px'}}></ion-icon>
                                                                                                </span>
                                                                                            </h4>
                                                                                        }
                                                                                    </div>
                                                                                    {v.UserAttendanceData[0].CheckIn ?
                                                                                        <>
                                                                                            <ProgressBar>
                                                                                                {v?.ProgressBar?.map((v1,k1) => {
                                                                                                    const label = v1.Label
                                                                                                    const isoStringStartTime = v1.StartTime;
                                                                                                    const isoStringEndTime = v1.EndTime;
                                                                                                    const formattedStartTime = moment(isoStringStartTime).utc().format('h:mm A');
                                                                                                    const formattedEndTime = moment(isoStringEndTime).utc().format('h:mm A');
                                                                                                    const duration = moment.duration(moment(isoStringEndTime).diff(moment(isoStringStartTime)));
                                                                                                    const hours = Math.floor(duration.asHours());
                                                                                                    const minutes = duration.minutes();
                                                                                                    const tooltip = <Tooltip>
                                                                                                        <b>{label}</b><br />
                                                                                                        {formattedStartTime}.{formattedEndTime}<br />
                                                                                                        {hours} Hours(s) {minutes} minutes
                                                                                                    </Tooltip>
                                                                                                    return (
                                                                                                        <OverlayTrigger placement="top" overlay={tooltip}>
                                                                                                            <ProgressBar isChild={true} style={{height: '12px', borderRight: '0.5px solid #e9ecef'}} variant={(label == 'BREAK') ? 'dark' : v1?.Color} striped={v1?.Striped} animated={v1?.Striped} now={Math.round(v1?.Percentage)} key={k1} label={(v1?.label == 'BREAK') ? 'BREAK' : ''} />
                                                                                                        </OverlayTrigger>
                                                                                                    )
                                                                                                })}
                                                                                            </ProgressBar>
                                                                                        </>
                                                                                    :
                                                                                        <>
                                                                                            <h2 class="text-danger">Absent</h2>
                                                                                        </>
                                                                                    }
                                                                                    {v.UserAttendanceData[0].CheckIn &&
                                                                                        <div class="d-flex justify-content-between" style={{marginTop: '3px'}}>
                                                                                            <>
                                                                                                {(v.UserAttendanceData[0].TaskEntries.length > 0) &&
                                                                                                    <>
                                                                                                        {v.UserAttendanceData[0].TaskEntries[0].Status == 'onGoing' &&
                                                                                                            <h5 class="mb-0 fw-bold d-flex align-items-center w-75">
                                                                                                                {v.UserAttendanceData[0].TaskEntries[0].TaskName}&nbsp;({v.UserAttendanceData[0].TaskEntries[0].ProjectName})
                                                                                                            </h5>
                                                                                                        }
                                                                                                        {((v.UserAttendanceData[0].TaskEntries[0].Status == 'paused' || v.UserAttendanceData[0].TaskEntries[0].Status == 'completed' || v.UserAttendanceData[0].TaskEntries[0].Status == 'toDo') && !v.UserAttendanceData[0].CheckOut) ?
                                                                                                            <h5 class="mb-0 fw-bold d-flex align-items-center">
                                                                                                                <ion-icon name="ban" class="fs-6 me-0 text-danger fw-bold"></ion-icon> IDLE
                                                                                                            </h5>
                                                                                                        :
                                                                                                            <span class="badge border-white text-dark" style={{borderWidth: '1px'}}>
                                                                                                                <ion-icon name="pie-chart-outline" class="me-0 fs-6" style={{width: '30px'}}></ion-icon>{convertMinutes(v.ProductiveTime)}       
                                                                                                            </span>
                                                                                                        }
                                                                                                    </>
                                                                                                }
                                                                                                {(v.UserAttendanceData[0].TaskEntries.length == 0 && v.UserAttendanceData[0].CheckIn) &&
                                                                                                    <h5 class="mb-0 fw-bold d-flex align-items-center">
                                                                                                        <ion-icon name="ban" class="fs-6 me-0 text-danger fw-bold"></ion-icon> IDLE
                                                                                                    </h5>
                                                                                                }
                                                                                            </>
                                                                                        </div>
                                                                                    }
                                                                                </div>
                                                                            </div>
                                                                        </Accordion.Header>
                                                                        <Accordion.Body>
                                                                            <ul class="listview image-listview flush">
                                                                                {v.UserAttendanceData[0].TaskEntries.map((v1,k1) => {
                                                                                    return <TaskListView taskList={v1}></TaskListView>
                                                                                })}
                                                                            </ul>
                                                                        </Accordion.Body>
                                                                    </Accordion.Item>
                                                                </div>
                                                            </div>
                                                        </>
                                                    </Accordion>
                                                </div>
                                            {/* } */}
                                    </>
                                })}
                            </div>
                            <div class="row" style={{backgroundColor: 'orange'}}>
                                <h3 class="pt-2 text-dark text-center d-flex justify-content-center">Logged Out&nbsp; <span class="badge bg-secondary text-white" style={{height: '18px'}}>{finalUserListLoggedout?.length}</span></h3>
                                {finalUserListLoggedout && finalUserListLoggedout.map((v,k) => {
                                    const after10AM = isAfter10AM(moment(v?.UserAttendanceData[0]?.CheckIn).format('hh:mm A'));
                                    const tempAfter10AM = handleIsAfter10AM(moment(v?.UserAttendanceData[0]?.CheckIn).format('hh:mm A'));
                                    const late10AM = isLate10AM(moment(v?.UserAttendanceData[0]?.CheckIn).format('hh:mm A'));
                                    const today = new Date();
                                    const yyyy = today.getFullYear();
                                    const mm = String(today.getMonth() + 1).padStart(2, '0'); // Months are zero-based
                                    const dd = String(today.getDate()).padStart(2, '0');

                                    // need to create function
                                    const formattedDate = `${yyyy}-${mm}-${dd}T10:00:00.000Z`;
                                    const formattedLoginDateTime = `${yyyy}-${mm}-${dd}T${moment(v.UserAttendanceData[0].CheckIn).format('HH:mm')}:00.000Z`;
                                    const isoStringStartTime = formattedDate;
                                    const isoStringEndTime = formattedLoginDateTime;
                                    const duration = moment.duration(moment(isoStringEndTime).diff(moment(isoStringStartTime)));
                                    let hours
                                    if (tempAfter10AM) {
                                        hours = Math.floor(duration.asHours());    
                                    } else {
                                        hours = Math.ceil(duration.asHours());
                                    }
                                    // const hours = Math.floor(duration.asHours());
                                    const minutes = duration.minutes();
                                    const TotalDuration = hours + ' hr ' + minutes + ' min'
                                    // need to create function
                                    const formattedDate1 = `${yyyy}-${mm}-${dd}T19:00:00.000Z`;
                                    const formattedLogoutDateTime1 = `${yyyy}-${mm}-${dd}T${moment(v.UserAttendanceData[0].CheckOut).format('HH:mm')}:00.000Z`;
                                    const isoStringStartTime1 = formattedDate1;
                                    const isoStringEndTime1 = formattedLogoutDateTime1;
                                    const duration1 = moment.duration(moment(isoStringEndTime1).diff(moment(isoStringStartTime1)));
                                    const hours1 = Math.round(duration1.asHours());
                                    const minutes1 = duration1.minutes();
                                    const TotalDuration1 = '[' + hours1 + ' hr ' + minutes1 + ' min]'
                                    return <>
                                            {/* {((v.UserAttendanceData[0].Timeline.length > 0) && (v.ProgressBar[v.ProgressBar.length - 1].Label == 'BREAK') || v.UserAttendanceData[0].Timeline.length == 0) && */}
                                                <div class="col-md-12 col-12">
                                                    <Accordion style={{background: 'transparent', border: 'none'}}>
                                                        <>
                                                            <div class="card mb-2">
                                                                <div class="card-body p-0">
                                                                    <Accordion.Item eventKey={k} class="clockyAcod">
                                                                        <Accordion.Header>
                                                                            <div class="row w-100">
                                                                                <div class="col-12 col-md-12 mb-0 d-flex justify-content-between">
                                                                                    <div class="d-flex align-items-center justify-content-between w-100">
                                                                                        <div class="d-flex align-items-center">
                                                                                            <span class="fw-bold me-1 d-flex align-items-center"><ion-icon name="person-outline" class="fs-5 me-0"></ion-icon> {v.UserName}</span>
                                                                                            {(Math.ceil(v.ProductiveTime / v.TotalDuration * 100) <= 50) &&
                                                                                                <span class="badge bg-danger text-white ms-1">
                                                                                                    {Math.ceil(v.ProductiveTime / v.TotalDuration * 100) + '%'}
                                                                                                </span>
                                                                                            }
                                                                                            {(Math.ceil(v.ProductiveTime / v.TotalDuration * 100) <= 80 && Math.ceil(v.ProductiveTime / v.TotalDuration * 100) > 50) &&
                                                                                                <span class="badge bg-warning text-white ms-1">
                                                                                                    {Math.ceil(v.ProductiveTime / v.TotalDuration * 100) + '%'}
                                                                                                </span>
                                                                                            }
                                                                                            {(Math.ceil(v.ProductiveTime / v.TotalDuration * 100) > 80) &&
                                                                                                <span class="badge bg-success text-white ms-1">
                                                                                                    {Math.ceil(v.ProductiveTime / v.TotalDuration * 100) + '%'}
                                                                                                </span>
                                                                                            }
                                                                                        </div>
                                                                                        <OverlayTrigger trigger="click"
                                                                                            rootClose
                                                                                            placement="left"
                                                                                            overlay={popoverClickRootClose}
                                                                                        >
                                                                                            <div onClick={(e) => {handleSetUserID(e, v)}}>
                                                                                                {(role === 2 && v.UserAttendanceData[0].Flag !== 1 && v.UserAttendanceData[0].Flag !== 2) &&
                                                                                                    <ion-icon name="flag-outline" class="me-0"></ion-icon>
                                                                                                }
                                                                                                {v.UserAttendanceData[0].Flag === 1 && 
                                                                                                    <ion-icon name="flag" class="me-0 text-warning"></ion-icon>
                                                                                                }
                                                                                                {v.UserAttendanceData[0].Flag === 2 && 
                                                                                                    <ion-icon name="flag" class="me-0 text-danger"></ion-icon>
                                                                                                }
                                                                                            </div>
                                                                                        </OverlayTrigger>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-12 col-md-12">
                                                                                    <div class="d-flex justify-content-between" style={{marginBottom: '3px'}}>
                                                                                        {v.UserAttendanceData[0].CheckIn &&
                                                                                            <h4 class="mb-0 d-flex align-items-cente">
                                                                                                <span class="cursor-pointer fs-10" onClick={() => { handleLocationOnMap(v.UserAttendanceData[0].CheckInLocation) }}>
                                                                                                    <ion-icon name="location-outline" class="me-0" style={{fontSize: '15px'}}></ion-icon>
                                                                                                </span>
                                                                                                <span class={(after10AM) ? "text-danger" : "text-success"}>
                                                                                                    <Moment format="hh:mm A">{v.UserAttendanceData[0].CheckIn}</Moment>
                                                                                                </span>&nbsp;&nbsp;
                                                                                                <span>
                                                                                                    {v?.UserAttendanceData[0]?.CheckInDevice?.includes('Desktop') &&
                                                                                                        <ion-icon name="desktop-outline" class="me-0" style={{fontSize: '15px'}}></ion-icon>
                                                                                                    }
                                                                                                    {v?.UserAttendanceData[0]?.CheckInDevice?.includes('Mobile') &&
                                                                                                        <ion-icon name="phone-portrait-outline" class="me-0" style={{fontSize: '15px'}}></ion-icon>
                                                                                                    }
                                                                                                    {v?.UserAttendanceData[0]?.CheckInDevice?.includes('Tablet') &&
                                                                                                        <ion-icon name="tablet-landscape-outline" class="me-0" style={{fontSize: '15px'}}></ion-icon>
                                                                                                    }
                                                                                                    {(v?.UserAttendanceData[0]?.CheckInDevice?.includes('Card')) &&
                                                                                                        <ion-icon name="card-outline" class="me-0" style={{fontSize: '15px'}}></ion-icon>
                                                                                                    }
                                                                                                </span>
                                                                                                <span class={(after10AM) ? "text-danger" : "text-success"} style={{fontSize: '11px'}}><span class="text-secondary fw-bold">{TotalDuration.replace(/-/g, '').trim()} {(late10AM) ? 'Late' : 'Early'}</span></span>
                                                                                            </h4>
                                                                                        }
                                                                                        {(v.UserAttendanceData[0].CheckIn && v.UserAttendanceData[0].CheckOut) &&
                                                                                            <h4 class="mb-0 d-flex align-items-center">
                                                                                                {/* <span class={(after7PM) ? 'text-success' : 'text-danger'} style={{fontSize: '12px'}}>{TotalDuration1} {(after7PM) ? 'Over Time' : 'Early'}</span>&nbsp;&nbsp; */}
                                                                                                <span>
                                                                                                    {v?.UserAttendanceData[0]?.CheckOutDevice?.includes('Desktop') &&
                                                                                                        <ion-icon name="desktop-outline" class="me-0" style={{fontSize: '15px'}}></ion-icon>
                                                                                                    }
                                                                                                    {v?.UserAttendanceData[0]?.CheckOutDevice?.includes('Mobile') &&
                                                                                                        <ion-icon name="phone-portrait-outline" class="me-0" style={{fontSize: '15px'}}></ion-icon>
                                                                                                    }
                                                                                                    {v?.UserAttendanceData[0]?.CheckOutDevice?.includes('Tablet') &&
                                                                                                        <ion-icon name="tablet-landscape-outline" class="me-0" style={{fontSize: '15px'}}></ion-icon>
                                                                                                    }
                                                                                                    {(v?.UserAttendanceData[0]?.CheckOutDevice?.includes('Card')) &&
                                                                                                        <ion-icon name="card-outline" class="me-0" style={{fontSize: '15px'}}></ion-icon>
                                                                                                    }
                                                                                                </span>
                                                                                                <span>
                                                                                                    <Moment format="hh:mm A">{v.UserAttendanceData[0].CheckOut}</Moment>
                                                                                                </span>
                                                                                                <span class="cursor-pointer" onClick={() => { handleLocationOnMap(v.UserAttendanceData[0].CheckOutLocation) }}>
                                                                                                    <ion-icon name="location-outline" style={{fontSize: '15px'}}></ion-icon>
                                                                                                </span>
                                                                                            </h4>
                                                                                        }
                                                                                    </div>
                                                                                    {v.UserAttendanceData[0].CheckIn ?
                                                                                        <>
                                                                                            <ProgressBar>
                                                                                                {v?.ProgressBar?.map((v1,k1) => {
                                                                                                    const label = v1.Label
                                                                                                    const isoStringStartTime = v1.StartTime;
                                                                                                    const isoStringEndTime = v1.EndTime;
                                                                                                    const formattedStartTime = moment(isoStringStartTime).utc().format('h:mm A');
                                                                                                    const formattedEndTime = moment(isoStringEndTime).utc().format('h:mm A');
                                                                                                    const duration = moment.duration(moment(isoStringEndTime).diff(moment(isoStringStartTime)));
                                                                                                    const hours = Math.floor(duration.asHours());
                                                                                                    const minutes = duration.minutes();
                                                                                                    const tooltip = <Tooltip>
                                                                                                        <b>{label}</b><br />
                                                                                                        {formattedStartTime}.{formattedEndTime}<br />
                                                                                                        {hours} Hours(s) {minutes} minutes
                                                                                                    </Tooltip>
                                                                                                    return (
                                                                                                        <OverlayTrigger placement="top" overlay={tooltip}>
                                                                                                            <ProgressBar isChild={true} style={{height: '12px', borderRight: '0.5px solid #e9ecef'}} variant={(label == 'BREAK') ? 'dark' : v1?.Color} striped={v1?.Striped} animated={v1?.Striped} now={Math.round(v1?.Percentage)} key={k1} label={(v1?.label == 'BREAK') ? 'BREAK' : ''} />
                                                                                                        </OverlayTrigger>
                                                                                                    )
                                                                                                })}
                                                                                            </ProgressBar>
                                                                                        </>
                                                                                    :
                                                                                        <>
                                                                                            <h2 class="text-danger">Absent</h2>
                                                                                        </>
                                                                                    }
                                                                                    {v.UserAttendanceData[0].CheckIn &&
                                                                                        <div class="d-flex justify-content-end" style={{marginTop: '3px'}}>
                                                                                            <>
                                                                                                {(v.UserAttendanceData[0].TaskEntries.length > 0) &&
                                                                                                    <>
                                                                                                        {/* {v.UserAttendanceData[0].TaskEntries[0].Status == 'onGoing' &&
                                                                                                            <h5 class="mb-0 fw-bold d-flex align-items-center w-75">
                                                                                                                {v.UserAttendanceData[0].TaskEntries[0].TaskName}&nbsp;({v.UserAttendanceData[0].TaskEntries[0].ProjectName})
                                                                                                            </h5>
                                                                                                        } */}
                                                                                                        {((v.UserAttendanceData[0].TaskEntries[0].Status == 'paused' || v.UserAttendanceData[0].TaskEntries[0].Status == 'completed' || v.UserAttendanceData[0].TaskEntries[0].Status == 'toDo') && !v.UserAttendanceData[0].CheckOut) ?
                                                                                                            <h5 class="mb-0 fw-bold d-flex align-items-center">
                                                                                                                <ion-icon name="ban" class="fs-6 me-0 text-danger fw-bold"></ion-icon> IDLE
                                                                                                            </h5>
                                                                                                        :
                                                                                                            <>
                                                                                                                <span class="badge border-white text-dark" style={{borderWidth: '1px'}}>
                                                                                                                    <ion-icon name="pie-chart-outline" class="me-0 fs-6"></ion-icon>{convertMinutes(v.ProductiveTime)} /
                                                                                                                </span>
                                                                                                                {(calculateAboveEightHours(v.UserAttendanceData[0].TotalTime, v.UserAttendanceData[0].CheckIn, v.UserAttendanceData[0].TaskEntries) != '' && calculateAboveEightHours(v.UserAttendanceData[0].TotalTime, v.UserAttendanceData[0].CheckIn, v.UserAttendanceData[0].TaskEntries) != '--') &&
                                                                                                                    <span class="badge border-white" style={{borderWidth: '1px'}}>
                                                                                                                        <ion-icon name="timer-outline" class="me-0 fs-6 text-dark"></ion-icon> 
                                                                                                                        {calculateAboveEightHours(v.UserAttendanceData[0].TotalTime, v.UserAttendanceData[0].CheckIn, v.UserAttendanceData[0].TaskEntries)}
                                                                                                                    </span>
                                                                                                                }
                                                                                                            </>       
                                                                                                        }
                                                                                                    </>
                                                                                                }
                                                                                                {(v.UserAttendanceData[0].TaskEntries.length == 0 && v.UserAttendanceData[0].CheckIn) &&
                                                                                                    <h5 class="mb-0 fw-bold d-flex align-items-center">
                                                                                                        <ion-icon name="ban" class="fs-6 me-0 text-danger fw-bold"></ion-icon> IDLE
                                                                                                    </h5>
                                                                                                }
                                                                                            </>
                                                                                        </div>
                                                                                    }
                                                                                </div>
                                                                            </div>
                                                                        </Accordion.Header>
                                                                        <Accordion.Body>
                                                                            <ul class="listview image-listview flush">
                                                                                {v.UserAttendanceData[0].TaskEntries.map((v1,k1) => {
                                                                                    return <TaskListView taskList={v1}></TaskListView>
                                                                                })}
                                                                            </ul>
                                                                        </Accordion.Body>
                                                                    </Accordion.Item>
                                                                </div>
                                                            </div>
                                                        </>
                                                    </Accordion>
                                                </div>
                                            {/* } */}
                                    </>
                                })}
                            </div>
                            <div class="row" style={{backgroundColor: 'gainsboro'}}>
                                <h3 class="pt-2 text-dark text-center d-flex justify-content-between">
                                    <div class="d-flex">
                                        Not Present <span class="badge bg-secondary text-white" style={{height: '18px', marginLeft: '5px'}}>{absentUserList?.length}</span>
                                    </div>
                                    <div class="d-flex">
                                        {!showAbsentUsers &&
                                            <div class="d-flex align-items-center cursor-pointer" onClick={() => {setShowAbsentUsers(true)}}>
                                                <ion-icon name="eye-off-outline" class="fs-4"></ion-icon>                            
                                            </div>
                                        }
                                        {showAbsentUsers &&
                                            <div class="d-flex align-items-center cursor-pointer" onClick={() => {setShowAbsentUsers(false)}}>
                                                <ion-icon name="eye-outline" class="fs-4"></ion-icon>                            
                                            </div>
                                        }
                                    </div>
                                </h3>
                                {showAbsentUsers &&
                                    <>
                                        {absentUserList && absentUserList.map((v,k) => {
                                            const after10AM = isAfter10AM(moment(v?.UserAttendanceData[0]?.CheckIn).format('hh:mm A'));
                                            const late10AM = isLate10AM(moment(v?.UserAttendanceData[0]?.CheckIn).format('hh:mm A'));
                                            const today = new Date();
                                            const yyyy = today.getFullYear();
                                            const mm = String(today.getMonth() + 1).padStart(2, '0'); // Months are zero-based
                                            const dd = String(today.getDate()).padStart(2, '0');

                                            // need to create function
                                            const formattedDate = `${yyyy}-${mm}-${dd}T10:00:00.000Z`;
                                            const formattedLoginDateTime = `${yyyy}-${mm}-${dd}T${moment(v.UserAttendanceData[0].CheckIn).format('HH:mm')}:00.000Z`;
                                            const isoStringStartTime = formattedDate;
                                            const isoStringEndTime = formattedLoginDateTime;
                                            const duration = moment.duration(moment(isoStringEndTime).diff(moment(isoStringStartTime)));
                                            let hours
                                            if (after10AM) {
                                                hours = Math.floor(duration.asHours());    
                                            } else {
                                                hours = Math.ceil(duration.asHours());
                                            }
                                            // const hours = Math.floor(duration.asHours());
                                            const minutes = duration.minutes();
                                            const TotalDuration = hours + ' hr ' + minutes + ' min'
                                            // need to create function
                                            const formattedDate1 = `${yyyy}-${mm}-${dd}T19:00:00.000Z`;
                                            const formattedLogoutDateTime1 = `${yyyy}-${mm}-${dd}T${moment(v.UserAttendanceData[0].CheckOut).format('HH:mm')}:00.000Z`;
                                            const isoStringStartTime1 = formattedDate1;
                                            const isoStringEndTime1 = formattedLogoutDateTime1;
                                            const duration1 = moment.duration(moment(isoStringEndTime1).diff(moment(isoStringStartTime1)));
                                            const hours1 = Math.round(duration1.asHours());
                                            const minutes1 = duration1.minutes();
                                            const TotalDuration1 = '[' + hours1 + ' hr ' + minutes1 + ' min]'
                                            return <>
                                                    {/* {((v.UserAttendanceData[0].Timeline.length > 0) && (v.ProgressBar[v.ProgressBar.length - 1].Label == 'BREAK') || v.UserAttendanceData[0].Timeline.length == 0) && */}
                                                        <div class="col-md-12 col-12">
                                                            <Accordion style={{background: 'transparent', border: 'none'}}>
                                                                <>
                                                                    <div class="card mb-2">
                                                                        <div class="card-body p-0">
                                                                            <Accordion.Item eventKey={k} class="clockyAcod">
                                                                                <Accordion.Header>
                                                                                    <div class="row w-100">
                                                                                        <div class="col-12 col-md-12 mb-0 d-flex justify-content-between">
                                                                                            <div class="d-flex align-items-center justify-content-between w-100">
                                                                                                <div class="d-flex align-items-center">
                                                                                                    <span class="fw-bold me-1 d-flex align-items-center"><ion-icon name="person-outline" class="fs-5 me-0"></ion-icon> {v.UserName}</span>
                                                                                                    {(Math.ceil(v.ProductiveTime / v.TotalDuration * 100) <= 50) &&
                                                                                                        <span class="badge bg-danger text-white ms-1">
                                                                                                            {Math.ceil(v.ProductiveTime / v.TotalDuration * 100) + '%'}
                                                                                                        </span>
                                                                                                    }
                                                                                                    {(Math.ceil(v.ProductiveTime / v.TotalDuration * 100) <= 80 && Math.ceil(v.ProductiveTime / v.TotalDuration * 100) > 50) &&
                                                                                                        <span class="badge bg-warning text-white ms-1">
                                                                                                            {Math.ceil(v.ProductiveTime / v.TotalDuration * 100) + '%'}
                                                                                                        </span>
                                                                                                    }
                                                                                                    {(Math.ceil(v.ProductiveTime / v.TotalDuration * 100) > 80) &&
                                                                                                        <span class="badge bg-success text-white ms-1">
                                                                                                            {Math.ceil(v.ProductiveTime / v.TotalDuration * 100) + '%'}
                                                                                                        </span>
                                                                                                    }
                                                                                                </div>
                                                                                                <OverlayTrigger trigger="click"
                                                                                                    rootClose
                                                                                                    placement="left"
                                                                                                    overlay={popoverClickRootClose}
                                                                                                >
                                                                                                    <div onClick={(e) => {handleSetUserID(e, v)}}>
                                                                                                        {(role === 2 && v.UserAttendanceData[0].Flag !== 1 && v.UserAttendanceData[0].Flag !== 2) &&
                                                                                                            <ion-icon name="flag-outline" class="me-0"></ion-icon>
                                                                                                        }
                                                                                                        {v.UserAttendanceData[0].Flag === 1 && 
                                                                                                            <ion-icon name="flag" class="me-0 text-warning"></ion-icon>
                                                                                                        }
                                                                                                        {v.UserAttendanceData[0].Flag === 2 && 
                                                                                                            <ion-icon name="flag" class="me-0 text-danger"></ion-icon>
                                                                                                        }
                                                                                                    </div>
                                                                                                </OverlayTrigger>
                                                                                                {/* <div>
                                                                                                    {(calculateAboveEightHours(v.UserAttendanceData[0].TotalTime, v.UserAttendanceData[0].CheckIn, v.UserAttendanceData[0].TaskEntries) != '' && calculateAboveEightHours(v.UserAttendanceData[0].TotalTime, v.UserAttendanceData[0].CheckIn, v.UserAttendanceData[0].TaskEntries) != '--') &&
                                                                                                        <span class="badge border-primary" style={{borderWidth: '1px'}}>
                                                                                                            {calculateAboveEightHours(v.UserAttendanceData[0].TotalTime, v.UserAttendanceData[0].CheckIn, v.UserAttendanceData[0].TaskEntries)}
                                                                                                        </span>
                                                                                                    }
                                                                                                </div> */}
                                                                                            </div>
                                                                                        </div>
                                                                                        <div class="col-12 col-md-12">
                                                                                            <div class="d-flex justify-content-between" style={{marginBottom: '3px'}}>
                                                                                                {v.UserAttendanceData[0].CheckIn &&
                                                                                                    <h4 class="mb-0 d-flex align-items-cente">
                                                                                                        <span class="cursor-pointer fs-10" onClick={() => { handleLocationOnMap(v.UserAttendanceData[0].CheckInLocation) }}>
                                                                                                            <ion-icon name="location-outline" class="me-0" style={{fontSize: '15px'}}></ion-icon>
                                                                                                        </span>
                                                                                                        <span class={(after10AM) ? "text-danger" : "text-success"}>
                                                                                                            <Moment format="hh:mm A">{v.UserAttendanceData[0].CheckIn}</Moment>
                                                                                                        </span>&nbsp;&nbsp;
                                                                                                        <span>
                                                                                                            {v?.UserAttendanceData[0]?.CheckInDevice?.includes('Desktop') &&
                                                                                                                <ion-icon name="desktop-outline" class="me-0" style={{fontSize: '15px'}}></ion-icon>
                                                                                                            }
                                                                                                            {v?.UserAttendanceData[0]?.CheckInDevice?.includes('Mobile') &&
                                                                                                                <ion-icon name="phone-portrait-outline" class="me-0" style={{fontSize: '15px'}}></ion-icon>
                                                                                                            }
                                                                                                            {v?.UserAttendanceData[0]?.CheckInDevice?.includes('Tablet') &&
                                                                                                                <ion-icon name="tablet-landscape-outline" class="me-0" style={{fontSize: '15px'}}></ion-icon>
                                                                                                            }
                                                                                                            {(v?.UserAttendanceData[0]?.CheckInDevice?.includes('Card')) &&
                                                                                                                <ion-icon name="card-outline" class="me-0" style={{fontSize: '15px'}}></ion-icon>
                                                                                                            }
                                                                                                        </span>
                                                                                                        <span class={(after10AM) ? "text-danger" : "text-success"} style={{fontSize: '11px'}}><span class="text-secondary fw-bold">{TotalDuration.replace(/-/g, '').trim()} {(late10AM) ? 'Late' : 'Early'}</span></span>
                                                                                                    </h4>
                                                                                                }
                                                                                                {(v.UserAttendanceData[0].CheckIn && v.UserAttendanceData[0].CheckOut) &&
                                                                                                    <h4 class="mb-0 d-flex align-items-center">
                                                                                                        {/* <span class={(after7PM) ? 'text-success' : 'text-danger'} style={{fontSize: '12px'}}>{TotalDuration1} {(after7PM) ? 'Over Time' : 'Early'}</span>&nbsp;&nbsp; */}
                                                                                                        <span>
                                                                                                            <Moment format="hh:mm A">{v.UserAttendanceData[0].CheckOut}</Moment>
                                                                                                        </span>
                                                                                                        <span class="cursor-pointer" onClick={() => { handleLocationOnMap(v.UserAttendanceData[0].CheckOutLocation) }}>
                                                                                                            <ion-icon name="location-outline" style={{fontSize: '15px'}}></ion-icon>
                                                                                                        </span>
                                                                                                    </h4>
                                                                                                }
                                                                                            </div>
                                                                                            {v.UserAttendanceData[0].CheckIn ?
                                                                                                <>
                                                                                                    <ProgressBar>
                                                                                                        {v?.ProgressBar?.map((v1,k1) => {
                                                                                                            const label = v1.Label
                                                                                                            const isoStringStartTime = v1.StartTime;
                                                                                                            const isoStringEndTime = v1.EndTime;
                                                                                                            const formattedStartTime = moment(isoStringStartTime).utc().format('h:mm A');
                                                                                                            const formattedEndTime = moment(isoStringEndTime).utc().format('h:mm A');
                                                                                                            const duration = moment.duration(moment(isoStringEndTime).diff(moment(isoStringStartTime)));
                                                                                                            const hours = Math.floor(duration.asHours());
                                                                                                            const minutes = duration.minutes();
                                                                                                            const tooltip = <Tooltip>
                                                                                                                <b>{label}</b><br />
                                                                                                                {formattedStartTime}.{formattedEndTime}<br />
                                                                                                                {hours} Hours(s) {minutes} minutes
                                                                                                            </Tooltip>
                                                                                                            return (
                                                                                                                <OverlayTrigger placement="top" overlay={tooltip}>
                                                                                                                    <ProgressBar isChild={true} style={{height: '12px', borderRight: '0.5px solid #e9ecef'}} variant={(label == 'BREAK') ? 'dark' : v1?.Color} striped={v1?.Striped} animated={v1?.Striped} now={Math.round(v1?.Percentage)} key={k1} label={(v1?.label == 'BREAK') ? 'BREAK' : ''} />
                                                                                                                </OverlayTrigger>
                                                                                                            )
                                                                                                        })}
                                                                                                    </ProgressBar>
                                                                                                </>
                                                                                            :
                                                                                                <>
                                                                                                    <h2 class="text-danger">Absent</h2>
                                                                                                </>
                                                                                            }
                                                                                            {v.UserAttendanceData[0].CheckIn &&
                                                                                                <div class="d-flex justify-content-between" style={{marginTop: '3px'}}>
                                                                                                    <>
                                                                                                        {(v.UserAttendanceData[0].TaskEntries.length > 0) &&
                                                                                                            <>
                                                                                                                {v.UserAttendanceData[0].TaskEntries[0].Status == 'onGoing' &&
                                                                                                                    <h5 class="mb-0 fw-bold d-flex align-items-center">
                                                                                                                        {v.UserAttendanceData[0].TaskEntries[0].TaskName}&nbsp;({v.UserAttendanceData[0].TaskEntries[0].ProjectName})
                                                                                                                    </h5>
                                                                                                                }
                                                                                                                {((v.UserAttendanceData[0].TaskEntries[0].Status == 'paused' || v.UserAttendanceData[0].TaskEntries[0].Status == 'completed' || v.UserAttendanceData[0].TaskEntries[0].Status == 'toDo') && !v.UserAttendanceData[0].CheckOut) ?
                                                                                                                    <h5 class="mb-0 fw-bold d-flex align-items-center">
                                                                                                                        <ion-icon name="ban" class="fs-6 me-0 text-danger fw-bold"></ion-icon> IDLE
                                                                                                                    </h5>
                                                                                                                :
                                                                                                                    <span class="badge border-primary text-dark" style={{borderWidth: '1px'}}>
                                                                                                                        {convertMinutes(v.ProductiveTime)}       
                                                                                                                    </span>
                                                                                                                }
                                                                                                            </>
                                                                                                        }
                                                                                                        {(v.UserAttendanceData[0].TaskEntries.length == 0 && v.UserAttendanceData[0].CheckIn) &&
                                                                                                            <h5 class="mb-0 fw-bold d-flex align-items-center">
                                                                                                                <ion-icon name="ban" class="fs-6 me-0 text-danger fw-bold"></ion-icon> IDLE
                                                                                                            </h5>
                                                                                                        }
                                                                                                    </>
                                                                                                </div>
                                                                                            }
                                                                                        </div>
                                                                                    </div>
                                                                                </Accordion.Header>
                                                                                <Accordion.Body>
                                                                                    <ul class="listview image-listview flush">
                                                                                        {v.UserAttendanceData[0].TaskEntries.map((v1,k1) => {
                                                                                            return <TaskListView taskList={v1}></TaskListView>
                                                                                        })}
                                                                                    </ul>
                                                                                </Accordion.Body>
                                                                            </Accordion.Item>
                                                                        </div>
                                                                    </div>
                                                                </>
                                                            </Accordion>
                                                        </div>
                                                    {/* } */}
                                            </>
                                        })}
                                    </>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </>
        }
        {view == 'project' &&
            <OkrBucketView dateFilter={selectedDate} />
            // <ProjectView dateFilter={selectedDate} />
        }
    </div>
  )
}

const mapStateToProps = ({ auth, data }) => {
    const {
        userData,
        authToken,
        role
    } = auth
    const {
        renderCount,
        renderProgressBar,
        attendanceListData,
        usersListData,
        disabledAllButtons,
        allUserList,
        allProjectList
    } = data
    return {
        userData,
        authToken,
        renderCount,
        renderProgressBar,
        attendanceListData,
        usersListData,
        disabledAllButtons,
        allUserList,
        role,
        allProjectList
    }
}
  
const mapDispatchToProps = {
    setTasksList,
    setRenderCount,
    setUsersListData,
    setAttendanceListData,
    setRenderProgressBar,
    showAddTaskModal,
    showPreloader,
    actionDisabledAllButtons
}

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard)
